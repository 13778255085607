import { Button, Form, Select, Tag, Space, Input, message as antMessage, Typography } from 'antd';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useCallAPI } from '../../../../hooks/useCallAPI';
import {
  CREATE_LINKEDIN_EMAIL_TEMPLATE,
  UPDATE_LINKEDIN_EMAIL_TEMPLATE,
  GET_LINKEDIN_EMAIL_TEMPLATE_BY_USER
} from '../../../../ApiUrl';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;


const stripHtmlTags = (html) => {
  if (!html) return '';
  return html.replace(/<[^>]*>/g, '');
};

export default function CandidateLinkedinInvitation({
  form,
  selectedRows,
  sendInvitation,
  maxChars = 200
}) {
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [messageText, setMessageText] = useState('');
  const textAreaRef = useRef(null);

  const templateAPI = useCallAPI(GET_LINKEDIN_EMAIL_TEMPLATE_BY_USER);
  const createTemplate = useCallAPI(CREATE_LINKEDIN_EMAIL_TEMPLATE);
  const updateTemplate = useCallAPI(UPDATE_LINKEDIN_EMAIL_TEMPLATE);


  useEffect(() => {
    const message = form.getFieldValue('message') || '';
    setMessageText(message);
    setCharCount(message.length);
  }, [form]);

  const fetchTemplates = async () => {
    try {
      setIsLoadingTemplates(true);
      const response = await templateAPI.getList();

      // Force a re-render by updating both states
      if (response?.data?.data) {
        setTemplates([...response.data.data]);
        setForceUpdate(prev => prev + 1);
      }
    } catch (error) {
      console.error('Template fetch error:', error);
      antMessage.error('Failed to load templates');
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  // Fetch templates on component mount
  useEffect(() => {
    fetchTemplates();
  }, []); // Empty dependency array for mount only

  // Update templates when API data changes
  useEffect(() => {
    if (templateAPI.data?.data) {
      // Force a re-render by creating a new array
      setTemplates([...templateAPI.data.data]);
      setForceUpdate(prev => prev + 1);

      // If we have a selectedTemplateId, make sure it's still selected after refresh
      if (selectedTemplateId) {
        const template = templateAPI.data.data.find(t => t._id === selectedTemplateId);
        if (template) {
   
          const plainContent = stripHtmlTags(template.content || '');
          form.setFieldsValue({
            template_name: template.template_name,
            message: plainContent
          });
          setMessageText(plainContent);
          setCharCount(plainContent.length);
        }
      }
    }
  }, [templateAPI.data, selectedTemplateId, form]);

  // Add cleanup function
  useEffect(() => {
    return () => {
      setTemplates([]);
      setIsLoadingTemplates(false);
      setForceUpdate(0);
    };
  }, []);

  const handleTemplateSelect = useCallback((value) => {
    if (!value) {
      setSelectedTemplateId(null);
      resetForm();
      return;
    }

    const template = templates.find(t => t._id === value);
    if (template) {
      setSelectedTemplateId(value);

      const plainContent = stripHtmlTags(template.content || '');
      form.setFieldsValue({
        template_name: template.template_name,
        message: plainContent
      });
      setMessageText(plainContent);
      setCharCount(plainContent.length);
    }
  }, [templates, form]);

  const handleSaveTemplate = async () => {
    const values = form.getFieldsValue();
    const templateData = {
      template_name: values.template_name,
      email_type: 'linkedin_invitation',
      content: values.message
    };

    try {
      setIsSavingTemplate(true);

      let savedTemplateId;

      if (selectedTemplateId && !selectedTemplateId.toString().startsWith('temp_')) {
        // Update existing template
        const response = await updateTemplate.put(templateData, selectedTemplateId);
        savedTemplateId = selectedTemplateId;
        antMessage.success('Template updated successfully');
      } else {
        // Create new template
        const response = await createTemplate.post(templateData);
        savedTemplateId = response?.data?.data?._id;
        antMessage.success('Template saved successfully');
      }

      // Refetch templates to get the latest data
      await fetchTemplates();

      // Update selected template ID to the saved one
      setSelectedTemplateId(savedTemplateId);

      // Reset form if it was a new template
      if (!selectedTemplateId) {
        form.setFieldsValue({
          template_name: templateData.template_name,
          message: templateData.content
        });
        setMessageText(templateData.content);
        setCharCount(templateData.content.length);
      }

    } catch (err) {
      console.error('Template save error:', err);
      antMessage.error('Failed to save template');
    } finally {
      setIsSavingTemplate(false);
    }
  };

  const insertVariable = (variable) => {
    const currentMessage = messageText;
    const insertion = `{{${variable}}}`;
    const newMessage = currentMessage + insertion;
    
    form.setFieldsValue({ message: newMessage });
    setMessageText(newMessage);
    setCharCount(newMessage.length);
    

    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  const resetForm = () => {
    form.setFieldsValue({
      template_name: '',
      message: ''
    });
    setMessageText('');
    setCharCount(0);
  };

  // Modified renderTemplateOptions to not use useCallback
  const renderTemplateOptions = () => {
    const getTemplateDisplay = (template) => {
      // Strip HTML tags for display purposes
      const plainContent = stripHtmlTags(template?.content || '');
      const variables = plainContent.match(/{{(\w+)}}/g) || [];
      const uniqueVars = [...new Set(variables)].join(' ');
      return uniqueVars ? `Template: ${uniqueVars}` : 'Empty Template';
    };

    return [
      <Option key="select-placeholder" disabled value="">
        Select a template
      </Option>,
      ...templates.map(template => (
        <Option
          key={`${template?._id}-${forceUpdate}`}
          value={template?._id}
          title={stripHtmlTags(template?.content || '')}
        >
          {getTemplateDisplay(template)}
        </Option>
      ))
    ];
  };

  const handleTextAreaChange = (e) => {
    const content = e.target.value;
    setMessageText(content);
    setCharCount(content.length);
    form.setFieldsValue({ message: content });
  };

  const counterStyle = {
    position: 'absolute',
    bottom: '-20px',
    right: '0',
    fontSize: '12px',
    color: charCount > maxChars ? '#ff4d4f' : charCount > maxChars * 0.9 ? '#faad14' : '#8c8c8c'
  };

  const handleSubmit = async (values) => {
    try {
   
      const { displayName, messageContent } = { 
        displayName: values.displayName, 
        messageContent: values.message 
      };
      
      const result = await sendInvitation({ 
        displayName, 
        message: messageContent 
      });
      

      if (result && result.success) {
        
        form.resetFields();
        setMessageText('');
        setCharCount(0);
      }
     
    } catch (error) {
 
      console.error('Error sending invitation:', error);
    }
  };

  const templateNameRules = isSavingTemplate ?
    [{ required: true, message: "Please enter template name" }] :
    [];

  return (
    <Form
      form={form}
      name="linkedin_message"
      onFinish={handleSubmit}
      layout="vertical"
      style={{ maxWidth: "700px", margin: "auto" }}
    >
      <Form.Item
        name="displayName"
        label="LinkedIn Username"
        rules={[{ required: true, message: "Please select recipients" }]}
      >
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="LinkedIn Users"
        >
          {selectedRows?.map((row) => (
            <Option key={row._id} value={row.candidate.first_name}>
              <Tag color="blue">{row.candidate.first_name}</Tag>
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Templates">
        <Select
          key={`templates-select-${forceUpdate}`}
          placeholder="Select a template"
          onChange={handleTemplateSelect}
          value={selectedTemplateId}
          loading={templateAPI.loading || isLoadingTemplates}
          allowClear
          style={{ width: '100%' }}
        >
          {renderTemplateOptions()}
        </Select>
      </Form.Item>

      <Form.Item
        name="template_name"
        label="Template Name"
        rules={templateNameRules}
        style={{ display: isSavingTemplate ? 'block' : 'none' }}
      >
        <Input placeholder="Enter template name" />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" onClick={() => insertVariable("name")}>
            Name
          </Button>
          <Button type="primary" onClick={() => insertVariable("email")}>
            Email
          </Button>
          <Button type="primary" onClick={() => insertVariable("phone")}>
            Phone
          </Button>
        </Space>
      </Form.Item>

      <Form.Item
        name="message"
        label="Message"
        rules={[
          { required: true, message: "Please enter your message" },
          {
            validator: (_, value) => {
              if (value && value.length > maxChars) {
                return Promise.reject(
                  `Message exceeds ${maxChars} characters limit`
                );
              }
              return Promise.resolve();
            }
          }
        ]}
        style={{ position: 'relative' }}
      >
        <div style={{ position: 'relative' }}>
          <TextArea
            ref={textAreaRef}
            rows={6}
            placeholder="Enter your message here"
            onChange={handleTextAreaChange}
            value={messageText}
            maxLength={maxChars + 50}
          />
          <div style={counterStyle}>
            <Text
              type={charCount > maxChars ? 'danger' : charCount > maxChars * 0.9 ? 'warning' : 'secondary'}
            >
              {charCount}/{maxChars} characters
            </Text>
          </div>
          {charCount > maxChars && (
            <Text type="danger" style={{ fontSize: '12px', marginTop: '4px', display: 'block' }}>
              Content exceeding {maxChars} characters will not be sent
            </Text>
          )}
        </div>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Send Invitation
          </Button>
          <Button
            onClick={handleSaveTemplate}
            loading={createTemplate.loading || updateTemplate.loading}
          >
            {selectedTemplateId ? 'Update Template' : 'Save as Template'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
