import React, { useState, useEffect } from "react";
import { Calendar, Badge, Modal, List, Card } from "antd";
import axios from "axios";
import moment from "moment";
import { COPILOT_GET_CALANDER_EVENTS } from "../../../ApiUrl";
import { useSelector } from "react-redux";
import { LinkOutlined } from "@ant-design/icons";

const RecruiterCalendar = () => {
  const [tasks, setTasks] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const users = useSelector(({ users }) => users);

  // Fetch events for the current month
  const fetchEvents = async (month, year) => {
    try {
      const response = await axios.post(
        COPILOT_GET_CALANDER_EVENTS,
        { month, year },
        { headers: { Authorization: users.token } }
      );
      const events = response.data.data;

      // Group events by date
      const formattedEvents = events.reduce((acc, event) => {
        const dateStr = moment(event.date).format("YYYY-MM-DD");
        if (!acc[dateStr]) acc[dateStr] = [];
        acc[dateStr].push({
          title: event.title,
          meetingLink: event.meeting_link,
        });
        return acc;
      }, {});

      setTasks(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchEvents(currentMonth.month() + 1, currentMonth.year());
  }, [currentMonth]);

  const handleEventClick = (task) => {
    setSelectedTask(task);
    setIsModalVisible(true);
  };

  const dateCellRender = (value) => {
    const dateStr = value.format("YYYY-MM-DD");
    const taskList = tasks[dateStr] || [];

    return (
      <div>
        {taskList.map((task, index) => (
          <Badge
            key={index}
            color="blue"
            text={task.title}
            style={{ display: "block", marginBottom: 4, cursor: "pointer" }}
            onClick={() => handleEventClick(task)}
          />
        ))}
      </div>
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      <Calendar
        dateCellRender={dateCellRender}
        onPanelChange={(value) => setCurrentMonth(value)}
        style={{
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
          padding: "10px",
        }}
      />

      {/* Event Details Modal */}
      <Modal
        title="Event Details"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {selectedTask && (
          <Card
            bordered={false}
            style={{
              background: "#f0f2f5",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <h3 style={{ marginBottom: "10px" }}>{selectedTask.title}</h3>
            {selectedTask.meetingLink && (
              <a
                href={selectedTask.meetingLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "16px", color: "#1890ff" }}
              >
                <LinkOutlined style={{ marginRight: "5px" }} />
                Join Meeting
              </a>
            )}
          </Card>
        )}
      </Modal>
    </div>
  );
};

export default RecruiterCalendar;
