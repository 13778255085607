import React, { useState, useEffect } from "react";
import {
  TimePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Typography,
  Divider,
  Select,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  COPILOT_CAMPAIGN_TIME_SLOTS_CREATE,
  COPILOT_CAMPAIGN_TIME_SLOTS_GET,
  COPILOT_GET_SELECTED_TIME_ZONE,
  COPILOT_SELECT_TIME_ZONE,
} from "../../../ApiUrl";

const { Title } = Typography;

const WeeklyHours = ({ campaignId }) => {
  const defaultTime = moment("00:00", "HH:mm");
  const users = useSelector(({ users }) => users);
  const [timezone, setTimezone] = useState("EST"); // Default timezone
  const [schedule, setSchedule] = useState({
    Sunday: [],
    Monday: [{ start: defaultTime, end: defaultTime }],
    Tuesday: [{ start: defaultTime, end: defaultTime }],
    Wednesday: [{ start: defaultTime, end: defaultTime }],
    Thursday: [{ start: defaultTime, end: defaultTime }],
    Friday: [{ start: defaultTime, end: defaultTime }],
    Saturday: [],
  });
  const [loading, setLoading] = useState(false);

  const handleTimezoneChange = async (value) => {
    setTimezone(value);
    await axios.post(
      COPILOT_SELECT_TIME_ZONE,
      { timezone: value },
      {
        headers: {
          Authorization: users.token,
        },
      }
    );
    message.success("Timezone updated successfully!");
  };

  const convertToTimezone = (time, targetZone) => {
    return time
      .clone()
      .tz(targetZone)
      .format("HH:mm");
  };

  const prepareSlots = async () => {
    const formattedSchedule = Object.entries(schedule).map(([day, slots]) => ({
      day,
      times: slots.map(({ start, end }) => ({
        start: start.format("HH:mm"),
        end: end.format("HH:mm"),
      })),
    }));
    try {
      await axios.post(
        COPILOT_CAMPAIGN_TIME_SLOTS_CREATE,
        {
          campaign_id: campaignId,
          slots: formattedSchedule,
        },
        {
          headers: {
            Authorization: users.token,
          },
        }
      );
      message.success("Time slots saved successfully!");
    } catch (error) {
      console.error("Error saving time slots:", error);
      message.error("Failed to save time slots. Please try again.");
    }
  };

  const fetchTimeZone = async () => {
    try {
      const response = await axios.get(COPILOT_GET_SELECTED_TIME_ZONE, {
        headers: {
          Authorization: users.token,
        },
      });
      setTimezone(response.data.data.zone);
    } catch (error) {
      console.error("Error fetching time zone:", error);
      message.error("Failed to fetch time zone. Please try again.");
    }
  };
  useEffect(() => {
    const fetchTimeSlots = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${COPILOT_CAMPAIGN_TIME_SLOTS_GET}/${campaignId}`,
          {
            headers: {
              Authorization: users.token,
            },
          }
        );
        const slots = response?.data.data?.slots;
        const fetchedSchedule = slots
          ? slots.reduce((acc, { day, times }) => {
              acc[day] = times.map(({ start, end }) => ({
                start: moment(start, "HH:mm"),
                end: moment(end, "HH:mm"),
              }));
              return acc;
            }, {})
          : {};
        setSchedule((prev) => ({ ...prev, ...fetchedSchedule }));
      } catch {
        message.error("Failed to fetch time slots.");
      } finally {
        setLoading(false);
      }
    };

    fetchTimeSlots();
  }, [campaignId, users.token]);

  const handleTimeChange = (day, index, field, value) => {
    setSchedule((prev) => {
      const updatedDay = [...prev[day]];
      updatedDay[index][field] = value;
      return { ...prev, [day]: updatedDay };
    });
  };

  const handleAddInterval = (day) => {
    setSchedule((prev) => {
      const dayIntervals = prev[day] || [];
      if (dayIntervals.length === 0) {
        return {
          ...prev,
          [day]: [
            {
              start: moment("09:00 AM", "hh:mm A"),
              end: moment("10:00 AM", "hh:mm A"),
            },
          ],
        };
      } else {
        const lastSlot = moment(
          dayIntervals[dayIntervals.length - 1].end,
          "hh:mm A"
        );
        const nextStartTime = lastSlot.clone().add(1, "hour");
        const nextEndTime = nextStartTime.clone().add(1, "hour");

        return {
          ...prev,
          [day]: [
            ...dayIntervals,
            {
              start: nextStartTime,
              end: nextEndTime,
            },
          ],
        };
      }
    });
  };

  const handleRemoveInterval = (day, index) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index),
    }));
  };

  const renderDayRow = (day, intervals) => (
    <div key={day} style={{ marginBottom: 24 }}>
      <Row gutter={16} align="middle" justify="space-between">
        <Col span={4} style={{ fontWeight: 600, fontSize: "1rem" }}>
          {day}
        </Col>
        <Col span={18}>
          {intervals.map((interval, index) => (
            <Row
              key={index}
              gutter={8}
              align="middle"
              justify="space-between"
              style={{ marginBottom: 8 }}
            >
              <Col span={10}>
                <TimePicker
                  value={interval.start}
                  format="HH:mm"
                  onChange={(value) =>
                    handleTimeChange(day, index, "start", value)
                  }
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={2} style={{ textAlign: "center" }}>
                -
              </Col>
              <Col span={10}>
                <TimePicker
                  value={interval.end}
                  format="HH:mm"
                  onChange={(value) =>
                    handleTimeChange(day, index, "end", value)
                  }
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={2} style={{ textAlign: "right" }}>
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemoveInterval(day, index)}
                  danger
                />
              </Col>
            </Row>
          ))}
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => handleAddInterval(day)}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0" }} />
    </div>
  );

  return (
    <Card style={{ padding: 24 }}>
      <Title level={4}>Weekly Time Slots</Title>
      <Select
        value={timezone}
        onChange={handleTimezoneChange}
        style={{ width: 120 }}
      >
        <Option value="IST">IST</Option>
        <Option value="EST">EST</Option>
      </Select>
      {loading ? (
        <p>Loading time slots...</p>
      ) : (
        Object.entries(schedule).map(([day, intervals]) =>
          renderDayRow(day, intervals)
        )
      )}
      <Button
        type="primary"
        onClick={() => prepareSlots()}
        disabled={loading}
        style={{ marginTop: 16 }}
      >
        Save
      </Button>
    </Card>
  );
};

export default WeeklyHours;
