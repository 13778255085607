import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  TimePicker,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  COPILOT_EDIT_CAMPAIGN_REQUEST,
  EMAIL_TEMPLATES_USER_BY_TYPE,
  OUTLOOK_EMAILS,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import CreateEmailTemplate from "./CreateEmailTemplate";

const CampaignSettings = ({ campaign, visible, onClose, fetchCampaigns }) => {
  const [form] = Form.useForm();
  const predefinedPlatforms = ["monster", "local", "cb"];
  const [predefinedTemplates, setPredefinedTemplates] = useState([]);
  const [templateContent, setTemplateContent] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [connectedEmails, setConnectedEmails] = useState([]);

  const users = useSelector(({ users }) => users);
  const daysOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleTemplateSelection = (id) => {
    // Find the selected template from the fetched templates
    const selectedTemplate = predefinedTemplates?.find(
      (template) => template._id === id
    );

    if (selectedTemplate) {
      setTemplateContent(selectedTemplate.content); // Set the content
    } else {
      // Handle case where the template is not found
      console.error("Selected template not found!");
      setTemplateContent("");
    }
  };

  const fetchConnectedEmails = async () => {
    try {
      const userId = users.user._id;
      const response = await axios.get(OUTLOOK_EMAILS, {
        headers: {
          Authorization: users.token,
        },
        params: { userId },
      });

      if (response.data) {
        const emails = response.data;

        setConnectedEmails(emails);
      } else {
        console.error("Failed to fetch connected emails");
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  const fetchTemplates = () => {
    // setLoadingTemplate(true);

    axios
      .get(`${EMAIL_TEMPLATES_USER_BY_TYPE}?type=${"ai-copilot-recruitment"}`, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        const result = res.data.data || []; // Ensure it's an array
        const templates = result.map((item) => ({
          name: item.template_name,
          ...item,
        }));
        setPredefinedTemplates(templates); // Update the predefined templates with the response
        // setLoadingTemplate(false);
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
        setPredefinedTemplates([]); // Reset templates on error
        // setLoadingTemplate(false);
      });
  };

  const handleSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        number_of_source_per_platforms: values.platformNumbers,
        mode: values.campaign_mode,
        emailTemplates: [values.template],
        campaign_active_days_and_time: {
          ...values.campaign_active_days_and_time,
          time_range: {
            start: values.campaign_active_days_and_time.time_range.start.format(
              "HH:mm"
            ),
            end: values.campaign_active_days_and_time.time_range.end.format(
              "HH:mm"
            ),
          },
        },
      };

      await axios.post(
        `${COPILOT_EDIT_CAMPAIGN_REQUEST}/${campaign._id}`,
        payload,
        {
          headers: { Authorization: users.token },
        }
      );

      message.success({
        content: "Co-Pilot updated successfully.",
        duration: 3, // Message will disappear after 3 seconds
      });
      fetchCampaigns();
      handleCancelCampaignModal();
    } catch (error) {
      console.error(error);
      message.error({
        content: "An error occurred. Please try again.",
        duration: 3, // Message will disappear after 3 seconds
      });
    }
  };

  useEffect(() => {
    if (campaign) {
      fetchConnectedEmails();
      setTemplateContent(campaign?.emailTemplates[0]?.content);
      form.setFieldsValue({
        campaign_name: campaign.campaign_name,
        campaign_active_days_and_time: {
          days: campaign.campaign_active_days_and_time?.days || [],
          time_range: {
            start: campaign.campaign_active_days_and_time?.time_range?.start
              ? moment(
                  campaign.campaign_active_days_and_time.time_range.start,
                  "HH:mm"
                )
              : null,
            end: campaign.campaign_active_days_and_time?.time_range?.end
              ? moment(
                  campaign.campaign_active_days_and_time.time_range.end,
                  "HH:mm"
                )
              : null,
          },
        },
        platformNumbers: campaign.number_of_source_per_platforms || {},
        campaign_mode: campaign.mode,
        template: campaign.emailTemplates?.[0]?._id || null,
        email_to_reach_candidate: campaign.email_to_reach_candidate || "",
        start_date: campaign.start_date
          ? moment(campaign.start_date, "YYYY-MM-DD")
          : null,
        end_date: campaign.end_date
          ? moment(campaign.end_date, "YYYY-MM-DD")
          : null,
      });
      fetchTemplates();
    }
  }, [campaign]);

  const handleCancelCampaignModal = () => {
    onClose();
    form.resetFields();
  };
  return (
    <>
      <Modal
        title={"Co-Pilot Settings"}
        visible={visible}
        onCancel={handleCancelCampaignModal}
        footer={null}
        width={1000}
        bodyStyle={{ padding: "24px 32px" }}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="campaign_name"
                label="Co-Pilot Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Co-Pilot name",
                  },
                ]}
              >
                <Input placeholder="Enter Co-Pilot name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name={["campaign_active_days_and_time", "days"]}
                label="Active Days"
                rules={[
                  {
                    required: true,
                    message: "Please select active days",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select active days"
                  allowClear
                >
                  {daysOptions.map((day) => (
                    <Option key={day} value={day}>
                      {day}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="campaign_mode"
                label="Co-Pilot Mode"
                rules={[
                  {
                    required: true,
                    message: "Please select the Co-Pilot mode",
                  },
                ]}
              >
                <Select placeholder="Select Mode">
                  <Option value="simple">Simple</Option>
                  <Option value="complex">Complex</Option>
                  <Option value="medium">Medium</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Typography.Title level={5} style={{ marginBottom: "16px" }}>
            Platforms Sourcing
          </Typography.Title>

          <Row gutter={[24, 24]}>
            {predefinedPlatforms.map((platform) => (
              <Col xs={24} sm={12} md={8} key={platform}>
                <Form.Item
                  name={["platformNumbers", platform]}
                  label={platform}
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      message: "Number must be positive",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    placeholder="Enter number"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>

          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name={"email_to_reach_candidate"}
                label="Email To Reach"
              >
                <Select placeholder="Select Email" allowClear>
                  {connectedEmails.map((emailObject) => (
                    <Option key={emailObject.id} value={emailObject.email}>
                      {emailObject.email}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="template"
                label="Select Email Template"
                rules={[
                  {
                    required: true,
                    message: "Please select an email template",
                  },
                ]}
              >
                <Select
                  placeholder="Select an email template"
                  onChange={(value) => {
                    const selectedRecord = predefinedTemplates.find(
                      (template) => template._id === value
                    );
                    handleTemplateSelection(value);
                  }}
                >
                  {predefinedTemplates.map((template) => (
                    <Option key={template.type} value={template._id}>
                      {template.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setIsVisible(true);
                    onClose();
                  }}
                  style={{ padding: 0 }}
                >
                  Create New Template
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {templateContent?.length > 0 && (
            <Card
              style={{
                border: "1px solid #e8e8e8",
                padding: "16px",
                background: "#f9f9f9",
                marginTop: "16px",
              }}
            >
              <Typography.Text strong>Template Preview:</Typography.Text>
              <div dangerouslySetInnerHTML={{ __html: templateContent }} />
            </Card>
          )}
          <Row gutter={[24, 24]} style={{ marginTop: "16px" }}>
            <Col xs={24} sm={12}>
              <Form.Item label="Active Time Range" style={{ marginBottom: 0 }}>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name={[
                        "campaign_active_days_and_time",
                        "time_range",
                        "start",
                      ]}
                      rules={[
                        {
                          required: true,
                          message: "Please select start time",
                        },
                      ]}
                    >
                      <TimePicker
                        format="HH:mm"
                        placeholder="Start Time"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[
                        "campaign_active_days_and_time",
                        "time_range",
                        "end",
                      ]}
                      rules={[
                        {
                          required: true,
                          message: "Please select end time",
                        },
                      ]}
                    >
                      <TimePicker
                        format="HH:mm"
                        placeholder="End Time"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Please select the start date",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="Select start date"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[
                  {
                    required: true,
                    message: "Please select the end date",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("start_date") <= value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("End date must be after start date")
                      );
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder="Select end date"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <CreateEmailTemplate
        onClose={() => {
          setIsVisible(false);
        }}
        visible={isVisible}
      />
    </>
  );
};

export default CampaignSettings;
