import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Table, Button, Typography, Card, Empty, Select, Space, Row, Col, Statistic, Skeleton } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { JOB_EMAIL_HISTORY } from "../../../ApiUrl";
import {
  CheckCircleOutlined,
  MailTwoTone,
  MessageOutlined,
  SendOutlined,
  EyeOutlined,
  ReloadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { ReplyOutlined } from "@material-ui/icons";
const { Text, Title } = Typography;
const { Option } = Select;

const JobEmailHistoryModal = ({ openingId, visible, onClose, initialView = 'sent' }) => {
  const [emailHistory, setEmailHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [statistics, setStatistics] = useState({
    sent: 0,
    opened: 0,
    replied: 0,
    bounced: 0
  });
  const history = useHistory();
  const users = useSelector(({ users }) => users);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const filterOptions = [
    { label: 'Sent', value: 'sent' },
    { label: 'Opened', value: 'opened' },
    { label: 'Replied', value: 'replied' },
    { label: 'Bounced', value: 'bounced' }
  ];

  const candidateDetailsPath =
    users.role === "admin"
      ? "admin"
      : users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;

  const handleChatClick = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const calculateStatistics = (emails) => {
    const stats = {
      sent: 0,
      opened: 0,
      replied: 0,
      bounced: 0
    };

    emails.forEach(email => {
      if (email.is_sent) stats.sent++;
      if (email.is_open) stats.opened++;
      if (email.is_replied) stats.replied++;
      if (email.is_bounced) stats.bounced++;
    });

    setStatistics(stats);
  };

  const getModalTitle = () => {
    if (initialView === 'opened') return 'Opened Emails History';
    if (initialView === 'replied') return 'Replied Emails History';
    if (initialView === 'bounced') return 'Bounced Emails History';
    return 'Email History';
  };

  const handleClose = () => {
    setSelectedFilters([]);
    onClose();
  };

  useEffect(() => {
    if (visible) {
      if (initialView && initialView !== 'sent') {
        setSelectedFilters([initialView]);
      } else {
        setSelectedFilters([]);
      }
      fetchEmailHistoryWithFilters();
    }
  }, [visible, initialView]);

  useEffect(() => {
    if (!visible) {
      setSelectedFilters([]);
      setEmailHistory([]);
      setTotal(0);
      setCurrentPage(1);
      setStatistics({
        sent: 0,
        opened: 0,
        replied: 0,
        bounced: 0
      });
    }
  }, [visible]);

  const fetchEmailHistoryWithFilters = async (page = 1, filters = selectedFilters) => {
    setLoading(true);
    try {
      const params = { 
        page, 
        limit: 10
      };

      if (initialView && initialView !== 'sent') {
        params.view = initialView;
      } 
      else if (filters.length > 0) {
        params.status = filters.join(',');
      }

      const response = await axios.get(`${JOB_EMAIL_HISTORY}${openingId}`, {
        params,
        headers: { Authorization: users.token },
      });
      
      setEmailHistory(response.data.emails);
      setTotal(response.data.totalCount);
      setCurrentPage(response.data.currentPage);
      calculateStatistics(response.data.emails);
    } catch (error) {
      console.error("Error fetching email history:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (values) => {
    setSelectedFilters(values);
    setCurrentPage(1);
    fetchEmailHistoryWithFilters(1, values);
  };

  const columns = [
       { 
         title: 'Name', 
         dataIndex: 'candidate_info', 
         key: 'candidate_info',
         width: '25%',
         ellipsis: true,
         render: (data,record) => {
           return (
             <NavLink
                to={{
                  pathname: `/${candidateDetailsPath}/candidateProfileDetail`,
                  state: {
                    item: {
                      candidate_id:record.candidate_id,
                      opening_id: record.opening_id,
                    },
                  },
                }}
             >
               {data && data.length > 0 ? data[0].first_name : ''} {data && data.length && data[0].middle_name > 0 ? data[0].middle_name : ''} {data && data.length > 0 ? data[0].last_name : ''}
             </NavLink>
           );
         },
       },
       { 
         title: 'Email', 
         dataIndex: 'to_email', 
         key: 'to_email',
         width: '33%',
         ellipsis: true
       },
       {
         title: 'Open',
         dataIndex: 'is_open',
         key: 'is_open',
         width: '10%',
         align: 'center',
         render: (isOpen) =>
           isOpen ? (
             <MailTwoTone twoToneColor="#52c41a" />
           ) : (
             <MailTwoTone twoToneColor="#ff4d4f" />
           ),
       },
       {
         title: 'Replied',
         dataIndex: 'is_replied',
         key: 'is_replied',
         width: '10%',
         align: 'center',
         render: (isReplied) =>
           isReplied ? (
             <ReplyOutlined style={{ color: '#52c41a' }} />
           ) : (
             <ReplyOutlined style={{ color: '#ff4d4f' }} />
           ),
       },
       {
         title: 'Bounced',
         dataIndex: 'is_bounced',
         key: 'is_bounced',
         width: '12%',
         align: 'center',
         render: (isBounced) =>
           isBounced ? (
             <CheckCircleOutlined style={{ color: '#52c41a' }} />
           ) : (
             <CheckCircleOutlined style={{ color: '#ff4d4f' }} />
           ),
       },
       {
         title: 'Action',
         key: 'action',
         width: '10%',
         align: 'center',
         render: (text, record) => (
           <Button onClick={() => handleChatClick(record)}>
             <MessageOutlined />
           </Button>
         ),
       },
  ];

  const renderSkeletonLoading = () => (
    <>
      {(!initialView || initialView === 'sent') && (
        <Card className="statistics-card" style={{ marginBottom: 16 }}>
          <Row gutter={16}>
            {[1, 2, 3, 4].map((item) => (
              <Col span={6} key={item}>
                <Skeleton.Input style={{ width: '100%', height: 50 }} active />
                <div style={{ marginTop: 8 }}>
                  <Skeleton.Input style={{ width: '60%' }} active />
                </div>
              </Col>
            ))}
          </Row>
        </Card>
      )}

      {(!initialView || initialView === 'sent') && (
        <Card style={{ marginBottom: 16 }}>
          <Skeleton.Input style={{ width: 500 }} active />
        </Card>
      )}

      <Card>
        <Skeleton active>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            {[1, 2, 3, 4, 5].map((item) => (
              <Col span={24} key={item}>
                <Skeleton.Input style={{ width: '100%', height: 40, marginBottom: 16 }} active />
              </Col>
            ))}
          </Row>
        </Skeleton>
      </Card>
    </>
  );

  return (
    <Modal
      title={getModalTitle()}
      open={visible}
      onCancel={handleClose}
      footer={null}
      width={1200}
      destroyOnClose={true}
    >
      {loading ? (
        renderSkeletonLoading()
      ) : (
        <>
          {(!initialView || initialView === 'sent') && (
            <Card className="statistics-card" style={{ marginBottom: 16 }}>
              <Row gutter={16}>
                <Col span={6}>
                  <Statistic
                    title="Total Sent"
                    value={statistics.sent}
                    prefix={<SendOutlined />}
                    valueStyle={{ color: '#1890ff' }}
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Opened"
                    value={statistics.opened}
                    prefix={<EyeOutlined />}
                    valueStyle={{ color: '#52c41a' }}
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Replied"
                    value={statistics.replied}
                    prefix={<ReloadOutlined />}
                    valueStyle={{ color: '#722ed1' }}
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Bounced"
                    value={statistics.bounced}
                    prefix={<WarningOutlined />}
                    valueStyle={{ color: '#ff4d4f' }}
                  />
                </Col>
              </Row>
            </Card>
          )}

          {!initialView || initialView === 'sent' ? (
            <Card style={{ marginBottom: 16 }}>
              <Space>
                <Text strong>Filter by:</Text>
                <Select
                  mode="multiple"
                  style={{ width: 400 }}
                  placeholder="Select filters"
                  value={selectedFilters}
                  onChange={handleFilterChange}
                  optionLabelProp="label"
                >
                  {filterOptions.map(option => (
                    <Option key={option.value} value={option.value} label={option.label}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Space>
            </Card>
          ) : null}

          {emailHistory?.length > 0 ? (
            <Table
              dataSource={emailHistory}
              columns={columns}
              pagination={{
                total,
                pageSize: 10,
                current: currentPage,
                onChange: (page) => {
                  setCurrentPage(page);
                  fetchEmailHistoryWithFilters(page);
                },
              }}
              scroll={{
                x: "auto",
              }}
              rowKey="_id"
            />
          ) : (
            <Empty description="No emails found." />
          )}
        </>
      )}

      {selectedRecord && (
        <Modal
          title="Email Conversation"
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          <div>
            {/* Message Box */}
            <Card
              className="message-box"
              style={{ marginBottom: "20px", backgroundColor: "#f0f2f5" }}
            >
              <Text strong>Sent Message:</Text>
              <br />
              <Text>From: {selectedRecord.from_email}</Text>
              <br />
              <Text>To: {selectedRecord.to_email}</Text>
              <br />
              <Text>Subject: {selectedRecord.subject}</Text>
              <br />
              <div
                dangerouslySetInnerHTML={{ __html: selectedRecord.message }}
                style={{
                  padding: "10px",
                  whiteSpace: "pre-wrap",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                }}
              />
              <Text type="secondary">
                {new Date(selectedRecord.created_at).toLocaleString()}
              </Text>
            </Card>

            {/* Reply Box */}
            {selectedRecord.is_replied && (
              <Card
                className="reply-box"
                style={{ backgroundColor: "#e6fffb" }}
              >
                <Text strong>Reply Message:</Text>
                <br />
                <Text>From: {selectedRecord.to_email}</Text>
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedRecord.reply_message,
                  }}
                  style={{
                    padding: "10px",
                    whiteSpace: "pre-wrap",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                  }}
                />
                <br />
                <Text type="secondary">
                  {new Date(
                    selectedRecord?.meta?.reply_receivedDateTime
                  ).toLocaleString()}
                </Text>
              </Card>
            )}
          </div>
        </Modal>
      )}
    </Modal>
  );
};

export default JobEmailHistoryModal;
