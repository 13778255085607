import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  Typography,
  Pagination,
  Collapse,
  Checkbox,
  Slider,
  Radio,
  Spin,
  Select,
  InputNumber,
  Input,
  Skeleton,
} from "antd";
import { useHistory } from "react-router-dom";
import {
  degreeOptions,
  freshnessOptions,
  workStatusOptions,
  ProfileOptions,
  optionSource,
  stateAbbreviationToName,
  statesList,
  Countries,
  industries,
  securityClearances,
  Availability,
  contactInfo,
  academicDiscipline,
  minimumTravelRequirements,
  languagesSpokenOptions,
  employmentTypeOptions,
} from "./Option";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import "./CB.css";
import CandidatesList from "./candidatesList";
import { debounce } from 'lodash';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const SearchList = ({
  results,
  jobSkill = [],
  range,
  setRange,
  highestDegree,
  setHighestDegree,
  contactValue,
  currentPage,
  totalPages,
  totalResults,
  onPageChange,
  rowsPerPage,
  setRowsPerPage,
  loading,
  sortOption,
  onSortChange,
  onFilterChange,
  enableFilters,
  activeFilters,
  resultsLoading,
  locationDataLoading,
  onResetFilters,
}) => {
  const handleFilterChange = (filterType, value) => {
    // Add validation check before applying filters
    if (!jobSkill.trim() && !activeFilters?.keywords?.trim()) {
      message.warning("Please enter keywords or job title before applying filters");
      return;
    }
    
    onFilterChange(filterType, value);
  };

  const jobTitles = [
    ...new Set(
      results?.flatMap((result) => (result?.JobTitle?.[0] ?? []))
    ),
  ];

  // =================== FreshnessInDays =============
  const isFresh = (date, freshnessValue) => {
    if (!date || freshnessValue === 365) return true; // Changed from 'all' to 365
    const lastUpdate = new Date(date);
    const now = new Date();
    const diffDays = Math.ceil((now - lastUpdate) / (1000 * 60 * 60 * 24));

    const days = parseInt(freshnessValue, 10);
    return isNaN(days) ? true : diffDays <= days;
  };

  //  ============ Cities ===========================
  const cities = useMemo(() => {
    const cityCounts = results?.reduce((acc, result) => {
      const homeLocation = result?.HomeLocation?.[0];
      if (!homeLocation) return acc;

      const parts = homeLocation?.split("-");
      if (parts?.length >= 3 && parts[0] === "US") {
        const stateAbbreviation = parts[1];
        const city = parts[2]?.split(",")?.[0]?.trim();

        const stateName = stateAbbreviationToName?.[stateAbbreviation];
        if (stateName && city) {
          const formattedCity = `${city}, ${stateName}, United States`;

          acc[formattedCity] = (acc[formattedCity] || 0) + 1;
        }
      }

      return acc;
    }, {});

    return cityCounts ?? {};
  }, [results]);

  // ====================== States ======================
  const states = useMemo(() => {
    const stateCounts = results.reduce((acc, result) => {
      const homeLocation = result.HomeLocation[0];
      if (!homeLocation) return acc;

      const parts = homeLocation.split("-");
      let stateAbbreviation;
      if (parts.length >= 2 && parts[0] === "US") {
        stateAbbreviation = parts[1];
      } else {
        return acc;
      }

      const stateName = stateAbbreviationToName[stateAbbreviation];
      if (stateName) {
        const formattedState = `${stateName}, United States`;

        if (statesList.includes(formattedState)) {
          acc[formattedState] = (acc[formattedState] || 0) + 1;
        }
      }

      return acc;
    }, {});

    return stateCounts;
  }, [results]);
  // ====================== Countries ======================
  const countries = useMemo(() => {
    const countryCounts = results.reduce((acc, result) => {
      const homeLocation = result.HomeLocation[0];
      if (!homeLocation) return acc;

      const parts = homeLocation.split("-");
      if (parts.length >= 1) {
        const country = parts[0].trim();
        const formattedCountry = Countries[country] || country;

        acc[formattedCountry] = (acc[formattedCountry] || 0) + 1;
      }

      return acc;
    }, {});

    return countryCounts;
  }, [results]);

  // const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const history = useHistory();
  const [workStatus, setWorkStatus] = useState([]);
  const [excludedSkills, setExcludedSkills] = useState([]);
  const [includedSkills, setIncludedSkills] = useState([]);
  const [includedDegrees, setIncludedDegrees] = useState([]);
  const [excludedDegrees, setExcludedDegrees] = useState([]);

  const [profileOptions, setProfileOptions] = useState([]);
  const [optSource, setoptionSource] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedClearances, setSelectedClearances] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [contact, setContactinfo] = useState([]);

  const [selectedCount, setSelectedCount] = useState(0);
  const [includeCount, setIncludeCount] = useState(0);
  const [excludeCount, setExcludeCount] = useState(0);
  const [showCounts, setShowCounts] = useState("");
  const [excludedCities, setExcludedCities] = useState([]);
  const [includedCities, setIncludedCities] = useState([]);

  const [selectedStates, setSelectedStates] = useState([]);
  const [excludedStates, setExcludedStates] = useState([]);
  const [includedStates, setIncludedStates] = useState([]);

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [excludedCountries, setExcludedCountries] = useState([]);

  const [selectIndustries, setIndustries] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [employmentType, setEmploymentType] = useState('All');
  const [securityClearance, setSecurityClearance] = useState('No');
  const [availability, setAvailability] = useState('0');
  const [militaryExperience, setMilitaryExperience] = useState('');

  // Add new state variables
  const [salaryRange, setSalaryRange] = useState([0, 1000000]);
  const [minimumTravelReq, setMinimumTravelReq] = useState('');
  const [languagesSpoken, setLanguagesSpoken] = useState([]);
  const [managementExp, setManagementExp] = useState('No');
  const [employeesManaged, setEmployeesManaged] = useState(0);
  const [companySearchType, setCompanySearchType] = useState('');
  const [currentlyEmployed, setCurrentlyEmployed] = useState('No');

  // Initialize localSalaryRange with default values
  const [localSalaryRange, setLocalSalaryRange] = useState(activeFilters?.salaryRange?.range || [0, 300000]);

  const debouncedSalaryChange = useCallback(
    debounce((value) => {
      if (Array.isArray(value) && value.length === 2) {
        onFilterChange('salaryRange', {
          range: value,
          minimumSalary: value[0],
          maximumSalary: value[1]
        });
      }
    }, 500),
    [onFilterChange]
  );

  // Add these new state variables near the top of the component

  // Add these state variables for local range values
  const [localExperienceRange, setLocalExperienceRange] = useState(activeFilters?.experience || [0, 20]);

  // Create debounced handlers
  const debouncedExperienceChange = useCallback(
    debounce((value) => {
      onFilterChange('experience', value);
    }, 500),
    [onFilterChange]
  );

  // Update local state immediately but debounce the API call
  const handleExperienceRangeChange = useCallback((value) => {
    if (!Array.isArray(value) || value.length !== 2) return;
    setLocalExperienceRange(value);
    // Call parent's filter change immediately
    onFilterChange('experience', value);
  }, [onFilterChange]);

  // Sync local state with active filters
  useEffect(() => {
    if (activeFilters?.experience) {
      setLocalExperienceRange(activeFilters.experience);
    }
  }, [activeFilters]);

  useEffect(() => {
    if (activeFilters?.salaryRange) {
      setLocalSalaryRange(activeFilters.salaryRange);
    } else if (Array.isArray(activeFilters.salaryRange)) {
      setLocalSalaryRange(activeFilters.salaryRange);
    }
  }, [activeFilters?.salaryRange]);

  const handleFreshnessChange = useCallback((e) => {
    const selectedValue = e.target.value;
    // console.log('Freshness selected in SearchList:', selectedValue); // Debug log
    onFilterChange('freshnessInDays', selectedValue);
  }, [onFilterChange]);

  const handleSalaryRangeChange = useCallback((value) => {
    if (!Array.isArray(value) || value.length !== 2) return;
    setLocalSalaryRange(value);
    // Call parent's filter change immediately
    onFilterChange('salaryRange', {
      range: value,
      minimumSalary: value[0],
      maximumSalary: value[1]
    });
  }, [onFilterChange]);

  const handleLanguagesSpokenChange = useCallback((selectedLanguages) => {
    setLanguagesSpoken(selectedLanguages);
    onFilterChange('languagesSpoken', selectedLanguages);
  }, [onFilterChange]);

  const LocationFilterHeader = ({ title, count }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span>{title}</span>
      {count > 0 && <span className="count-badge">{count}</span>}
    </div>
  );

  // Modify the LocationFilter component to handle loading state
  const LocationFilter = ({ items, selectedItems, onItemToggle, placeholder, loading }) => {
    const [searchText, setSearchText] = useState('');

    const filteredItems = Object.entries(items).filter(([name]) =>
      name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
      <div className="location-filter">
        <Input
          placeholder={`Search ${placeholder}`}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          prefix={<SearchOutlined />}
          className="location-search"
        />
        <Spin spinning={loading}>
          <div className="location-list">
            {filteredItems.map(([name, count]) => (
              <div key={name} className="filter-item">
                <Checkbox
                  checked={selectedItems.includes(name)}
                  onChange={() => onItemToggle(name)}
                >
                  {name} ({count})
                </Checkbox>
              </div>
            ))}
          </div>
        </Spin>
      </div>
    );
  };


  // Add useEffect to sync activeFilters with local state
  useEffect(() => {
    if (activeFilters) {
      setSelectedCities(activeFilters.cities || []);
      setSelectedStates(activeFilters.states || []);
      setSelectedCountries(activeFilters.countries || []);
      if (activeFilters.salaryRange?.range) {
        setLocalSalaryRange(activeFilters.salaryRange.range);
      }
    }
  }, [activeFilters]);

  // =============== Filter Function ======================
  const { filteredResults, totalFilteredRecords } = useMemo(() => {
    const rangeNumbers = range.map(Number);

    // Filter results based on the applied filters
    const filtered = results.filter((result) => {
      const jobTitle = result.JobTitle ? result.JobTitle[0] : null;
      if (!jobTitle) return false;

      if (excludedSkills.includes(jobTitle)) return false;

      if (includedSkills.length > 0 && !includedSkills.includes(jobTitle))
        return false;

      // =========  Advance Search Filtering Contact Name and Contact Email ===============s
      const contactEmail = result.ContactEmail ? result.ContactEmail[0] : "";
      const contactName = result.ContactName ? result.ContactName[0] : "";

      const matchesContact =
        contactEmail
          .trim()
          .toLowerCase()
          .includes(contactValue.trim().toLowerCase()) ||
        contactName
          .trim()
          .toLowerCase()
          .includes(contactValue.trim().toLowerCase());

      if (!matchesContact) return false;

      // ===========  Check Education Level =================
      const educationLevel = result.HighestDegree
        ? result.HighestDegree[0]
        : "";
      if (
        excludedDegrees.length > 0 &&
        excludedDegrees.includes(educationLevel)
      )
        return false;
      if (
        includedDegrees.length > 0 &&
        !includedDegrees.includes(educationLevel)
      )
        return false;
      // ============== Check Years of Experience ==============
      const monthsOfExperience = Number(result.MonthsOfExperience[0]) || 0;
      const experience = monthsOfExperience / 12;

      if (
        isNaN(experience) ||
        experience < rangeNumbers[0] ||
        experience > rangeNumbers[1]
      ) {
        return false;
      }

      // Freshness Check
      const lastUpdateDate = result.LastUpdate ? result.LastUpdate[0] : null;
      if (!isFresh(lastUpdateDate, activeFilters.freshnessInDays)) return false;

      // Cities and States Check
      const resultCity = result.HomeLocation[0].split("-")[1];
      if (
        selectedCities.length > 0 &&
        !Object.keys(cities).includes(resultCity)
      ) {
        return false;
      }

      const resultState = result.HomeLocation[0].split("-")[2];
      if (
        (selectedStates.length > 0 && !selectedStates.includes(resultState)) ||
        (excludedStates.length > 0 && excludedStates.includes(resultState))
      ) {
        return false;
      }

      const resultCountry = result.HomeLocation[0].split("-")[0];
      if (
        (selectedCountries.length > 0 &&
          !selectedCountries.includes(resultCountry)) ||
        (excludedCountries.length > 0 &&
          excludedCountries.includes(resultCountry))
      ) {
        return false;
      }

      // Security Clearance Check
      const securityClearance = result.SecurityClearance
        ? result.SecurityClearance[0]
        : "No";

      if (
        selectedClearances.length > 0 &&
        !selectedClearances.includes(securityClearance)
      ) {
        return false;
      }

      // Availability Check
      const resultAvailability = result.Availability
        ? result.Availability[0]
        : null;
      if (
        availabilities.length > 0 &&
        !availabilities.includes(resultAvailability)
      ) {
        return false;
      }

      return true;
    });

    // console.log("Filtered Results: ", filtered);
    // console.log("Total Filtered Records: ", filtered.length);

    return {
      filteredResults: filtered,
      totalFilteredRecords: filtered.length,
    };
  }, [
    excludedSkills,
    includedSkills,
    includedDegrees,
    excludedDegrees,
    // highestDegree,
    range,
    results,
    selectedCities,
    cities,
    selectedStates,
    excludedStates,
    activeFilters.freshnessInDays,
    selectedCountries,
    excludedCountries,
    selectedClearances,
    availabilities,
    contactValue,
  ]);

  //  ============ Job Title CheckBox ============
  const handleCheckboxChange = (title) => {
    if (includedSkills.includes(title)) {
      setIncludedSkills((prev) => prev.filter((item) => item !== title));
      setIncludeCount((prevCount) => prevCount - 1);
    } else {
      setIncludedSkills((prev) => [...prev, title]);
      setIncludeCount((prevCount) => prevCount + 1);
    }
    setSelectedCount((prevCount) =>
      includedSkills.includes(title) ? prevCount - 1 : prevCount + 1
    );
  };

  const degreeCounts = degreeOptions.reduce((acc, option) => {
    const count = results.filter((result) =>
      result.HighestDegree.includes(option.label)
    ).length;
    acc[option.value] = count;
    return acc;
  }, {});

  // ==== Education Level Checkbox ================

  const handleCheckboxDegreeChange = (degree) => {
    const isIncluded = includedDegrees.includes(degree);
    const isExcluded = excludedDegrees.includes(degree);

    if (isIncluded) {
      setIncludedDegrees((prev) => prev.filter((item) => item !== degree));
      setExcludedDegrees((prev) => [...prev, degree]);
    } else if (isExcluded) {
      setExcludedDegrees((prev) => prev.filter((item) => item !== degree));
      setIncludedDegrees((prev) => [...prev, degree]);
    } else {
      setExcludedDegrees((prev) => [...prev, degree]);
    }
  };

  const workStatusCounts = workStatusOptions.reduce((acc, option) => {
    const count = results.filter((result) => result.WorkStatus === option.value)
      .length;
    acc[option.value] = count;
    return acc;
  }, {});

  const toggleWorkStatus = (status) => {
    setWorkStatus((prev) =>
      prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status]
    );
  };

  // const currentResults = filteredResults.slice(
  //   (currentPage - 1) * pageSize,
  //   currentPage * pageSize
  // );

  // const onPageChange = (page) => {
  //   setCurrentPage(page);
  // };

  // ========= Toggle Job Title ==================

  const toggleJobTitle = (title) => {
    const isExcluded = excludedSkills.includes(title);
    const isIncluded = includedSkills.includes(title);

    if (isExcluded) {
      setExcludedSkills((prev) => prev.filter((item) => item !== title));
      setExcludeCount((prevCount) => prevCount - 1);
    } else {
      setExcludedSkills((prev) => [...prev, title]);
      setExcludeCount((prevCount) => prevCount + 1);
    }

    if (isIncluded) {
      setIncludedSkills((prev) => prev.filter((item) => item !== title));
      setIncludeCount((prevCount) => prevCount - 1);
    }

    setSelectedCount((prevCount) =>
      isExcluded || isIncluded ? prevCount - 1 : prevCount + 1
    );
  };

  // ============= Toggle Education Level ===============
  const toggleEducationLevel = (degree) => {
    const isExcluded = excludedDegrees.includes(degree);
    const isIncluded = includedDegrees.includes(degree);

    if (isExcluded) {
      setExcludedDegrees((prev) => prev.filter((item) => item !== degree));
      setIncludedDegrees((prev) => [...prev, degree]);
    } else if (isIncluded) {
      setIncludedDegrees((prev) => prev.filter((item) => item !== degree));
    } else {
      setExcludedDegrees((prev) => [...prev, degree]);
    }
  };

  // ============ Toggle City =====================
  const handleCityToggle = useCallback((city) => {
    const newSelection = selectedCities.includes(city)
      ? selectedCities.filter(c => c !== city)
      : [...selectedCities, city];
    setSelectedCities(newSelection);
    handleFilterChange('cities', newSelection);
  }, [selectedCities, handleFilterChange]);

  // ================= Toggle States ===============================
  const handleStateToggle = useCallback((state) => {
    const newSelection = selectedStates.includes(state)
      ? selectedStates.filter(s => s !== state)
      : [...selectedStates, state];
    setSelectedStates(newSelection);
    onFilterChange('states', newSelection);
  }, [selectedStates, onFilterChange]);

  //=============== Toggle Countries =========================
  const handleCountryToggle = useCallback((country) => {
    const newSelection = selectedCountries.includes(country)
      ? selectedCountries.filter(c => c !== country)
      : [...selectedCountries, country];
    setSelectedCountries(newSelection);
    onFilterChange('countries', newSelection);
  }, [selectedCountries, onFilterChange]);



  const onChangeSlider = (value) => {
    setRange(value);
  };

  const handleClick1 = () => {
    history.push("/admin/cbSearchDetails");
  };

  // ============ Toggle Industries ================

  const toggleIndustries = (option) => {
    if (industries.includes(option)) {
      setIndustries(industries.filter((item) => item !== option));
    } else {
      setIndustries([...industries, option]);
    }
  };

  //  ============ Toggle Profile Options ================

  const toggleProfileOption = (option) => {
    if (profileOptions.includes(option)) {
      setProfileOptions(profileOptions.filter((item) => item !== option));
    } else {
      setProfileOptions([...profileOptions, option]);
    }
  };

  // ======= Toggle Source ===============

  const toggleSource = (source) => {
    if (optSource.includes(source)) {
      setoptionSource(optSource.filter((item) => item !== source));
    } else {
      setoptionSource([...optSource, source]);
    }
  };

  // ============== Toggle Security Clearances ===========================
  const toggleSecurityClearance = (clearance) => {
    const updatedClearances = selectedClearances.includes(clearance)
      ? selectedClearances.filter((item) => item !== clearance)
      : [...selectedClearances, clearance];

    setSelectedClearances(updatedClearances);

    // console.log("Updated Clearances:", updatedClearances);
  };

  //  ============ Toggle Availability ================
  const toggleAvailability = (option) => {
    if (Availability.includes(option)) {
      setProfileOptions(Availability.filter((item) => item !== option));
    } else {
      setAvailabilities([...Availability, option]);
    }
  };

  // const handleCityChange = (checkedValues) => {
  //   setSelectedCities(checkedValues);
  // };
  const handlePageChange = (page, pageSize) => {
    setRowsPerPage(pageSize); // Update local rowsPerPage state
    onPageChange(page, pageSize); // Pass both page and pageSize to parent
  };

  const LocationFilterContent = ({ items, selectedItems, onItemToggle, placeholder }) => {
    const [searchText, setSearchText] = useState('');

    const filteredItems = Object.entries(items).filter(([name]) =>
      name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
      <div className="location-filter">
        <Input
          placeholder={`Search ${placeholder}`}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          prefix={<SearchOutlined />}
          className="location-search"
        />
        <div className="location-list">
          {locationDataLoading ? (
            <div className="loading-counts">
              <Skeleton paragraph={{ rows: 3 }} active />
            </div>
          ) : (
            filteredItems.map(([name, count]) => (
              <div key={name} className="filter-item">
                <Checkbox
                  checked={selectedItems.includes(name)}
                  onChange={() => onItemToggle(name)}
                >
                  {name} ({count})
                </Checkbox>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };



  const handleResetFilters = () => {
    // Reset all local filter states
    setLocalExperienceRange([0, 20]);
    setLocalSalaryRange([0, 300000]);
    setSelectedCities([]);
    setSelectedStates([]);
    setSelectedCountries([]);
    setSelectedClearances([]);
    setWorkStatus([]);
    setExcludedSkills([]);
    setIncludedSkills([]);
    setIncludedDegrees([]);
    setExcludedDegrees([]);
    setAvailabilities([]);
    setLanguagesSpoken([]);

    // Call parent's reset handler to trigger the backend call
    onResetFilters();
  };

  return (
    <Spin spinning={resultsLoading}>
      <Card bordered={false} className="search-results-card">
        <Row gutter={24}>
          <Col span={6}>
            <div className="filters-section">
              <div className="filters-header" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 16px',
                borderBottom: '1px solid #f0f0f0'
              }}>
                <Text strong>Filters</Text>
                <Button
                  type="link"
                  onClick={handleResetFilters}
                  disabled={!enableFilters}
                >
                  Reset Filters
                </Button>
              </div>
              <Collapse defaultActiveKey={["1", "freshnessInDays", "experience", "cities", "states", "countries", "salary"]}>
                {/* <Panel header="Freshness" key="freshnessInDays">
                  <Radio.Group
                    onChange={handleFreshnessChange}
                    value={activeFilters?.freshnessInDays}
                    disabled={!enableFilters}
                  >
                    {freshnessOptions.map(option => {
                      console.log('Radio option:', option.value, 'Current value:', activeFilters?.freshnessInDays); // Debug log
                      return (
                        <Radio key={option.value} value={option.value}>
                          {option.label}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Panel>

                <Panel header="Years of Experience" key="experience">
                  <Slider
                    range
                    min={0}
                    max={20}
                    value={localExperienceRange}
                    onChange={handleExperienceRangeChange}
                    disabled={!enableFilters}
                    step={1}
                    marks={{
                      0: '0 yrs',
                      5: '5 yrs',
                      10: '10 yrs',
                      15: '15 yrs',
                      20: '20+ yrs'
                    }}
                  />
                </Panel> */}
                    {/* temp disabling */}
                {/* <Panel header="Salary Range" key="salary">
                  <Slider
                    range
                    value={localSalaryRange}
                    onChange={handleSalaryRangeChange}
                    min={0}
                    max={300000}
                    step={1000}
                    disabled={!enableFilters}
                    marks={{
                      0: '$0',
                      100000: '$100k',
                      200000: '$200k',
                      300000: '$300k+'
                    }}
                    tipFormatter={value => `$${value.toLocaleString()}`}
                  />
                </Panel> */}

                {/* Location filters with their own loading states */}
                <Panel header={<LocationFilterHeader title="Cities" count={selectedCities.length} />} key="cities">
                  {locationDataLoading ? (
                    <div className="location-filter-loading">
                      <Skeleton paragraph={{ rows: 3 }} active />
                    </div>
                  ) : (
                    <LocationFilterContent
                      items={cities}
                      selectedItems={selectedCities}
                      onItemToggle={handleCityToggle}
                      placeholder="Search cities"
                    />
                  )}
                </Panel>

                {/* Similar updates for States and Countries panels */}
                <Panel header={<LocationFilterHeader title="States" count={selectedStates.length} />} key="states">
                  {locationDataLoading ? (
                    <div className="location-filter-loading">
                      <Skeleton paragraph={{ rows: 3 }} active />
                    </div>
                  ) : (
                    <LocationFilterContent
                      items={states}
                      selectedItems={selectedStates}
                      onItemToggle={handleStateToggle}
                      placeholder="Search states"
                    />
                  )}
                </Panel>

                <Panel header={<LocationFilterHeader title="Countries" count={selectedCountries.length} />} key="countries">
                  {locationDataLoading ? (
                    <div className="location-filter-loading">
                      <Skeleton paragraph={{ rows: 3 }} active />
                    </div>
                  ) : (
                    <LocationFilterContent
                      items={countries}
                      selectedItems={selectedCountries}
                      onItemToggle={handleCountryToggle}
                      placeholder="Search countries"
                    />
                  )}
                </Panel>
              </Collapse>
            </div>
          </Col>
          <Col span={18}>
            <div className="results-section">
              {resultsLoading ? (
                <div className="loading-results">
                  {Array(5).fill(null).map((_, index) => (
                    <Card key={index} style={{ marginBottom: 16 }}>
                      <Skeleton active avatar paragraph={{ rows: 3 }} />
                    </Card>
                  ))}
                </div>
              ) : (
                <>
                  <Row gutter={24}>
                    <Col span={24} className="text-left">
                      {results.length > 0 && (
                        <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '16px' }}>
                          <h6>Bix3 Candidates ({totalResults})</h6>
                          <Pagination
                            current={currentPage}
                            total={totalResults}
                            pageSize={rowsPerPage}
                            onChange={handlePageChange}
                            showSizeChanger
                            onShowSizeChange={handlePageChange}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <CandidatesList results={results} loading={loading} sortOption={sortOption} onSortChange={onSortChange} />
                    </Col>
                  </Row>
                  {results.length > 0 && (
                    <div className="d-flex justify-content-between align-items-center" style={{ marginTop: '16px' }}>
                      <h6>Bix3 Candidates ({totalResults})</h6>
                      <Pagination
                        current={currentPage}
                        total={totalResults}
                        pageSize={rowsPerPage}
                        onChange={handlePageChange}
                        showSizeChanger
                        onShowSizeChange={handlePageChange}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default SearchList;
