import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Checkbox,
  Input,
  Button,
  Form,
  Space,
  List,
  Typography,
  message,
  Divider,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const ScreeningQuestions = () => {
  const [questions, setQuestions] = useState([
    "What's your current CTC?",
    "What's your expected CTC?",
    "When can you join?",
    "What's your notice period?",
  ]);
  const [selectedQuestions, setSelectedQuestions] = useState(
    "What's your current CTC?"
  );
  const [newQuestion, setNewQuestion] = useState("");

  const handleCheckboxChange = useCallback((checkedValues) => {
    setSelectedQuestions(checkedValues);
  }, []);

  const handleAddQuestion = () => {
    if (!newQuestion.trim()) {
      message.error("Question cannot be empty");
      return;
    }
    if (questions.includes(newQuestion.trim())) {
      message.warning("This question already exists");
      return;
    }
    setQuestions([...questions, newQuestion.trim()]);
    setNewQuestion("");
    message.success("Question added successfully");
  };

  const handleRemoveQuestion = (questionToRemove) => {
    const updatedQuestions = questions.filter((q) => q !== questionToRemove);
    setQuestions(updatedQuestions);
    setSelectedQuestions(
      selectedQuestions.filter((q) => q !== questionToRemove)
    );
    message.success("Question removed successfully");
  };

  const handleSubmit = () => {
    if (selectedQuestions.length === 0) {
      message.warning("Please select at least one question to submit");
      return;
    }
    console.log("Selected Questions:", selectedQuestions);
    message.success("Questions submitted successfully");
  };

  return (
    <Card
      title="Screening Questions"
      style={{ maxWidth: 600, margin: "20px auto", borderRadius: 10 }}
    >
      <Typography.Title level={5}>Select Questions</Typography.Title>
      <Divider />
      <Checkbox.Group
        style={{ display: "flex", flexDirection: "column" }}
        value={selectedQuestions}
        onChange={handleCheckboxChange}
      >
        <List
          bordered
          dataSource={questions}
          renderItem={(question) => (
            <List.Item
              actions={[
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemoveQuestion(question)}
                  danger
                />,
              ]}
            >
              <Checkbox value={question}>{question}</Checkbox>
            </List.Item>
          )}
        />
      </Checkbox.Group>

      <Divider />

      <Form
        layout="inline"
        onFinish={handleAddQuestion}
        style={{ marginBottom: 20 }}
      >
        <Space>
          <Input
            placeholder="Add a new question"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            onPressEnter={handleAddQuestion}
            style={{ width: 300 }}
          />
          <Button type="primary" icon={<PlusOutlined />} htmlType="submit">
            Add
          </Button>
        </Space>
      </Form>

      <Button type="primary" onClick={handleSubmit} block>
        Submit Selected Questions
      </Button>
    </Card>
  );
};

export default ScreeningQuestions;
