import { Button, Form, Input, Select, Tag, message, Space } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { OUTLOOK_EMAILS } from "../../../../ApiUrl";

const { Option } = Select;

export default function CandidateEmail({
    form,
    emailData,
    sendEmail,
    onFinishFailed,
    insertVariable,
    templates,
    isLoading,
    createTemplate,
    updateTemplate,
    onTemplateChange,
  }) {
    const [editor, setEditor] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(0);
    const [connectedEmails, setConnectedEmails] = useState([]);
    const users = useSelector(({ users }) => users);
  
    const fetchConnectedEmails = async () => {
      try {
        const userId = users.user._id;
        const response = await axios.get(OUTLOOK_EMAILS, {
          headers: {
            Authorization: users.token,
          },
          params: { userId },
        });
  
        if (response.data) {
          const emails = response.data;
  
          setConnectedEmails(emails);
        } else {
          console.error("Failed to fetch connected emails");
        }
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };
  
    useEffect(() => {
      if (!selectedTemplateId) {
        form.setFieldsValue({
          subject: "",
          message: "",
        });
        if (editor) {
          editor.setData("");
        }
      }
    }, [selectedTemplateId]);
  
    const handleInsertVariable = (variable) => {
      if (editor) {
        const insertion = `{{${variable}}}`;
  
        // Get the current selection
        const selection = editor.model.document.selection;
  
        // Insert content at cursor position
        editor.model.change((writer) => {
          // If there's a selection, remove it first
          if (!selection.isCollapsed) {
            editor.model.deleteContent(selection);
          }
  
          // Insert the variable at current position
          const insertPosition = selection.getFirstPosition();
          editor.model.insertContent(
            writer.createText(insertion),
            insertPosition
          );
        });
  
        // Update form value with new content
        form.setFieldsValue({ message: editor.getData() });
      }
    };
  
    const handleSubjectInsertVariable = (variable) => {
      const currentSubject = form.getFieldValue("subject") || "";
      const insertion = `{{${variable}}}`;
      form.setFieldsValue({ subject: currentSubject + insertion });
    };
  
    const handleTemplateSelect = async (value) => {
      if (value === "last_used") {
        setSelectedTemplateId(null);
        const lastTemplate = localStorage.getItem("lastUsedTemplate");
        if (lastTemplate) {
          const parsedTemplate = JSON.parse(lastTemplate);
          form.setFieldsValue({
            subject: parsedTemplate.subject || "",
            message: parsedTemplate.message || "",
          });
          if (editor) {
            editor.setData(parsedTemplate.message || "");
          }
        }
        return;
      }
  
      if (value) {
        setSelectedTemplateId(value);
        const template = templates.find((t) => t._id === value);
        if (template) {
          form.setFieldsValue({
            subject: template.subject || "",
            message: template.content || "",
          });
          if (editor) {
            editor.setData(template.content || "");
          }
        }
      } else {
        setSelectedTemplateId(null);
        form.setFieldsValue({
          subject: "",
          message: "",
        });
        if (editor) {
          editor.setData("");
        }
      }
    };
  
    const handleSaveTemplate = async () => {
      const values = form.getFieldsValue();
      const templateData = {
        subject: values.subject,
        template_name: values.subject,
        content: values.message,
        email_type: "custom",
      };
  
      try {
        let response;
        if (selectedTemplateId) {
          response = await updateTemplate.put(templateData, selectedTemplateId);
          message.success("Template updated successfully");
        } else {
          response = await createTemplate.post(templateData);
          message.success("New template saved successfully");
          if (response?.data?.data?._id) {
            setSelectedTemplateId(response.data.data._id);
          }
        }
  
        setForceUpdate((prev) => prev + 1);
        if (onTemplateChange) {
          await onTemplateChange();
        }
      } catch (err) {
        message.error("Failed to save template");
        console.error(err);
      }
    };
  
    const onSendEmailWrapper = (values) => {
      const replacedSubject = values.subject.replace(
        /{{(\w+)}}/g,
        (match, p1) => {
          if (p1 === "name" && values.to[0]) {
            return values.to[0].split("@")[0];
          }
          return match;
        }
      );
  
      // Transform the from field to fromEmail before sending
      const updatedValues = { 
        ...values, 
        subject: replacedSubject,
        fromEmail: values.from, // Add fromEmail property with value from "from"
      };
      
      // Remove the original "from" field if you don't want to send it
      delete updatedValues.from;
      
      localStorage.setItem("lastUsedTemplate", JSON.stringify(updatedValues));
      sendEmail(updatedValues);
    };
    
    useEffect(() => {
      if (users.token && users.user._id) {
        fetchConnectedEmails();
      }
    }, [users.token, users.user._id]);
  
    useEffect(() => {
      if (createTemplate.error || updateTemplate.error) {
        message.error("Failed to save template");
      }
    }, [createTemplate.error, updateTemplate.error]);
  
    const subjectVariables = [
      { key: "name", label: "Name" },
      { key: "job_title", label: "Job Title" },
    ];
  
    const contentVariables = [
      { key: "name", label: "Name" },
      { key: "email", label: "Email" },
      { key: "phone", label: "Phone" },
      { key: "job_title", label: "Job Title" },
      { key: "location", label: "Location" },
      { key: "short_description", label: "Short Description" },
    ];
  
    return (
      <Form
        form={form}
        name="email_form"
        onFinish={onSendEmailWrapper}
        onFinishFailed={onFinishFailed}
        initialValues={emailData}
        layout="vertical"
        style={{ maxWidth: "800px", width: "100%", margin: "auto" }}
      >
        <Form.Item
          name="to"
          label="To"
          rules={[{ required: true, message: "Please enter the email" }]}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Send Email To:"
          >
            {emailData.to.map((to, idx) => (
              <Option key={idx} value={to}>
                <Tag color="blue">{to}</Tag>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="from"
          label="From"
          rules={[{ required: true, message: "Please select the email" }]}
        >
          <Select
            placeholder="Select Email"
            style={{ width: "100%" }}
          >
            {connectedEmails.map((email) => (
              <Option key={email.email} value={email.email}>
              </Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item label="Templates">
          <Select
            key={`template-select-${forceUpdate}`}
            placeholder="Select a template"
            onChange={handleTemplateSelect}
            value={selectedTemplateId}
            style={{ marginBottom: "10px" }}
            allowClear
            loading={isLoading}
            showSearch
            optionFilterProp="children"
          >
            <Option value="last_used">Last Used Template</Option>
            {templates?.map((template) => (
              <Option key={template._id} value={template._id}>
                {template?.template_name ||
                  template?.subject ||
                  "Untitled Template"}
              </Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label={
            <div>
              Subject
              <div style={{ marginTop: "5px" }}>
                <Space wrap>
                  {subjectVariables.map((variable) => (
                    <Button
                      key={variable.key}
                      type="default"
                      size="small"
                      onClick={() => handleSubjectInsertVariable(variable.key)}
                    >
                      {variable.label}
                    </Button>
                  ))}
                </Space>
              </div>
            </div>
          }
          name="subject"
          rules={[{ required: true, message: "Please enter the email subject" }]}
        >
          <Input placeholder="Email Subject" />
        </Form.Item>
  
        <Form.Item label="Variables">
          <Space wrap>
            {contentVariables.map((variable) => (
              <Button
                key={variable.key}
                type="primary"
                onClick={() => handleInsertVariable(variable.key)}
              >
                {variable.label}
              </Button>
            ))}
          </Space>
        </Form.Item>
  
        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: "Please enter your message" }]}
        >
          <CKEditor
            editor={ClassicEditor}
            onReady={(editor) => {
              setEditor(editor);
              const initialContent = form.getFieldValue("message");
              if (initialContent) {
                editor.setData(initialContent);
              }
            }}
            onChange={(event, editor) => {
              form.setFieldsValue({ message: editor.getData() });
            }}
          />
        </Form.Item>
  
        <Form.Item
          label="Pause Second"
          name="pause"
          rules={[{ required: true, message: "Please enter the pause second" }]}
          initialValue={2}
        >
          <Input type="number" defaultValue={2} min={2} />
        </Form.Item>
  
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "10px" }}
          >
            Send Email
          </Button>
          <Button
            onClick={handleSaveTemplate}
            loading={createTemplate.loading || updateTemplate.loading}
          >
            {selectedTemplateId ? "Update Template" : "Save as Template"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
