import {
  ColumnHeightOutlined,
  EditFilled,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  message,
  Popover,
  Progress,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { COPILOT_GET_USER_CAMPAIGNS } from "../../../ApiUrl";
import CampaignSettings from "./CampaignSettings";

const { Text } = Typography;

const CampaignsListing = () => {
  // const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const users = useSelector(({ users }) => users);
  const [state, setState] = useState({
    current_page: 1,
    pageSize: 10,
    campaigns: [],
    totalPages: 0,
    totalRecords: 0,
  });
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const savedColumns = localStorage.getItem("campaignTableColumns");
    return savedColumns
      ? JSON.parse(savedColumns)
      : {
          campaign_name: true,
          opening_title: true,
          status: true,
          local_sourcing: true,
          monster_sourcing: true,
          cb_sourcing: true,
          total_sourced_v2: true,
          progress: true,
          total_emails_sent: true,
          total_emails_bounced: true,
          total_emails_replied: true,
          start_date: true,
          end_date: true,
          created_at: true,
          actions: true,
        };
  });

  // Save column visibility to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(
      "campaignTableColumns",
      JSON.stringify(visibleColumns)
    );
  }, [visibleColumns]);

  const handleColumnVisibilityChange = (columnKey) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  };

  const ColumnCustomizer = () => (
    <div className="column-customizer">
      <div
        style={{
          padding: "20px",
          width: "350px",
          background: "#fff",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
            borderBottom: "1px solid #f0f0f0",
            paddingBottom: "12px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <ColumnHeightOutlined />
          <Typography.Text strong>Customize Table Columns</Typography.Text>
        </div>
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          {Object.keys(visibleColumns).map((columnKey) => (
            <div
              key={columnKey}
              style={{
                marginBottom: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px",
                background: "#f5f5f5",
                borderRadius: "6px",
                transition: "all 0.3s ease",
                cursor: "pointer",
                ":hover": {
                  background: "#f0f0f0",
                },
              }}
            >
              <Typography.Text
                style={{
                  color: "#262626",
                  fontSize: "14px",
                  fontWeight: visibleColumns[columnKey] ? "500" : "400",
                }}
              >
                {columnKey
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </Typography.Text>
              <Switch
                checked={visibleColumns[columnKey]}
                onChange={() => handleColumnVisibilityChange(columnKey)}
                size="small"
                className="column-switch"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const fetchCampaigns = async () => {
    // page=1&per_page=10&order=created_at&order_direction=desc
    console.log("🚀 ~ CampaignsListing ~ state:", state);
    const params = {
      page: state.current_page,
      per_page: state.pageSize,
      order_direction: "desc",
      order: "created_at",
    };
    try {
      setLoading(true);
      const response = await axios.get(COPILOT_GET_USER_CAMPAIGNS, {
        headers: { Authorization: users.token },
        params,
      });
      setState((prev) => ({
        ...prev,
        ...response.data.data,
      }));
    } catch (error) {
      message.error("Failed to load campaigns. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const createNewClientPath =
    users.role === "admin"
      ? "admin"
      : users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;

  useEffect(() => {
    fetchCampaigns();
  }, [state.current_page]);

  // Handle opening the modal and setting the campaign
  const handleEditCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setIsModalVisible(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setSelectedCampaign(null);
    setIsModalVisible(false);
  };

  // Define table columns
  const columns = [
    {
      title: "Name",
      dataIndex: "campaign_name",
      show: true,
      key: "campaign_name",
      render: (data, record) => (
        <Tooltip
          color="#1a1a27"
          title={
            <Space>
              <Tooltip title="View Details">
                <NavLink
                  to={{
                    pathname: `/${createNewClientPath}/campaign-detail/${record._id}`,
                  }}
                >
                  <Button type="primary" icon={<EyeOutlined />} />
                </NavLink>
              </Tooltip>
              <Tooltip title="Edit Campaign">
                <Button
                  icon={<EditFilled />}
                  onClick={() => handleEditCampaign(record)}
                />
              </Tooltip>
            </Space>
          }
        >
          <NavLink
            to={{
              pathname: `/${createNewClientPath}/campaign-detail/${record._id}`,
              state: { record: record },
            }}
          >
            {data}
          </NavLink>
        </Tooltip>
      ),
    },

    {
      title: "Opening Title",
      key: "opening_title",
      render: (code, row) => row?.opening?.opening_title,
      hidden: !visibleColumns.opening_title,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={
            status === "sourcing_completed"
              ? "success"
              : status === "in_sourcing"
              ? "processing"
              : "warning"
          }
        >
          {status?.replace(/_/g, " ")}
        </Tag>
      ),
      hidden: !visibleColumns.status,
    },
    {
      title: "Total Sourcing Required (Local)",
      key: "local_sourcing",
      render: (code, row) => row?.number_of_source_per_platforms?.local,
      hidden: !visibleColumns.local_sourcing,
    },
    {
      title: "Total Sourcing Required (Monster)",
      key: "monster_sourcing",
      render: (code, row) => row?.number_of_source_per_platforms?.monster,
      hidden: !visibleColumns.monster_sourcing,
    },
    {
      title: "Total Sourcing Required (Career Builder)",
      key: "cb_sourcing",
      render: (code, row) => row?.number_of_source_per_platforms?.cb,
      hidden: !visibleColumns.cb_sourcing,
    },
    {
      title: "Total Sourced",
      key: "total_sourced_v2",
      render: (code, row) => {
        const monster = row?.number_of_source_per_platforms?.monster || 0;
        const local = row?.number_of_source_per_platforms?.local || 0;
        const cb = row?.number_of_source_per_platforms?.cb || 0;
        return monster + local + cb;
      },
      hidden: !visibleColumns.total_sourced_v2,
    },
    {
      title: "Progress",
      key: "progress",
      render: (code, row) => {
        const totalSourced =
          (row?.number_of_source_per_platforms?.monster || 0) +
          (row?.number_of_source_per_platforms?.local || 0) +
          (row?.number_of_source_per_platforms?.cb || 0);
        const totalEmailsSent = row?.total_emails_sent || 0;
        const percentage = totalSourced
          ? Math.round((totalEmailsSent / totalSourced) * 100)
          : 0;

        return (
          <Tooltip
            title={`Emails Sent: ${totalEmailsSent} / Total Sourced: ${totalSourced}`}
          >
            <Progress
              percent={percentage}
              status={percentage >= 100 ? "success" : "active"}
              strokeColor={{
                "0%": "#1890ff",
                "40%": "#40a9ff",
                "100%": "#096dd9",
              }}
              style={{ margin: 0 }}
            />
          </Tooltip>
        );
      },
      hidden: !visibleColumns.progress,
    },
    {
      title: "Total Emails Sent",
      dataIndex: "total_emails_sent",
      key: "total_emails_sent",
      render: (text, record) => record?.total_emails_sent,
      hidden: !visibleColumns.total_emails_sent,
    },
    {
      title: "Total Emails Bounced",
      dataIndex: "total_emails_bounced",
      key: "total_emails_bounced",
      render: (text, record) => record?.total_emails_bounced,
      hidden: !visibleColumns.total_emails_bounced,
    },
    {
      title: "Total Emails Replied",
      dataIndex: "total_emails_replied",
      key: "total_emails_replied",
      render: (text, record) => record?.total_emails_replied,
      hidden: !visibleColumns.total_emails_replied,
    },
    {
      title: "Start at",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => new Date(text).toLocaleDateString(), // Format the date
      hidden: !visibleColumns.start_date,
    },
    {
      title: "End at",
      dataIndex: "end_date",
      key: "end_date",
      render: (text) => new Date(text).toLocaleDateString(), // Format the date
      hidden: !visibleColumns.end_date,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleDateString(), // Format the date
      hidden: !visibleColumns.created_at,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            icon={<EditFilled />}
            onClick={() => handleEditCampaign(record)}
          />

          <NavLink
            to={{
              pathname: `/${createNewClientPath}/campaign-detail/${record._id}`,
            }}
          >
            <Button type="primary" icon={<EyeOutlined />} />
          </NavLink>
        </>
      ),
      hidden: !visibleColumns.actions,
    },
  ].filter((column) => !column.hidden);

  if (loading) {
    return (
      <Spin
        tip="Loading campaigns..."
        style={{ display: "block", textAlign: "center", marginTop: "20px" }}
      />
    );
  }

  return (
    <Card
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Co-Pilot List
          </Typography.Title>
        </div>
      }
      style={{ margin: "20px" }}
      className="campaigns-card"
    >
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
        gutter={[16, 16]}
      >
        <Col xs={24} sm={12} md={8} lg={6}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search Co-Pilots..."
            onChange={(e) => {
              // Add search functionality if needed
            }}
            className="search-input"
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs={24} sm={12} md={16} lg={18}>
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}
          >
            <Space>
              <Tooltip title="Rows per page">
                <InputNumber
                  min={5}
                  max={100}
                  defaultValue={state.pageSize}
                  onChange={(value) => {
                    setState((prev) => ({ ...prev, pageSize: value }));
                  }}
                  style={{ width: "80px" }}
                />
              </Tooltip>
              <Popover
                content={<ColumnCustomizer />}
                trigger="click"
                placement="bottomRight"
                overlayClassName="column-customizer-popover"
              >
                <Button
                  icon={<ColumnHeightOutlined />}
                  className="customize-columns-btn"
                >
                  Customize Columns
                </Button>
              </Popover>
            </Space>
          </div>
        </Col>
      </Row>

      {state.campaigns.length === 0 ? (
        <div style={{ textAlign: "center", padding: "40px" }}>
          <Typography.Text>No Co-Pilots found.</Typography.Text>
        </div>
      ) : (
        <Table
          pagination={{
            total: state.totalRecords,
            showSizeChanger: false,
            pageSize: state.pageSize,
            current: state.current_page,
            onChange(current) {
              setState((prev) => ({ ...prev, current_page: current }));
            },
          }}
          dataSource={state.campaigns}
          columns={columns}
          rowKey={(record) => record._id}
          scroll={{ x: "auto", y: "calc(100vh - 300px)" }}
          className="campaigns-table"
        />
      )}
      <CampaignSettings
        campaign={selectedCampaign}
        visible={isModalVisible}
        onClose={handleCloseModal}
        fetchCampaigns={fetchCampaigns}
      />
    </Card>
  );
};

export default CampaignsListing;
