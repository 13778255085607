import {
  BarChartOutlined,
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  EyeOutlined,
  FileSearchOutlined,
  MailOutlined,
  ReloadOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Chat } from "@material-ui/icons";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Empty,
  Progress,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import {
  COPILOT_APPROVE_BOOLEAN_STRING,
  COPILOT_GET_CAMPAIGN_ACTIVITIES,
  COPILOT_GET_CAMPAIGN_DETAIL_BY_ID,
  COPILOT_GET_SOURCED_CANDIDATES,
  COPILOT_REGENRATE_BOOLEAN_STRING,
} from "../../../ApiUrl";
import "./campaign_details.css";
import WeeklyHours from "./CampaignSlots";
import EmailConversationModal from "./EmailConvoModal";
import MeetingModal from "./MeetingModal";
import ScreeningQuestions from "./ScreeningQuestions";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const activityTimelineStyles = `
  .activity-timeline-container {
    scrollbar-width: thin;
    scrollbar-color: #d9d9d9 #f5f5f5;
    padding: 0 16px;
  }
  
  .activity-timeline-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .activity-timeline-container::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 3px;
  }
  
  .activity-timeline-container::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 3px;
  }
  
  .activity-timeline-container::-webkit-scrollbar-thumb:hover {
    background-color: #bfbfbf;
  }

  .timeline-item {
    padding: 12px 16px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    margin-bottom: 16px;
    transition: all 0.3s ease;
    margin-left: 28px;
  }

  .timeline-item:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    transform: translateY(-2px);
  }

  .timeline-content {
    margin-left: 0;
  }

  .timeline-date-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    color: #8c8c8c;
  }

  .timeline-date {
    font-size: 12px;
  }

  .timeline-date-divider {
    width: 3px;
    height: 3px;
    background-color: #d9d9d9;
    border-radius: 50%;
  }

  .timeline-exact-date {
    font-size: 12px;
    color: #bfbfbf;
  }

  .ant-timeline {
    padding-left: 12px !important;
  }

  .ant-timeline-item {
    padding-bottom: 32px;
    padding-left: 40px;
  }

  .ant-timeline-item-tail {
    left: 20px;
    border-left: 2px solid #f0f0f0;
  }

  .ant-timeline-item-head {
    width: 40px;
    height: 40px;
    border: none;
    margin-left: 0;
  }

  .ant-timeline-item-head-custom {
    width: 40px;
    height: 40px;
    margin-top: 0;
    padding: 0;
    position: absolute;
    left: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .load-more-container {
    padding: 16px;
    text-align: center;
    background: #fafafa;
    border-radius: 0 0 8px 8px;
    margin-top: -16px;
  }

  .load-more-button {
    border-radius: 20px;
    padding: 0 24px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

const CampaignDetailPage = () => {
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { campaignId } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [meetingModalVisible, setModalMeetingVisible] = useState(false);
  const users = useSelector(({ users }) => users);
  const [sourcedCandidateId, setSourcedCandidateId] = useState(null);
  const [sourcedCandidatesData, setSourcedCandidatesData] = useState({
    candidates: [],
    pagination: {
      current_page: 1,
      total_pages: 0,
      total_records: 0,
      per_page: 10,
    },
  });
  const [sourcedCandidatesLoading, setSourcedCandidatesLoading] = useState(
    false
  );
  const [activities, setActivities] = useState([]);
  const [activitiesLoading, setActivitiesLoading] = useState(false);
  const [activitiesPage, setActivitiesPage] = useState(1);
  const [hasMoreActivities, setHasMoreActivities] = useState(true);
  const [activitiesPagination, setActivitiesPagination] = useState({
    current_page: 1,
    total_pages: 0,
    total_records: 0,
    per_page: 10,
  });
  const createNewClientPath =
    users.role === "admin"
      ? "admin"
      : users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;

  // Fetch campaign details without sourced candidates
  const fetchCampaignDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${COPILOT_GET_CAMPAIGN_DETAIL_BY_ID}/${campaignId}`,
        {
          headers: { Authorization: users.token },
        }
      );
      setCampaign(response.data.data);
    } catch (err) {
      setError(err.message || "Failed to fetch campaign details.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch sourced candidates separately with filters
  const fetchSourcedCandidates = async (
    page = 1,
    pageSize = 10,
    tableFilters = {},
    sorter = {}
  ) => {
    try {
      setSourcedCandidatesLoading(true);
      const url = COPILOT_GET_SOURCED_CANDIDATES.replace(
        ":campaign_id",
        campaignId
      );

      // Extract single values from filter arrays
      const emailStatus = tableFilters.email_status?.[0];
      const platform = tableFilters.platform_sourced_from?.[0];

      // Only include sort parameters if there's actual sorting
      const sortParams = sorter.field
        ? {
            sort_field: sorter.field,
            sort_order: sorter.order,
          }
        : {};

      const response = await axios.get(url, {
        headers: { Authorization: users.token },
        params: {
          page,
          per_page: pageSize,
          email_status: emailStatus,
          platform: platform,
          ...sortParams,
        },
      });

      setSourcedCandidatesData(response.data.data);
    } catch (err) {
      setError(err.message || "Failed to fetch sourced candidates.");
    } finally {
      setSourcedCandidatesLoading(false);
    }
  };

  const generateMockActivities = (page) => {
    const activitiesPerPage = 10;
    const totalActivities = 35;
    const startIndex = (page - 1) * activitiesPerPage;
    const endIndex = Math.min(startIndex + activitiesPerPage, totalActivities);

    const mockActivities = [];
    for (let i = startIndex; i < endIndex; i++) {
      mockActivities.push({
        activity_log: `Mock Activity ${i + 1}: ${
          [
            "Candidate sourced",
            "Email sent",
            "Profile viewed",
            "Interview scheduled",
          ][i % 4]
        }`,
        created_at: new Date(Date.now() - i * 3600000).toISOString(),
      });
    }
    return {
      activities: mockActivities,
      hasMore: endIndex < totalActivities,
    };
  };

  const fetchActivities = async (page = 1) => {
    try {
      setActivitiesLoading(true);
      const url = COPILOT_GET_CAMPAIGN_ACTIVITIES.replace(
        ":campaign_id",
        campaignId
      );

      const response = await axios.get(url, {
        headers: { Authorization: users.token },
        params: {
          page,
          per_page: 10,
        },
      });

      const { activities: newActivities, pagination } = response.data.data;

      if (page === 1) {
        setActivities(newActivities);
      } else {
        setActivities((prev) => [...prev, ...newActivities]);
      }

      setActivitiesPagination(pagination);
      setHasMoreActivities(pagination.has_more);
      setActivitiesPage(page);
    } catch (err) {
      setError(err.message || "Failed to fetch activities.");
    } finally {
      setActivitiesLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaignDetails();
    fetchActivities(1);
  }, [campaignId]);

  const handleRegenerate = async (index) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${COPILOT_REGENRATE_BOOLEAN_STRING}`,
        { campaign_id: campaignId },
        {
          headers: { Authorization: users.token },
        }
      );
      await fetchCampaignDetails();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message || "Failed to regenerate boolean string.");
    }
  };

  // Handle Approve Boolean String
  const handleApprove = async (index) => {
    try {
      setLoading(true);
      await axios.post(
        `${COPILOT_APPROVE_BOOLEAN_STRING}`,
        { campaign_id: campaignId },
        {
          headers: { Authorization: users.token },
        }
      );
      await fetchCampaignDetails();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message || "Failed to approve boolean string.");
    }
  };

  // Handle table changes including sorting and filtering
  const handleTableChange = (pagination, filters, sorter) => {
    fetchSourcedCandidates(pagination.current, pagination.pageSize, filters, {
      field: sorter.field,
      order:
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : undefined,
    });
  };

  // Columns for sourced candidates table
  const sourcedCandidatesColumns = [
    {
      title: "Name",
      dataIndex: ["candidate_name"],
      key: "candidate_name",
    },
    {
      title: "Email",
      dataIndex: ["candidate_email"],
      key: "email",
    },
    {
      title: "Platform",
      dataIndex: "platform_sourced_from",
      key: "platform_sourced_from",
      filters: [
        { text: "Monster", value: "monster" },
        { text: "CareerBuilder", value: "cb" },
        { text: "Local", value: "local" },
      ],
      filterMultiple: false,
      width: 150,
      render: (platform) => {
        const color =
          platform === "monster"
            ? "purple"
            : platform === "cb"
            ? "blue"
            : platform === "local"
            ? "cyan"
            : "default";
        return <Tag color={color}>{platform || "N/A"}</Tag>;
      },
    },
    {
      title: "AI Score",
      dataIndex: "ai_score",
      key: "ai_score",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      width: 120,
      render: (score) => (score ? score.toFixed(2) : "N/A"),
    },
    {
      title: "Email Status",
      dataIndex: "email_status",
      key: "email_status",
      filters: [
        { text: "Sent", value: "sent" },
        { text: "Replied", value: "replied" },
        { text: "Bounced", value: "bounced" },
        { text: "Not Sent", value: "not_sent" },
      ],
      filterMultiple: false,
      width: 150,
      render: (status) => {
        const color =
          status === "sent"
            ? "blue"
            : status === "replied"
            ? "green"
            : status === "bounced"
            ? "red"
            : "default";
        return <Tag color={color}>{status || "Not Sent"}</Tag>;
      },
    },

    {
      title: "Email Verified",
      dataIndex: "email_verified",
      key: "email_verified",
      render: (verified) => (
        <Tag color={verified ? "green" : "red"}>
          {verified ? "Verified" : "Not Verified"}
        </Tag>
      ),
    },
    {
      title: "Email Sent",
      dataIndex: "email_sent",
      key: "email_sent",
      render: (sent) => (
        <Tag color={sent ? "green" : "red"}>{sent ? "Sent" : "Not Sent"}</Tag>
      ),
    },
    {
      title: "Email Replied",
      dataIndex: "email_replied",
      key: "email_replied",
      filters: [
        { text: "Replied", value: true },
        { text: "No Reply", value: false },
      ],
      filterMultiple: false,
      width: 150,
      render: (replied) => (
        <Tag color={replied ? "green" : "gray"}>
          {replied ? "Replied" : "No Reply"}
        </Tag>
      ),
    },
    {
      title: "Conversation",
      // dataIndex: "email_sent",
      // key: "email_sent",
      render: (sent, record) => (
        <Button
          onClick={() => {
            setSourcedCandidateId(record._id);
            setModalVisible(true);
          }}
          icon={<Chat />}
        />
      ),
    },
    {
      title: "Current CTC",
      dataIndex: "current_ctc",
      key: "current_ctc",
    },
    {
      title: "Expected CTC",
      dataIndex: "expected_ctc",
      key: "expected_ctc",
    },
    {
      title: "Expected Joining Date",
      dataIndex: "expected_joining_date",
      key: "expected_joining_date",
    },
    {
      title: "Notice Period",
      dataIndex: "notice_period",
      key: "notice_period",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        console.log("record  ", record);
        const candidate = {
          candidate_id: record?.candidate_id,
          // candidate_id: "67b460e37624457ce7a7b072",
          opening_id: campaign?.opening?.opening_id,
          opening_details: {
            opening_id: campaign?.opening?.opening_id,
          },
          _id: record?._id,
        };
        console.log("candidate  ", candidate);
        return (
          <>
            <Space>
              <NavLink
                to={{
                  pathname: `/${createNewClientPath}/candidateProfileDetail`,
                  state: { item: candidate },
                }}
              >
                <Button type="primary" icon={<EyeOutlined />} />
              </NavLink>
              <Button
                type="primary"
                onClick={() => {
                  setSourcedCandidateId(record._id);
                  setModalMeetingVisible(true);
                }}
                icon={<VideoCameraOutlined />}
              />
            </Space>
          </>
        );
      },
    },
  ];

  // Add this helper function for relative time
  const getRelativeTime = (date) => {
    const now = new Date();
    const past = new Date(date);
    const diffInSeconds = Math.floor((now - past) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSeconds < 60) {
      return "just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    } else if (diffInDays === 1) {
      return "yesterday";
    } else if (diffInDays < 7) {
      return `${diffInDays}d ago`;
    } else {
      return past.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: past.getFullYear() !== now.getFullYear() ? "numeric" : undefined,
      });
    }
  };

  // Add this helper function to get activity icon and color
  const getActivityIconAndColor = (activity) => {
    const activityLog = activity.activity_log.toLowerCase();

    if (activityLog.includes("sourced")) {
      return { icon: <SearchOutlined />, color: "#1890ff", bgColor: "#e6f7ff" };
    } else if (activityLog.includes("email") || activityLog.includes("sent")) {
      return { icon: <MailOutlined />, color: "#52c41a", bgColor: "#f6ffed" };
    } else if (
      activityLog.includes("interview") ||
      activityLog.includes("scheduled")
    ) {
      return {
        icon: <CalendarOutlined />,
        color: "#722ed1",
        bgColor: "#f9f0ff",
      };
    } else if (
      activityLog.includes("profile") ||
      activityLog.includes("candidate")
    ) {
      return { icon: <UserOutlined />, color: "#fa8c16", bgColor: "#fff7e6" };
    }
    return { icon: <UserOutlined />, color: "#1890ff", bgColor: "#e6f7ff" };
  };

  // Render Loading/ Error / Content
  if (loading) return <Spin tip="Loading Co-Pilot details..." />;
  if (error)
    return <Alert message="Error" description={error} type="error" showIcon />;
  const StatCard = ({ title, value, icon, color }) => (
    <div className="stat-card">
      <div className="stat-icon" style={{ backgroundColor: `${color}15` }}>
        {icon}
      </div>
      <div className="stat-content">
        <Text type="secondary">{title}</Text>
        <Title level={4} style={{ margin: 0 }}>
          {value}
        </Title>
      </div>
    </div>
  );
  const emailOpenRate =
    campaign.email_statistics.total_sent > 0
      ? (
          (campaign.email_statistics.total_opened /
            campaign.email_statistics.total_sent) *
          100
        ).toFixed(1)
      : 0;

  const emailReplyRate =
    campaign.email_statistics.total_sent > 0
      ? (
          (campaign.email_statistics.total_replied /
            campaign.email_statistics.total_sent) *
          100
        ).toFixed(1)
      : 0;
  return (
    <Card
      title={`Co-Pilot Details - ${campaign.opening?.opening_title ||
        "Untitled"}`}
    >
      <style>{activityTimelineStyles}</style>
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => {
          if (key === "2") {
            fetchSourcedCandidates();
          }
        }}
      >
        {/* Overview Tab */}
        <TabPane tab="Overview" key="1">
          <div className="tab-content">
            {/* Header Section with Key Metrics */}
            <Card className="dashboard-header" bordered={false}>
              <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                  <div className="opening-details">
                    <Badge
                      color={campaign.status === "Active" ? "green" : "orange"}
                      text={campaign.status?.replace(/_/g, " ")}
                      style={{
                        fontSize: "14px",
                        textTransform: "capitalize",
                        fontWeight: "500",
                      }}
                    />
                    <Title
                      level={3}
                      style={{ marginTop: "8px", marginBottom: "4px" }}
                    >
                      {campaign.opening?.opening_title || "Untitled Title"}
                    </Title>
                    <Text type="secondary" style={{ fontSize: "16px" }}>
                      {campaign.opening?.short_description ||
                        "No description available."}
                    </Text>

                    <Space style={{ marginTop: "16px", marginLeft: "16px" }}>
                      <Tag icon={<CalendarOutlined />} color="blue">
                        {new Date(campaign.start_date).toLocaleDateString()} -{" "}
                        {new Date(campaign.end_date).toLocaleDateString()}
                      </Tag>
                      <Tag icon={<UserOutlined />} color="purple">
                        {campaign.total_sourced || 0} Candidates
                      </Tag>
                    </Space>
                  </div>
                </Col>

                <Col xs={24} md={12}>
                  <Row gutter={[16, 16]}>
                    <Col xs={12}>
                      <StatCard
                        title="Monster Candidates"
                        value={campaign.total_monster_sourced || 0}
                        icon={<UsergroupAddOutlined />}
                        color="#1890ff"
                      />
                    </Col>
                    <Col xs={12}>
                      <StatCard
                        title="CareerBuilder Candidates"
                        value={campaign.total_cb_sourced || 0}
                        icon={<FileSearchOutlined />}
                        // icon={<UserOutlined />}
                        color="#52c41a"
                      />
                    </Col>
                    <Col xs={12}>
                      <StatCard
                        title="Bix3 Candidates"
                        value={campaign.total_local_sourced || 0}
                        icon={<UserOutlined />}
                        color="#722ed1"
                      />
                    </Col>
                    <Col xs={12}>
                      <StatCard
                        title="Total Emails Sent"
                        value={campaign.email_statistics.total_sent || 0}
                        icon={<MailOutlined />}
                        color="#fa8c16"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>

            {/* Main Content Area */}
            <Row gutter={[24, 24]} style={{ marginTop: "24px" }}>
              {/* Left Column */}
              <Col xs={24} lg={12}>
                {/* Email Performance Card */}
                <Card
                  title={
                    <>
                      <MailOutlined /> Email Performance
                    </>
                  }
                  className="dashboard-card"
                  bordered={false}
                  style={{ marginBottom: "24px" }}
                >
                  <Row gutter={[16, 24]}>
                    <Col span={8}>
                      <Statistic
                        title="Total Sent"
                        value={campaign.email_statistics.total_sent || 0}
                        valueStyle={{ color: "#1890ff" }}
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title="Replied"
                        value={campaign.email_statistics.total_replied || 0}
                        valueStyle={{ color: "#52c41a" }}
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title="Bounced"
                        value={campaign.email_statistics.total_bounced || 0}
                        valueStyle={{ color: "#ff4d4f" }}
                      />
                    </Col>
                    <Col span={24}>
                      <div style={{ marginBottom: "8px" }}>
                        <Space>
                          <Text>Reply Rate</Text>
                          <Text strong>{emailReplyRate}%</Text>
                        </Space>
                      </div>
                      <Progress
                        percent={emailReplyRate}
                        strokeColor="#52c41a"
                        trailColor="#f0f0f0"
                        showInfo={false}
                        strokeWidth={10}
                        style={{ marginBottom: "16px" }}
                      />
                    </Col>
                  </Row>
                </Card>

                {/* Boolean Strings Card */}
                <Card
                  title={
                    <>
                      <BarChartOutlined /> Boolean Search Strings
                    </>
                  }
                  className="dashboard-card"
                  bordered={false}
                  extra={
                    campaign.is_boolean_string_verified ? (
                      <Badge
                        count="Approved"
                        style={{ backgroundColor: "#52c41a" }}
                      />
                    ) : (
                      <Space>
                        <Button
                          type="default"
                          icon={<ReloadOutlined />}
                          onClick={handleRegenerate}
                          size="small"
                        >
                          Regenerate
                        </Button>
                        <Button
                          type="primary"
                          icon={<CheckOutlined />}
                          onClick={handleApprove}
                          size="small"
                        >
                          Approve
                        </Button>
                      </Space>
                    )
                  }
                >
                  {campaign.boolean_strings &&
                  campaign.boolean_strings.length > 0 ? (
                    <div className="boolean-strings-container">
                      {campaign.boolean_strings.map((booleanString, index) => (
                        <div key={index} className="boolean-string-item">
                          <Text code>{index + 1}.</Text>
                          <div className="boolean-string-content">
                            <Text copyable>{booleanString || "N/A"}</Text>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Empty
                      description="No boolean strings available"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                </Card>
              </Col>

              {/* Right Column */}
              <Col xs={24} lg={12}>
                {/* Activity Timeline Card */}
                <Card
                  title={
                    <>
                      <ClockCircleOutlined /> Activity Timeline
                    </>
                  }
                  className="dashboard-card timeline-card"
                  bordered={false}
                >
                  <div className="timeline-container p-5">
                    {activities.length > 0 ? (
                      <>
                        <Timeline>
                          {activities.map((activity, index) => {
                            const {
                              icon,
                              color,
                              bgColor,
                            } = getActivityIconAndColor(activity);
                            const activityDate = new Date(activity.created_at);
                            const formattedDate = activityDate.toLocaleDateString(
                              "en-US",
                              {
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            );

                            return (
                              <Timeline.Item
                                key={activity._id || index}
                                color={color}
                                dot={
                                  <Tooltip title={formattedDate}>
                                    <div
                                      className="timeline-dot"
                                      style={{
                                        backgroundColor: bgColor,
                                        borderColor: color,
                                      }}
                                    >
                                      {icon}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <div className="timeline-item">
                                  <Text>{activity.activity_log}</Text>
                                  <div className="timeline-meta">
                                    <Text type="secondary">
                                      {getRelativeTime(activity.created_at)}
                                    </Text>
                                  </div>
                                </div>
                              </Timeline.Item>
                            );
                          })}
                        </Timeline>

                        {hasMoreActivities && (
                          <div className="load-more-container">
                            <Button
                              onClick={() =>
                                fetchActivities(activitiesPage + 1)
                              }
                              loading={activitiesLoading}
                              type="primary"
                              style={{ width: "100%" }}
                            >
                              {activitiesLoading
                                ? "Loading..."
                                : "Load More Activities"}
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <Empty
                        description="No activity timeline available"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </TabPane>

        {/* Sourced Candidates Tab */}
        <TabPane tab="Sourced Candidates" key="2">
          <Table
            loading={sourcedCandidatesLoading}
            dataSource={sourcedCandidatesData.candidates}
            columns={sourcedCandidatesColumns}
            rowKey={(record) => record._id}
            pagination={{
              current: sourcedCandidatesData.pagination.current_page,
              pageSize: sourcedCandidatesData.pagination.per_page,
              total: sourcedCandidatesData.pagination.total_records,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} candidates`,
            }}
            onChange={handleTableChange}
            scroll={{ x: "auto" }}
          />

          <MeetingModal
            candidateId={sourcedCandidateId}
            visible={meetingModalVisible}
            onClose={() => setModalMeetingVisible(false)}
          />
          <EmailConversationModal
            sourcedCandidateId={sourcedCandidateId}
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
          />
        </TabPane>

        <TabPane tab="Interview Slots" key={"3"}>
          <WeeklyHours campaignId={campaignId} />
        </TabPane>

        <TabPane tab="Screening Questions" key={"4"}>
          <ScreeningQuestions />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default CampaignDetailPage;
