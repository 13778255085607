import {
  Avatar,
  Card,
  Checkbox,
  Divider,
  Tag,
  Typography,
  Button,
  Space,
  Row,
  Col,
  Radio,
  Timeline,
  Tabs,
  Image,
  Empty,
} from "antd";
import {
  EyeOutlined,
  SendOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkedinOutlined,
  GlobalOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { NavLink } from "react-router-dom";
import ExpandableContent from "../../../../others/utils/ExpandableContent";
import { Email } from "@material-ui/icons";
import { toAbsoluteUrl } from "../../../../_theme_parts/_helpers";

const { Text, Link } = Typography;

export const CandidateCard = ({
  cndidate,
  candidate,
  candidateLabel,
  getNotes,
  record,
  currentListId,
  type,
  rolePath,
  selectedCandidateIds,
  employment,
  qualifications,
  emailHistory,
  EmailStatus,
  lists,
  LinkedInInvitationStatus,
  isValidEmails,
  handleCheckboxChange,
  handleLabelChange,
  handleAddToList,
  onClickSendEmail,
  openDeleteModal,
  IMAGE_CANDIDATE_URL,
  attachments,
  getLinkedInUrl,
}) => {
  return (
    <Card
      style={{
        maxWidth: "100%",
        borderRadius: "12px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
        marginBottom: "24px",
      }}
    >
      {/* Main Header Section */}
      <Row gutter={[16, 16]}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "16px",
          }}
        >
          {/* Left Section: Checkbox & Actions */}
          <Space size={16} wrap>
            <Checkbox
              onChange={() => handleCheckboxChange(candidate?._id)}
              checked={selectedCandidateIds?.includes(candidate._id)}
            >
              {currentListId
                ? lists
                    .find((list) => list._id === currentListId)
                    ?.candidateIds?.includes(candidate._id)
                  ? "Remove from List"
                  : "Add to List"
                : "Select"}
            </Checkbox>
            <Radio.Group
              value={candidateLabel}
              onChange={(e) => handleLabelChange(candidate._id, e.target.value)}
              size="small"
              style={{
                backgroundColor: "#f0f7ff",
                padding: "4px",
                borderRadius: "6px",
              }}
            >
              <Radio.Button value="top">Best</Radio.Button>
              <Radio.Button value="good">Good</Radio.Button>
              <Radio.Button value="qualified">Qualified</Radio.Button>
            </Radio.Group>
          </Space>

          {/* Right Section: Action Buttons */}
          <Space size="middle" wrap>
            <NavLink
              to={{
                pathname: `/${rolePath}/edit-candidate`,
                state: {
                  item: { ...candidate, opening_id: record.opening_id },
                },
              }}
            >
              <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ backgroundColor: "#4a90e2", borderRadius: "6px" }}
              />
            </NavLink>
            <NavLink
              to={{
                pathname: `/${type}/candidateProfileDetail`,
                state: {
                  item: { ...candidate, opening_id: record.opening_id },
                },
              }}
            >
              <Button
                type="primary"
                icon={<EyeOutlined />}
                style={{ backgroundColor: "#4a90e2", borderRadius: "6px" }}
              />
            </NavLink>
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => openDeleteModal(candidate._id)}
              style={{ borderRadius: "6px" }}
            />
          </Space>
        </Col>

        {/* Profile Information Section */}
        <Col span={24}>
          <Row gutter={[16, 16]} align="top" wrap>
            {/* Avatar Column */}
            <Col xs={24} sm={4} md={3} xl={2}>
              <Avatar
                style={{
                  backgroundColor: "#4a90e2",
                  border: "2px solid rgba(74, 144, 226, 0.2)",
                  height: "75px",
                  width: "75px",
                  aspectRatio: "1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                }}
                icon={
                  candidate.profile_image ? (
                    <Image
                      preview={false}
                      style={{ borderRadius: "50%" }}
                      src={IMAGE_CANDIDATE_URL + candidate.profile_image}
                    />
                  ) : (
                    <Typography.Title className="mb-0 text-white">
                      {candidate.first_name?.charAt(0).toUpperCase() || ""}
                    </Typography.Title>
                  )
                }
              />
            </Col>

            {/* Main Info Column */}
            <Col xs={24} sm={20} md={24} xl={14}>
              <Space direction="vertical" size={12} style={{ width: "100%" }}>
                {/* Name and Quick Actions */}
                <Space size={16} align="center" wrap>
                  <NavLink
                    to={{
                      pathname: `/${type}/candidateProfileDetail`,
                      state: {
                        item: { ...candidate, opening_id: record.opening_id },
                      },
                    }}
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#2d4a8a",
                    }}
                  >
                    {candidate.first_name} {candidate.last_name}
                  </NavLink>
                  <Button
                    onClick={() => {
                      getNotes({ candidate });
                    }}
                    title="Notes"
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#f0f7ff",
                      borderColor: "#4a90e2",
                    }}
                  >
                    N
                  </Button>
                </Space>

                {/* Current Position & Location */}
                <Space direction="vertical" size={4} style={{ color: "#666" }}>
                  <Typography.Text>
                    {employment[0]?.is_current_company
                      ? `${employment[0].designation} at ${employment[0].organization}`
                      : "-"}
                  </Typography.Text>
                  <Typography.Text>
                    {candidate.current_location}
                  </Typography.Text>
                </Space>

                {/* Tags */}
                <Space size={8} wrap>
                  {candidate?.tags?.map((tag) => (
                    <Tag
                      key={tag}
                      style={{
                        backgroundColor: "rgba(74, 144, 226, 0.1)",
                        borderColor: "#4a90e2",
                        color: "#2d4a8a",
                        borderRadius: "16px",
                        padding: "4px 12px",
                      }}
                    >
                      {tag}
                    </Tag>
                  ))}
                </Space>

                {/* Experience Info */}
                <Space size={24} wrap>
                  <Space>
                    <BusinessCenterIcon style={{ color: "#4a90e2" }} />
                    <Typography.Text>
                      {(candidate.total_work_exp_year ?? 0) +
                        " Year(s) " +
                        (candidate.total_work_exp_month ?? 0) +
                        " Month(s)"}
                    </Typography.Text>
                  </Space>
                  {isValidEmails(candidate.email) && (
                    <Space>
                      <Email style={{ color: "#4a90e2" }} />
                      <Typography.Text>{candidate.email}</Typography.Text>
                    </Space>
                  )}
                </Space>
              </Space>
            </Col>

            {/* Right Column - Social & Downloads */}
            <Col
              xs={24}
              sm={24}
              md={8}
              xl={7}
              style={{
                whiteSpace: "nowrap",
              }}
              className="mt-4 mt-xl-0 pt-4 pt-xl-0 border-top border-xl-0 border-xl-start-0 border-xl-start-0"
            >
              <Space direction="vertical" size={16} style={{ width: "100%" }}>
                {/* Social Links */}
                <Space size={16} wrap>
                  {getLinkedInUrl(candidate?.meta) && (
                    <Typography.Link
                      href={getLinkedInUrl(candidate?.meta)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedinOutlined
                        style={{ fontSize: "24px", color: "#0077B5" }}
                      />
                    </Typography.Link>
                  )}
                  {candidate?.meta?.Indeed && (
                    <Typography.Link
                      href={candidate.meta.Indeed}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* <GlobalOutlined
                        style={{ fontSize: "24px", color: "#2164f4" }}
                      /> */}
                      <img
                        src={toAbsoluteUrl("/media/svg/icons/logos/indeed.png")}
                        alt="indeed"
                        width={100}
                      />
                    </Typography.Link>
                  )}
                  {candidate?.meta?.NurserRecruiter && (
                    <Typography.Link
                      href={candidate.meta.NurserRecruiter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* <GlobalOutlined
                        style={{ fontSize: "24px", color: "#2164f4" }}
                      /> */}
                      <img
                        src="https://cdn.nurserecruiter.com/images/logo.png?id=4d4b454e29000824bfe69c79f3681346"
                        alt="nurserecruiter"
                        width={150}
                      />
                    </Typography.Link>
                  )}
                  {isValidEmails(candidate.email) && (
                    <Button
                      title="Send Email"
                      icon={<SendOutlined />}
                      style={{
                        borderRadius: "6px",
                        borderColor: "#4a90e2",
                        color: "#4a90e2",
                      }}
                      onClick={() => onClickSendEmail(candidate)}
                    >
                      Send Email
                    </Button>
                  )}
                </Space>

                {/* Resume Download */}
                {Boolean(attachments) && (
                  <Button
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = `${process.env.REACT_APP_BASE_URL}/upload/candidate/${attachments}`;
                      link.setAttribute("download", "resume.pdf");
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                    icon={<DownloadOutlined />}
                    type="primary"
                    disabled={!attachments}
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#4a90e2",
                      color: "#fff",
                    }}
                  >
                    Download Resume
                  </Button>
                )}

                {/* Status Indicators */}
                <Space direction="vertical" size={8}>
                  <EmailStatus emailHistory={emailHistory} />
                  <LinkedInInvitationStatus candidate={candidate} />
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ margin: "24px 0" }} />

      {/* Details Tabs Section */}
      <Row>
        <Col span={24}>
          <Tabs
            defaultActiveKey="1"
            style={{ marginBottom: 24 }}
            items={[
              {
                label: "Skills & Experience",
                key: "1",
                children: (
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <Typography.Text strong style={{ marginRight: 16 }}>
                        Skills:
                      </Typography.Text>
                      <ExpandableContent
                        content={
                          <Space size={8} wrap>
                            {candidate?.key_skills?.map((skill, index) => (
                              <Tag
                                key={skill + index}
                                style={{
                                  backgroundColor: "rgba(74, 144, 226, 0.1)",
                                  borderColor: "#4a90e2",
                                  color: "#2d4a8a",
                                  borderRadius: "16px",
                                  padding: "4px 12px",
                                }}
                              >
                                {skill}
                              </Tag>
                            ))}
                          </Space>
                        }
                        maxHeight={48}
                        showMoreText="Show more skills"
                        showLessText="Show fewer skills"
                      />
                    </Col>
                    <Col span={24}>
                      <Typography.Text
                        strong
                        style={{ marginBottom: 16, display: "block" }}
                      >
                        Experience:
                      </Typography.Text>
                      <Timeline>
                        {employment?.length > 0 ? (
                          employment.map((job, index) => (
                            <Timeline.Item key={index} color="#4a90e2">
                              <Space direction="vertical" size={4}>
                                <Typography.Text
                                  strong
                                  style={{ color: "#2d4a8a" }}
                                >
                                  {job.designation} at {job.organization}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                  {job.work_since_from_year} -{" "}
                                  {job.is_current_company
                                    ? "Present"
                                    : job.work_since_to_year || "N/A"}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                  {job.location}
                                </Typography.Text>
                              </Space>
                            </Timeline.Item>
                          ))
                        ) : (
                          <Empty description="No Experience provided" />
                        )}
                      </Timeline>
                    </Col>
                  </Row>
                ),
              },
              {
                label: "Education",
                key: "2",
                children: (
                  <Timeline>
                    {qualifications?.length > 0 ? (
                      qualifications.map((qual, index) => (
                        <Timeline.Item
                          key={`qualification-${index}`}
                          color="#4a90e2"
                        >
                          <Space direction="vertical" size={4}>
                            <Typography.Text
                              strong
                              style={{ color: "#2d4a8a" }}
                            >
                              {qual.course}
                            </Typography.Text>
                            {qual.university && (
                              <Typography.Text>
                                {qual.university}
                              </Typography.Text>
                            )}
                            {qual.passing_year && (
                              <Typography.Text type="secondary">
                                ({qual.passing_year})
                              </Typography.Text>
                            )}
                          </Space>
                        </Timeline.Item>
                      ))
                    ) : (
                      <Empty description="No Education provided" />
                    )}
                  </Timeline>
                ),
              },
              {
                label: "Profile Summary",
                key: "3",
                children: candidate.profile_summary ? (
                  <Typography.Paragraph style={{ color: "#666" }}>
                    {candidate.profile_summary}
                  </Typography.Paragraph>
                ) : (
                  <Empty description="No Profile Summary to display." />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </Card>
  );
};
