import {
  Card,
  Checkbox,
  Dropdown,
  Form,
  InputNumber,
  Menu,
  Modal,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
  Input,
  Button,
  Space,
  Row,
  Col,
  Pagination,
  Radio,
  Spin,
  Popconfirm,
  Avatar,
  Badge,
  Alert,
  Empty,
} from "antd";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  SearchOutlined,
  ColumnHeightOutlined,
  EyeOutlined,
  FileTextOutlined,
  SendOutlined,
  MessageOutlined,
  TableOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkedinOutlined,
  MailOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MailTwoTone,
  DownloadOutlined,
  ReloadOutlined,
  StarFilled,
  CheckOutlined,
  DownOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  CANDIDATE_NOTES,
  CANDIDATE_NOTE_DELETE,
  CANDIDATE_NOTE_UPDATE,
  CANDIDATE_UPDATE_PARTIALLY,
  CREATE_OR_UPDATE_PREFERENCE,
  EMAIL_TEMPLATES_CREATE,
  EMAIL_TEMPLATES_UPDATE,
  IMAGE_CANDIDATE_URL,
  JOB_ASSIGNMENT_DETAILS,
  JOB_DETAIL,
  PREFERENCE_LIST,
  SEARCH_CONTACTS,
  SEND_BULK_EMAIL,
  SENT_LINKEDIN_INVITATION,
  USER_EMAIL_TEMPLATES,
} from "../../../ApiUrl";
import { requestHeader } from "../../../others/header";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../../../CSS/table.css";
import { showError } from "../utils";
import PdfViewer from "../../modules/others/pdfViewer";
import { GlobalSettings } from "../../modules/DynamicList/Settings";
import { useCallAPI } from "../../../hooks/useCallAPI";
import Notes from "../../modules/Modals/Notes";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../../modules/others/Snackbar";
import { EmailOutlined, List, ReplyOutlined } from "@material-ui/icons";
import { debounce } from "lodash";

import {
  applyFilters,
  fetchCandidates,
  setSearch,
  setSelectedCandidateIds,
  clearCandidateLabel,
  setCandidateLabel,
  setListView,
  setPage,
  setPageSize,
  setSelectedFilters,
  deleteCandidates,
  setShouldRefresh,
  setSortBy,
  setSortOrder,
  addCandidatesToList,
  createList,
  updateList,
  fetchLists,
  submitCandidates,
  setFilteredList,
  selectCandidateLabel,
  setLabelFilter,
  setLocationSearch,
} from "../../../redux/reducers/candidatesSlice";
import CandidateListManagement from "./components/CandidateListManagement";
import { CandidateCard } from "./components/CandidateCard";
import ExpandableContent from "../../../others/utils/ExpandableContent";

import CandidateSort from "./components/CandidateSort";
import JobListPagination from "./components/CandidatePagination";
import CandidateEmail from "./components/CandidateEmail";
import CandidateLinkedinInvitation from "./components/CandidateLinkedinInvitation";

const getLinkedInUrl = (meta) => {
  if (!meta) return null;

  // Find the key that matches 'linkedin' case-insensitively
  const linkedInKey = Object.keys(meta).find(
    (key) => key.toLowerCase() === "linkedin"
  );
  return linkedInKey ? meta[linkedInKey] : null;
};

export default function CandidateDynamicList2({ type = "admin" }) {
  const users = useSelector(({ users }) => users);
  const location = useLocation();
  const { record } = location.state || {};
  const rolePath =
    users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;
  const dispatch = useDispatch();
  const candidateList = useSelector((state) => state.candidates.list);
  const filteredCandidates = useSelector(
    (state) => state.candidates.filteredList
  );
  const loading = useSelector((state) => state.candidates.loading);
  const error = useSelector((state) => state.candidates.error);
  const page = useSelector((state) => state.candidates.page);
  const totalData = useSelector((state) => state.candidates.totalData);
  const currentPage = useSelector((state) => state.candidates.currentPage);
  const reduxPageSize = useSelector((state) => state.candidates.pageSize);
  const search = useSelector((state) => state.candidates.search);
  const selectedCandidateIds = useSelector(
    (state) => state.candidates.selectedCandidateIds
  );
  const listView = useSelector((state) => state.candidates.listView);
  const bestCandidates = useSelector(
    (state) => state.candidates.bestCandidates
  );
  const goodCandidates = useSelector(
    (state) => state.candidates.goodCandidates
  );
  const qualifiedCandidates = useSelector(
    (state) => state.candidates.qualifiedCandidates
  );
  const selectedFilters = useSelector(
    (state) => state.candidates.selectedFilters
  );
  const deleteStatus = useSelector((state) => state.candidates.deleteStatus);
  const deleteError = useSelector((state) => state.candidates.deleteError);
  const shouldRefresh = useSelector((state) => state.candidates.shouldRefresh);
  const sortBy = useSelector((state) => state.candidates.sortBy);
  const sortOrder = useSelector((state) => state.candidates.sortOrder);
  const lists = useSelector((state) => state.candidates.lists);
  const currentListId = useSelector((state) => state.candidates.currentListId);
  const submitting = useSelector((state) => state.candidates.submitting);
  const labelFilter = useSelector((state) => state.candidates.labelFilter);
  const locationSearch = useSelector(
    (state) => state.candidates.locationSearch
  );
  const [localPageSize, setLocalPageSize] = useState(reduxPageSize || 10);

  useEffect(() => {
    if (reduxPageSize && reduxPageSize !== localPageSize) {
      setLocalPageSize(reduxPageSize);
    }
  }, [reduxPageSize]);
  const [showCV, setShowCv] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [cvPath, setCVPath] = useState("");
  const [emailData, setEmailData] = useState({
    to: [],
    subject: "",
    body: "",
    candidateId: "",
  });
  const [openDltModal, setOpenDltModal] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEmailHistoryModalVisible, setIsEmailHistoryModalVisible] = useState(
    false
  );
  const [selectedEmailHistoryRecord, setSelectedEmailHistoryRecord] = useState(
    null
  );

  const handleChatClick = (emailHistory) => {
    setSelectedEmailHistoryRecord(emailHistory);
    setIsEmailHistoryModalVisible(true);
  };

  const handleModalClose = () => {
    setIsEmailHistoryModalVisible(false);
    setSelectedEmailHistoryRecord(null);
  };
  const [listName, setListName] = useState("");
  const [editingListId, setEditingListId] = useState(null);
  const candidateLabels = useMemo(() => {
    const labels = {};
    for (const candidate of filteredCandidates) {
      const id = candidate.candidate._id;
      if (bestCandidates.includes(id)) {
        labels[id] = "top";
      } else if (goodCandidates.includes(id)) {
        labels[id] = "good";
      } else if (qualifiedCandidates.includes(id)) {
        labels[id] = "qualified";
      } else {
        labels[id] = "";
      }
    }
    return labels;
  }, [filteredCandidates, bestCandidates, goodCandidates, qualifiedCandidates]);

  const handleLabelChange = useCallback(
    (candidateId, label) => {
      dispatch(
        clearCandidateLabel({ candidateId, labelType: "bestCandidates" })
      );
      dispatch(
        clearCandidateLabel({ candidateId, labelType: "goodCandidates" })
      );
      dispatch(
        clearCandidateLabel({ candidateId, labelType: "qualifiedCandidates" })
      );

      if (label === "top") {
        dispatch(
          setCandidateLabel({ candidateId, labelType: "bestCandidates" })
        );
      } else if (label === "good") {
        dispatch(
          setCandidateLabel({ candidateId, labelType: "goodCandidates" })
        );
      } else if (label === "qualified") {
        dispatch(
          setCandidateLabel({ candidateId, labelType: "qualifiedCandidates" })
        );
      }

      // Force a re-render of the table by updating the filteredList
      dispatch(setFilteredList([...filteredCandidates]));
    },
    [dispatch, filteredCandidates]
  );
  const StatusTag = ({ status }) => {
    const colors = {
      top: "#f5b014",
      good: "#52c41a",
      qualified: "#1890ff",
    };

    const icons = {
      top: <StarFilled />,
      good: <CheckCircleOutlined />,
      qualified: <CheckOutlined />,
    };

    return status ? (
      <Tag
        color={colors[status]}
        icon={icons[status]}
        className="rounded-full m-0"
      >
        {status.toUpperCase()}
      </Tag>
    ) : null;
  };
  const allColumns = useMemo(
    () => [
      {
        title: "Display Name",
        key: "displayName",
        mandatory: true,
        dataIndex: ["candidate", "first_name", "_id"],
        width: 200,
        minWidth: 150,
        maxWidth: 250,
        render: (text, records) => {
          const candidateLabel = candidateLabels[records.candidate._id];

          const labelMenu = (
            <Menu
              onClick={({ key }) =>
                handleLabelChange(records.candidate._id, key)
              }
            >
              <Menu.Item key="top">Best</Menu.Item>
              <Menu.Item key="good">Good</Menu.Item>
              <Menu.Item key="qualified">Qualified</Menu.Item>
            </Menu>
          );
          return (
            <div
              className="d-flex align-items-center"
              style={{
                gap: "10px",
              }}
            >
              <Button
                onClick={() => {
                  getNotes(records);
                }}
                style={{ minWidth: "40px", maxWidth: "60px" }}
                icon={<FileTextOutlined />}
              />

              <Tooltip
                color="#1a1a27"
                style={{
                  maxWidth: "auto",
                }}
                title={
                  <Space>
                    <Tooltip title="View Details">
                      <NavLink
                        to={{
                          pathname: `/${type}/candidateProfileDetail`,
                          state: {
                            item: {
                              ...records.candidate,
                              opening_id: records.opening_id,
                            },
                          },
                        }}
                      >
                        <Button
                          type="primary"
                          icon={<EyeOutlined />}
                          className="action-btn view-btn"
                        ></Button>
                      </NavLink>
                    </Tooltip>
                    <Tooltip title="Edit Candidate">
                      <NavLink
                        to={{
                          pathname: `/${rolePath}/edit-candidate`,
                          state: {
                            item: {
                              ...records.candidate,
                              opening_id: records.opening_id,
                            },
                          },
                        }}
                      >
                        <Button
                          type="default"
                          icon={<EditOutlined />}
                          className="action-btn edit-btn"
                        />
                      </NavLink>
                    </Tooltip>
                    <Popconfirm
                      title="Delete this candidate?"
                      onConfirm={() => openDeleteModal(records.candidate._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Delete Candidate">
                        <Button
                          type="danger"
                          icon={<DeleteOutlined />}
                          className="action-btn delete-btn"
                        />
                      </Tooltip>
                    </Popconfirm>
                    <Dropdown overlay={labelMenu}>
                      <Button className="text-capitalize">
                        {candidateLabel
                          ? candidateLabel == "top"
                            ? "Best"
                            : candidateLabel
                          : "Label"}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                }
              >
                <Space style={{ width: "max-content" }}>
                  <Space size="middle">
                    <NavLink
                      to={{
                        pathname: `/${type}/candidateProfileDetail`,
                        state: {
                          item: {
                            ...records.candidate,
                            opening_id: record.opening_id,
                          },
                        },
                      }}
                    >
                      <Typography.Text
                        style={{
                          color: "#1890ff",
                        }}
                      >
                        {records.candidate.first_name || "N/A"}{" "}
                        {records.candidate.last_name || "N/A"}
                      </Typography.Text>
                    </NavLink>
                  </Space>

                  {records?.candidate?.attachments ? (
                    <Button
                      onClick={() => setShowCv(true)}
                      icon={<FileTextOutlined />}
                    />
                  ) : null}

                  {setCVPath(
                    `${process.env.REACT_APP_BASE_URL}/upload/candidate/${records.candidate.attachments}`
                  )}
                </Space>
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: "Email",
        key: "email",
        mandatory: true,
        dataIndex: ["candidate", "email"],
        sorter: true,
        render: (text, records) => {
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div>
                {records?.candidate?.email
                  ? records.candidate.email.split(",").map((email, index) => (
                      <Typography.Text key={index} copyable>
                        {email.trim()}
                      </Typography.Text>
                    ))
                  : "N/A"}
              </div>
              {isValidEmails(records.candidate.email) && (
                <Button
                  onClick={() => {
                    onClickSendEmail(records);
                  }}
                  icon={<SendOutlined />}
                  type="primary"
                />
              )}
            </div>
          );
        },
      },
      {
        title: "Email Status",
        key: "emailStatus",
        mandatory: true,
        dataIndex: ["candidate", "email"],
        render: (text, records) => {
          const hasSent = records?.emailHistory?.some((email) => email.is_sent);
          const hasOpen = records?.emailHistory?.some((email) => email.is_open);
          const hasReplied = records?.emailHistory?.some(
            (email) => email.is_replied
          );
          const hasBounced = records?.emailHistory?.some(
            (email) => email.is_bounced
          );

          return (
            <span>
              <span style={{ marginRight: "10px" }}>
                {hasSent ? (
                  <Typography.Text>
                    Send <CheckCircleOutlined style={{ color: "green" }} />
                  </Typography.Text>
                ) : (
                  <Typography.Text>
                    Send <CloseCircleOutlined style={{ color: "red" }} />
                  </Typography.Text>
                )}
              </span>
              <span style={{ marginRight: "10px" }}>
                {hasOpen ? (
                  <Typography.Text>
                    Opened <MailTwoTone twoToneColor="#52c41a" />
                  </Typography.Text>
                ) : null}
              </span>
              <span style={{ marginRight: "10px" }}>
                {hasReplied ? (
                  <Typography.Text>
                    Replied <ReplyOutlined style={{ color: "#52c41a" }} />
                  </Typography.Text>
                ) : null}
              </span>
              <span style={{ marginRight: "10px" }}>
                {hasBounced ? (
                  <Typography.Text>
                    Bounced <CheckCircleOutlined style={{ color: "#52c41a" }} />
                  </Typography.Text>
                ) : null}
              </span>
              {hasReplied && (
                <Button
                  onClick={() => {
                    handleChatClick(records.emailHistory);
                  }}
                  icon={<MessageOutlined />}
                />
              )}
            </span>
          );
        },
      },
      {
        title: "Mobile",
        key: "mobile",
        mandatory: true,
        dataIndex: ["candidate", "mobile"],
        sorter: true,
        render: (text, records) => (
          <>
            {records.candidate?.mobile
              ? records.candidate.mobile.split(",").map((mobile, index) => (
                  <Typography.Text key={index} copyable>
                    {mobile}
                  </Typography.Text>
                ))
              : "N/A"}
          </>
        ),
      },
      {
        title: "Experience (yrs)",
        key: "experience",
        mandatory: false,
        dataIndex: ["candidate", "total_work_exp_year"],
        render: (text, records) => (
          <span>
            {records.candidate.total_work_exp_year || "0"} years{" "}
            {records.candidate.total_work_exp_month || "0"} months
          </span>
        ),
      },
      {
        title: "Profile Summary",
        key: "profileSummary",
        mandatory: false,
        dataIndex: ["candidate", "profile_summary"],
        render: (text, records) => (
          <ExpandableContent
            content={records.candidate.profile_summary || "N/A"}
            maxHeight={48}
            showLessText="Read Less"
            showMoreText="Read More"
          />
        ),
      },
      {
        title: "Gender",
        key: "gender",
        mandatory: false,
        dataIndex: ["candidate", "gender"],
        render: (text) => text || "N/A",
      },
      {
        title: "Home Town",
        key: "homeTown",
        mandatory: false,
        dataIndex: ["candidate", "home_town"],
        render: (text) => text || "N/A",
      },
      {
        title: "Permanent Address",
        key: "permanentAddress",
        mandatory: false,
        dataIndex: ["candidate", "permanent_address"],
        render: (text) => text || "N/A",
      },
      {
        title: "Desired Job",
        key: "desiredJob",
        mandatory: false,
        dataIndex: ["candidate", "desired_job_type"],
        render: (text) => text || "N/A",
      },
      {
        title: "Location",
        key: "location",
        mandatory: false,
        sorter: true,
        dataIndex: ["candidate", "current_location"],
        render: (text) => text || "N/A",
      },
      {
        title: "Job Category",
        key: "jobCategory",
        mandatory: true,
        dataIndex: ["candidate", "job_category"],
        render: (text) => text || "N/A",
      },
      {
        title: "CTC",
        key: "ctc",
        mandatory: false,
        dataIndex: ["candidate", "current_ctc"],
        render: (text) => text || "N/A",
      },
      {
        title: "LinkedIn Invitation",
        key: "linkedInInvitation",
        mandatory: false,
        dataIndex: ["candidate", "linkedIn_invitation_status"],
        render: (text, records) => {
          const currentUserId = users.user._id;
          const invitationStatusArray =
            records.candidate.linkedIn_invitation_status || [];
          const invitationStatus = invitationStatusArray.find(
            (status) => status.sent_by === currentUserId
          );
          return invitationStatus
            ? invitationStatus.status
            : "No Invitation Sent";
        },
      },
      {
        title: "Tags",
        key: "tags",
        mandatory: true,
        dataIndex: ["candidate", "tags"],
        render: (text, records) => (
          <span>
            {records.candidate.tags
              ? records.candidate.tags.map((tag) => (
                  <Tag key={tag} color="blue">
                    {tag}
                  </Tag>
                ))
              : "N/A"}
          </span>
        ),
      },
      {
        title: "Date of Birth",
        mandatory: false,
        dataIndex: ["candidate", "date_of_birth"],
        render: (text) => text || "N/A",
      },
      {
        title: "LinkedIn",
        key: "linkedin",
        mandatory: false,
        dataIndex: ["candidate", "meta"],
        sorter: true,
        render: (meta, records) => (
          <span>
            {getLinkedInUrl(records?.candidate?.meta) ? (
              <a
                href={getLinkedInUrl(records?.candidate?.meta)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="primary" icon={<EyeOutlined />} />
              </a>
            ) : (
              "N/A"
            )}
          </span>
        ),
      },
      {
        title: "Resume",
        key: "attachments",
        mandatory: false,
        dataIndex: ["candidate", "attachments"],
        sorter: true,
        render: (text, records) => (
          <Button
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.REACT_APP_BASE_URL}/upload/candidate/${records.candidate.attachments}`;
              link.setAttribute("download", "resume.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            icon={<DownloadOutlined />}
            disabled={!records.candidate.attachments}
            type="primary"
            className="mr-5"
          >
            Download
          </Button>
        ),
      },
      {
        title: "Action",
        dataIndex: ["candidate", "_id", "action"],
        mandatory: true,
        key: "action",
        width: 250,
        minWidth: 200,
        maxWidth: 300,
        render: (_, record) => {
          const candidateLabel = candidateLabels[record.candidate._id];

          const labelMenu = (
            <Menu
              onClick={({ key }) =>
                handleLabelChange(record.candidate._id, key)
              }
            >
              <Menu.Item key="top">Best</Menu.Item>
              <Menu.Item key="good">Good</Menu.Item>
              <Menu.Item key="qualified">Qualified</Menu.Item>
            </Menu>
          );

          return (
            <>
              <Space>
                <Tooltip title="View Details">
                  <NavLink
                    to={{
                      pathname: `/${type}/candidateProfileDetail`,
                      state: {
                        item: {
                          ...record.candidate,
                          opening_id: record.opening_id,
                        },
                      },
                    }}
                  >
                    <Button
                      type="primary"
                      icon={<EyeOutlined />}
                      className="action-btn view-btn"
                    ></Button>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Edit Opening">
                  <NavLink
                    to={{
                      pathname: `/${rolePath}/edit-candidate`,
                      state: {
                        item: {
                          ...record.candidate,
                          opening_id: record.opening_id,
                        },
                      },
                    }}
                  >
                    <Button
                      type="default"
                      icon={<EditOutlined />}
                      className="action-btn edit-btn"
                    />
                  </NavLink>
                </Tooltip>
                <Popconfirm
                  title="Delete this job opening?"
                  onConfirm={() => openDeleteModal(record.candidate._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Delete Opening">
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      className="action-btn delete-btn"
                    />
                  </Tooltip>
                </Popconfirm>
                <Dropdown overlay={labelMenu}>
                  <Button className="text-capitalize">
                    {candidateLabel
                      ? candidateLabel == "top"
                        ? "Best"
                        : candidateLabel
                      : "Label"}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </Space>
            </>
          );
        },
      },
    ],
    [type, record, selectCandidateLabel, handleLabelChange, users.role]
  );

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility = {};
    allColumns.forEach((col) => {
      initialVisibility[col.key] = col.mandatory || false;
    });
    return initialVisibility;
  });
  const visibleColumns = useMemo(() => {
    return allColumns.filter(
      (column) => column.mandatory || columnVisibility[column.key]
    );
  }, [allColumns, columnVisibility]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isListModalVisible, setIsListModalVisible] = useState(false);
  const [showLinkedInInvitaion, setShowLinkedInInvitaion] = useState(false);

  const [selectionType, setSelectionType] = useState("checkbox");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showNotes, setShowNotes] = useState(false);

  const NotesData = useCallAPI(CANDIDATE_NOTES);
  const UpdateCandidate = useCallAPI(CANDIDATE_UPDATE_PARTIALLY);
  const UpdateNotes = useCallAPI(CANDIDATE_NOTE_UPDATE);
  const DeleteNotes = useCallAPI(CANDIDATE_NOTE_DELETE);
  const SendBulkEmail = useCallAPI(SEND_BULK_EMAIL);
  const SearchContact = useCallAPI(SEARCH_CONTACTS);
  const [companyId, setCompanyId] = useState("");
  const [bdmId, setBdmId] = useState("");
  const [globalLoading, setGlobalLoading] = useState({
    linkedInInvitation: false,
  });
  const [globalSuccess, setGlobalSuccess] = useState({
    status: false,
    message: null,
  });
  const [globalError, setGlobalError] = useState({
    status: false,
    message: null,
  });

  const searchContact = () => {
    const params = selectedRows?.map((row) => ({
      candidateId: row.candidate._id,
      firstName: row.candidate.first_name,
      middleName: row.candidate.middle_name,
      lastName: row.candidate.last_name,
      address: row.candidate.current_location,
    }));
    SearchContact.post({ search: params });
    clearSelections();
  };

  const onClickSendEmail = (records) => {
    let emails = [];

    if (records?.candidate) {
      emails = records.candidate.email.split(",").map((email) => email.trim());
      setSelectedRows([records]); // Add this line to ensure selectedRows is set for single selection
    } else if (selectedRows && selectedRows.length > 0) {
      emails = selectedRows.flatMap((row) =>
        row.candidate.email.split(",").map((email) => email.trim())
      );
    }

    setEmailData({ ...emailData, to: emails });
    setShowEmailForm(true);
  };

  const getNotes = (record) => {
    if (record?.candidate?._id) {
      NotesData.get(record?.candidate?._id);
      setSelectedRow(record?.candidate);
      setShowNotes(true);
    }
  };

  useEffect(() => {
    if (record) {
      getOpeningDetail();
    }
  }, [record]);

  const getListPreference = () => {
    axios
      .get(PREFERENCE_LIST, requestHeader())
      .then((response) => {
        const updatedColumns = columns?.map((column) => {
          const preference = response.data.data.candidate_preference.find(
            (pref) =>
              JSON.stringify(pref.title) === JSON.stringify(column.title)
          );
          if (preference) {
            return {
              ...column,
              show: column.mandatory ? true : preference.show,
            };
          }
          return column;
        });

        setColumns(updatedColumns);
      })
      .catch((error) => {});
  };
  const handleListPreference = () => {
    setIsModalVisible(false);
    const updatedPreference = allColumns?.map((col) => ({
      title: col.title,
      show: col.mandatory ? true : columnVisibility[col.key],
      dataIndex: col.dataIndex,
    }));

    axios
      .post(
        CREATE_OR_UPDATE_PREFERENCE,
        {
          candidate_preference: updatedPreference,
        },
        requestHeader()
      )
      .then((response) => {})
      .catch((error) => {});
  };
  const getOpeningDetail = () => {
    if (record) {
      axios
        .get(JOB_DETAIL + "/" + record._id, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          setCompanyId(res.data.data[0].account_name[0]._id);
          getJobAssignmentDetails(res.data.data[0].opening_id);
        })
        .catch((error) => {
          if (error?.response?.status == 401) {
            logoutHandler(history);
          }
        });
    }
  };
  const getJobAssignmentDetails = (id) => {
    axios
      .post(
        JOB_ASSIGNMENT_DETAILS,
        {
          recruiter_id: users.user._id,
          opening_id: id,
        },
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => {
        setBdmId(res.data.data[0].created_by);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          logoutHandler(history);
        }
      });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSwitchChange = (key, show) => {
    setColumnVisibility((prev) => {
      const newState = { ...prev, [key]: show };
      return newState;
    });
  };
  useEffect(() => {
    const initialVisibility = { ...columnVisibility };
    allColumns.forEach((col) => {
      initialVisibility[col.key] = col.mandatory || initialVisibility[col.key];
    });
    setColumnVisibility(initialVisibility);
  }, [allColumns]);

  const handleDeleteNotes = (id) => {
    DeleteNotes.deletee(`${selectedRow._id}/${id}`);
  };

  useEffect(() => {
    getListPreference();
  }, []);

  function isValidEmails(emails) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (typeof emails === "string") {
      return emails.split(",").every((email) => emailRegex.test(email.trim()));
    }
    return false;
  }

  const [form] = Form.useForm();

  const prevSearch = useRef(search);
  const prevLocationSearch = useRef(locationSearch); // Add this line

  const handleSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearch(value));

    // Reset to first page when searching
    dispatch(setPage(1));

    // Fetch candidates with new search term
    dispatch(
      fetchCandidates({
        jobId: record.opening_id,
        page: 1,
        pageSize: localPageSize,
        search: value,
        sortBy,
        sortOrder,
        shortlistId: currentListId,
      })
    );
  };

  const handleDeleteCandidate = async () => {
    setIsDeleting(true);
    try {
      await dispatch(
        deleteCandidates({
          candidateIds: idsToDelete,
          jobId: record.opening_id,
        })
      ).unwrap();

      message.success("Candidate(s) deleted successfully");
      setOpenDltModal(false);
      setIsDeleting(false);
      setIdsToDelete([]);
    } catch (error) {
      console.error("Error deleting candidate:", error);
      message.error(error.message || "Failed to delete candidate(s)");
    } finally {
      setIsDeleting(false);
    }
  };
  const handleRefresh = () => {
    dispatch(setSortBy(null));
    dispatch(setSortOrder("asc"));
    dispatch(
      fetchCandidates({
        jobId: record.opening_id,
        page,
        pageSize: localPageSize,
        search,
        sortBy: "",
        sortOrder: "",
        shortlistId: currentListId,
        labelFilter,
      })
    )
      .unwrap()
      .then(() => {
        message.success("Candidate list refreshed successfully");
      })
      .catch((error) => {
        message.error("Failed to refresh candidate list: " + error.message);
      });
  };

  const handleDeleteMultipleCandidates = () => {
    openDeleteModal(selectedCandidateIds);
  };

  const openDeleteModal = (candidateIds) => {
    setOpenDltModal(true);
    setIdsToDelete(Array.isArray(candidateIds) ? candidateIds : [candidateIds]);
  };

  const handleCancelDeleteModal = () => {
    setOpenDltModal(false);
  };

  const handleCheckboxChange = useCallback(
    (candidateId) => {
      const newSelectedIds = selectedCandidateIds.includes(candidateId)
        ? selectedCandidateIds.filter((id) => id !== candidateId)
        : [...selectedCandidateIds, candidateId];

      dispatch(setSelectedCandidateIds(newSelectedIds));
    },
    [selectedCandidateIds, dispatch]
  );

  const handleSelectAll = () => {
    const allCandidateIds = filteredCandidates?.map(
      (candidate) => candidate.candidate._id
    );
    const newSelectedIds =
      selectedCandidateIds.length === allCandidateIds.length
        ? []
        : allCandidateIds;
    dispatch(setSelectedCandidateIds(newSelectedIds));

    const newSelectedRows =
      newSelectedIds.length === 0 ? [] : filteredCandidates;
    setSelectedRows(newSelectedRows);
  };

  const handleChangeLabel = useCallback(
    (selected) => {
      dispatch(setSelectedFilters(selected));
      dispatch(applyFilters());
    },
    [dispatch]
  );

  const handleView = useCallback(() => {
    dispatch(setListView(!listView));
  }, [dispatch, listView]);
  const sendInvitation = async (values) => {
    try {
      setGlobalLoading({ ...globalLoading, linkedInInvitation: true });

      // Extract all selected candidates that have LinkedIn profiles
      const candidatesWithLinkedIn = selectedRows.filter(
        (row) =>
          getLinkedInUrl(row.candidate?.meta) &&
          values.displayName.includes(row.candidate.first_name)
      );

      if (candidatesWithLinkedIn.length === 0) {
     
        setGlobalError({
          status: true,
          message: "No selected candidates have LinkedIn profiles",
        });
        return { success: false, message: "No selected candidates have LinkedIn profiles" };
      }

      // Prepare data for all selected candidates
      const data = candidatesWithLinkedIn.map((row) => {
        let msg = values.message;
        // Replace template variables for each candidate
        msg = msg.replace(/{{name}}/g, row.candidate?.first_name || "");
        msg = msg.replace(/{{email}}/g, row.candidate?.email || "");
        msg = msg.replace(/{{phone}}/g, row.candidate?.mobile || "");

        return {
          linkedin_link: getLinkedInUrl(row.candidate?.meta),
          message: msg,
          candidate_id: row.candidate._id,
        };
      });

      const response = await axios.post(
        SENT_LINKEDIN_INVITATION,
        data,
        requestHeader()
      );

      if (response.data.success) {
        // Handle success case
        setGlobalSuccess({
          status: true,
          message: `LinkedIn Invitation sent successfully to ${data.length} candidate(s)!`,
        });
        setShowLinkedInInvitaion(false);
        form.resetFields();
        return { success: true, message: `LinkedIn Invitation sent successfully to ${data.length} candidate(s)!` };
      } else {
     
        setGlobalError({
          status: true,
          message: response.data.message || "Failed to send invitations",
        });
        return { success: false, message: response.data.message || "Failed to send invitations" };
      }
    } catch (error) {
   
      console.error("Send invitation error:", error);
      setGlobalError({
        status: true,
        message: error.message || "Failed to send LinkedIn invitations!",
      });
      return { success: false, message: error.message || "Failed to send LinkedIn invitations!" };
    } finally {
      setGlobalLoading({ ...globalLoading, linkedInInvitation: false });

      setTimeout(() => {
        setGlobalSuccess({ status: false, message: null });
        setGlobalError({ status: false, message: null });
      }, 3000);
    }
  };

  const sendEmail = (emailBody) => {
    // console.log('emailBody:', emailBody);
    const data = [];

    // Check if selectedRows exists and has items
    if (!selectedRows || selectedRows.length === 0) {
      message.error("No candidates selected");
      return;
    }

    // Loop through email recipients
    for (let email of emailBody.to) {
      // Find the corresponding candidate from selectedRows
      const row = selectedRows.find((row) =>
        row.candidate.email
          .split(",")
          .map((e) => e.trim())
          .includes(email)
      );

      // console.log('Found candidate row:', row);

      if (row) {
        let msg = emailBody.message;
        let subject = emailBody.subject;
        // Replace template variables
        // msg = msg.replace(/{{name}}/g, row.candidate.first_name || '');
        // msg = msg.replace(/{{email}}/g, email || '');
        // msg = msg.replace(/{{phone}}/g, row.candidate.mobile || '');
        // subject = subject.replace(/{{name}}/g, row.candidate.first_name || '');

        const replaceVariables = (text, isSubject) => {
          const variables = isSubject
            ? {
                name: row.candidate.first_name || "",
                job_title: record.opening_title || "",
              }
            : {
                name: row.candidate.first_name || "",
                email: email || "",
                phone: row.candidate.mobile || "",
                job_title: record.opening_title || "",
                location:
                  `${record.city}, ${record.state}, ${record.country}` || "",
                short_description: record.short_description || "",
              };

          return text.replace(
            /{{(\w+)}}/g,
            (match, p1) => variables[p1] || match
          );
        };

        msg = replaceVariables(msg, false);
        subject = replaceVariables(subject, true);

        data.push({
          to: email,
          message: msg,
          subject: subject,
          candidateId: row.candidate._id,
        });
      }
    }

    // console.log('Prepared email data:', data);

    if (data.length === 0) {
      message.error("No valid candidates found for sending emails");
      return;
    }

    // Send the email
    try {
      SendBulkEmail.post({
        email: data,
        pauseSecond: emailBody.pause || 2,
        jobId: record?.opening_id,
        fromEmail: emailBody.fromEmail,
      })
        .then((response) => {
          if (response) {
            clearSelections();
            message.success("Emails sent successfully");
          }
        })
        .catch((error) => {
          message.error("Failed to send emails: " + error.message);
        });
    } catch (error) {
      console.error("Email sending error:", error);
      message.error("Failed to send emails");
    } finally {
      handleCloseEmailModal();
    }
  };

  const handleCloseEmailModal = useCallback(() => {
    setShowEmailForm(false);
    setEmailTemplates([]); // Clear templates
    form.resetFields();
    setEmailData({
      to: [],
      subject: "",
      body: "",
      candidateId: "",
    });
  }, [form]);

  const onFinishFailed = (errorInfo) => {};

  const handleLinkedInInviationModal = () => {
    if (selectedRows.length > 0) {
      setShowLinkedInInvitaion(true);
      form.setFieldsValue({
        displayName: selectedRows?.map((row) => row.candidate.first_name),
      });
    } else {
      message.warning(
        "Please select at least one candidate for LinkedIn invitation."
      );
    }
  };

  const textAreaRef = useRef(null);
  const [textValue, setTextValue] = useState("");

  const insertVariable = (value) => {
    form.setFieldsValue({
      message: form.getFieldValue("message") + ` {{${value}}}`,
    });
  };

  const maxChars = 200;

  const handleNotes = (value) => {
    UpdateCandidate.patch({ multipleNotes: value }, selectedRow._id);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      dispatch(setSortBy(sorter.field[1]));
      dispatch(setSortOrder(sorter.order === "ascend" ? "asc" : "desc"));
    } else {
      dispatch(setSortBy(null));
      dispatch(setSortOrder("asc"));
    }

    dispatch(setPage(pagination.current));
    dispatch(setPageSize(pagination.pageSize));
  };

  const LinkedInInvitationStatus = ({ candidate }) => {
    const currentUserId = users.user._id;
    const invitationStatusArray = candidate.linkedIn_invitation_status || [];
    const invitationStatus = invitationStatusArray.find(
      (status) => status.sent_by === currentUserId
    );

    const getStatusColor = (status) => {
      switch (status) {
        case "Sent":
          return "blue";
        case "Accepted":
          return "green";
        case "Rejected":
          return "red";
        default:
          return "default";
      }
    };
    return (
      <Space align="center">
        <LinkedinOutlined style={{ fontSize: "16px", color: "#0077B5" }} />
        <Typography.Text strong>LinkedIn Invitation:</Typography.Text>
        {invitationStatus ? (
          <Tag color={getStatusColor(invitationStatus.status)}>
            {invitationStatus.status}
          </Tag>
        ) : (
          <Tag>No Invitation Sent</Tag>
        )}
      </Space>
    );
  };
  const EmailStatus = ({ emailHistory }) => {
    const hasSent = emailHistory.some((email) => email.is_sent);
    // Check if emailHistory has any object with is_open as true
    const hasOpen = emailHistory.some((email) => email.is_open);
    // Check if emailHistory has any object with is_replied as true
    const hasReplied = emailHistory.some((email) => email.is_replied);
    // Check if emailHistory has any object with is_bounced as true
    const hasBounced = emailHistory.some((email) => email.is_bounced);
    return (
      <Space align="center">
        <MailOutlined style={{ fontSize: "16px", color: "#0077B5" }} />
        <Typography.Text strong>Email Status:</Typography.Text>
        <span style={{ marginRight: "10px" }}>
          {hasSent ? (
            <Typography.Text>
              Send <CheckCircleOutlined style={{ color: "green" }} />
            </Typography.Text>
          ) : (
            <Typography.Text>
              Send <CloseCircleOutlined style={{ color: "red" }} />
            </Typography.Text>
          )}
        </span>
        <span style={{ marginRight: "10px" }}>
          {hasOpen ? (
            <Typography.Text>
              Opened <MailTwoTone twoToneColor="#52c41a" />
            </Typography.Text>
          ) : null}
        </span>
        <span style={{ marginRight: "10px" }}>
          {hasReplied ? (
            <Typography.Text>
              Replied <ReplyOutlined style={{ color: "#52c41a" }} />
            </Typography.Text>
          ) : null}
        </span>
        <span style={{ marginRight: "10px" }}>
          {hasBounced ? (
            <Typography.Text>
              Bounced <CheckCircleOutlined style={{ color: "#52c41a" }} />
            </Typography.Text>
          ) : null}
        </span>
        {hasReplied && (
          <Button
            onClick={() => {
              handleChatClick(emailHistory);
            }}
            icon={<MessageOutlined />}
          />
        )}
      </Space>
    );
  };

  const handleListsCancel = () => {
    setIsListModalVisible(false);
    setListName("");
    setEditingListId(null);
  };

  const handleCreateOrUpdateList = () => {
    // console.log("clicked handleCreateOrUpdateList");
    if (editingListId) {
      // console.log("clicked update list");
      dispatch(updateList({ listId: editingListId, newName: listName }));
    } else {
      // console.log("clicked create list");

      dispatch(
        createList({
          listName: listName,
          user: users.user,
          jobId: record.opening_id,
        })
      );
    }
    handleListsCancel();
  };

  const [submitModalVisible, setSubmitModalVisible] = useState(false);

  const handleSubmitCandidates = () => {
    if (selectedCandidateIds.length === 0) {
      message.warning("Please select candidates to submit");
      return;
    }
    setSubmitModalVisible(true);
  };

  const handleConfirmSubmit = async () => {
    try {
      const recruiterId = users.role === "recruiter" ? users.user._id : null;
      const result = await dispatch(
        submitCandidates({
          candidateIds: selectedCandidateIds,
          jobId: record.opening_id,
          userRole: users?.role,
          bdmId,
          recruiterId,
          companyId: companyId,
          isAutoSourced: true,
        })
      ).unwrap();

      if (result.success) {
        message.success(
          `${selectedCandidateIds.length} candidate(s) submitted successfully`
        );
        dispatch(setSelectedCandidateIds([]));
        dispatch(setShouldRefresh(true));
        setSubmitModalVisible(false);
      } else {
        throw new Error(result.message || "Failed to submit candidates");
      }
    } catch (error) {
      console.error("Submit error:", error);
      message.error(
        error.message || "Failed to submit candidates. Please try again."
      );
    }
  };

  const handleAddToList = (listId) => {
    if (selectedCandidateIds.length > 0) {
      dispatch(
        addCandidatesToList({ listId, candidateIds: selectedCandidateIds })
      ).then(() => {
        dispatch(setFilteredList());
        message.success(
          `Added ${selectedCandidateIds.length} candidate(s) to the list.`
        );
      });
    } else {
      message.warning("Please select candidates to add to the list");
    }
  };
  // useEffect(() => {
  // 	getListPreference();
  // }, []);
  // useEffect(() => {
  // 	if (record) {
  // 		dispatch(
  // 			fetchCandidates({
  // 				jobId: record.opening_id,
  // 				page,
  // 				pageSize,
  // 				search,
  // 				sortBy,
  // 				sortOrder,
  // 				shortlistId: currentListId || null,
  // 			})
  // 		);
  // 	}
  // }, [
  // 	dispatch,
  // 	record,
  // 	page,
  // 	pageSize,
  // 	search,
  // 	sortBy,
  // 	sortOrder,
  // 	currentListId,
  // 	submitCandidates,
  // 	submitting

  // ]);

  // useEffect(() => {
  // 	dispatch(
  // 		fetchLists({ createdBy: users.user._id, jobId: record.opening_id })
  // 	);
  // }, [dispatch]);
  useEffect(() => {
    prevSearch.current = search;
  }, [search]);
  useEffect(() => {
    dispatch(applyFilters());
  }, [dispatch, selectedFilters, currentListId]);
  useEffect(() => {
    if (deleteStatus === "failed" && deleteError) {
      message.error(deleteError.message || "Failed to delete candidate(s)");
    }
  }, [deleteStatus, deleteError]);
  useEffect(() => {
    form.setFieldsValue(emailData);
  }, [emailData, form]);
  const handleUpdateNotes = (value, id) => {
    UpdateNotes.patch(value, `${selectedRow._id}/${id}`);
  };
  useEffect(() => {
    const newSelectedRows = filteredCandidates.filter((candidate) =>
      selectedCandidateIds.includes(candidate.candidate._id)
    );
    setSelectedRows(newSelectedRows);
  }, [selectedCandidateIds, filteredCandidates]);
  useEffect(() => {
    form.setFieldsValue({
      displayName: selectedRows?.map((row) => row.candidate.first_name),
    });
  }, [selectedRows]);
  const debouncedFetch = useCallback(
    debounce((params) => {
      dispatch(fetchCandidates(params));
    }, 300),
    []
  );

  useEffect(() => {
    if (record) {
      debouncedFetch({
        jobId: record.opening_id,
        page,
        pageSize: localPageSize,
        search,
        sortBy: sortBy || "",
        sortOrder: sortOrder || "asc",
        shortlistId: currentListId || "",
        locationSearch,
        labelFilter,
      });
    }
  }, [
    record?.opening_id,
    page,
    localPageSize,
    search,
    locationSearch,
    labelFilter,
  ]);
  useEffect(() => {
    if (
      record &&
      (candidateList.length === 0 ||
        search !== prevSearch.current ||
        shouldRefresh ||
        labelFilter !== prevLabelFilter.current)
    ) {
      // Don't fetch if we just got empty results with the same filter
      if (
        !(
          filteredCandidates.length === 0 &&
          labelFilter === prevLabelFilter.current
        )
      ) {
        dispatch(
          fetchCandidates({
            jobId: record.opening_id,
            page,
            pageSize: localPageSize,
            search,
            sortBy,
            sortOrder,
            shortlistId: currentListId,
            labelFilter,
          })
        );
      }

      if (shouldRefresh) {
        dispatch(setShouldRefresh(false));
      }

      // Update refs after state changes
      prevSearch.current = search;
      prevLabelFilter.current = labelFilter;
    }
  }, [
    dispatch,
    record,
    page,
    localPageSize,
    search,
    sortBy,
    sortOrder,
    currentListId,
    shouldRefresh,
    // labelFilter,
    candidateList.length,
  ]);

  const prevLabelFilter = useRef(labelFilter);

  const clearSelections = useCallback(() => {
    dispatch(setSelectedCandidateIds([]));
    setSelectedRows([]);
  }, [dispatch]);
  useEffect(() => {
    return () => {
      clearSelections();
      dispatch(setSearch(""));
      dispatch(setLabelFilter(""));
      dispatch(setLocationSearch(""));
      dispatch(setSortBy(null));
      dispatch(setSortOrder("asc"));
    };
  }, [dispatch, clearSelections]);

  //email templates
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const userEmailData = useCallAPI(USER_EMAIL_TEMPLATES);
  const createTemplate = useCallAPI(EMAIL_TEMPLATES_CREATE);
  const updateTemplate = useCallAPI(EMAIL_TEMPLATES_UPDATE);

  const fetchEmailTemplates = async () => {
    try {
      setIsLoadingTemplates(true);
      const response = await userEmailData.getList();

      // Important: We access the data after the await
      if (response?.data?.data) {
        setEmailTemplates(response.data.data);
      }
    } catch (error) {
      console.error("Failed to load email templates:", error);
      message.error("Failed to load email templates");
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  // Fetch templates when email modal opens
  useEffect(() => {
    if (showEmailForm) {
      fetchEmailTemplates();
    }
  }, [showEmailForm]);

  // Update templates when API data changes
  useEffect(() => {
    if (userEmailData.data?.data) {
      setEmailTemplates(userEmailData.data.data);
    }
  }, [userEmailData.data]);

  const handleSaveTemplateSuccess = useCallback(async () => {
    try {
      await fetchEmailTemplates();
      message.success("Email templates refreshed");
    } catch (error) {
      console.error("Failed to refresh templates:", error);
      message.error("Failed to refresh templates");
    }
  }, []);

  const handlePaginationChange = useCallback(
    (newPage, newPageSize) => {
      const pageSizeChanged = newPageSize !== localPageSize;

      if (pageSizeChanged) {
        setLocalPageSize(newPageSize);
        dispatch(setPageSize(newPageSize));
      }
      dispatch(
        fetchCandidates({
          jobId: record.opening_id,
          page: newPage,
          pageSize: pageSizeChanged ? newPageSize : localPageSize,
          search,
          sortBy: sortBy || "",
          sortOrder: sortOrder || "asc",
          shortlistId: currentListId || "",
          locationSearch,
          labelFilter,
        })
      );
    },
    [
      record.opening_id,
      localPageSize,
      search,
      sortBy,
      sortOrder,
      currentListId,
      locationSearch,
      labelFilter,
    ]
  );

  useEffect(() => {
    const shouldFetch =
      record &&
      (candidateList.length === 0 ||
        search !== prevSearch.current ||
        shouldRefresh ||
        locationSearch !== prevLocationSearch.current ||
        labelFilter !== prevLabelFilter.current);

    if (shouldFetch) {
      const fetch = () => {
        dispatch(
          fetchCandidates({
            jobId: record.opening_id,
            page,
            pageSize: localPageSize,
            search,
            sortBy: sortBy || "",
            sortOrder: sortOrder || "asc",
            shortlistId: currentListId || "",
            locationSearch,
            labelFilter,
          })
        );
      };

      const timeoutId = setTimeout(fetch, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [
    record,
    page,
    localPageSize,
    search,
    shouldRefresh,
    locationSearch,
    labelFilter,
  ]);
  useEffect(() => {
    prevSearch.current = search;
    prevLocationSearch.current = locationSearch;
    prevLabelFilter.current = labelFilter;
  }, [search, locationSearch, labelFilter]);

  // Add this useEffect for cleanup
  useEffect(() => {
    return () => {
      dispatch(setSearch(""));
      dispatch(setLabelFilter(""));
      dispatch(setLocationSearch(""));
      dispatch(setSortBy(null));
      dispatch(setSortOrder("asc"));
    };
  }, [dispatch]);
  // useEffect(() => {
  //   if (record && (locationSearch !== prevLocationSearch.current || labelFilter !== prevLabelFilter.current)) {
  //     prevLocationSearch.current = locationSearch;
  //     prevLabelFilter.current = labelFilter;

  //     dispatch(
  //       fetchCandidates({
  //         jobId: record.opening_id,
  //         page: 1, // Reset to first page on filter change
  //         pageSize: localPageSize,
  //         search,
  //         sortBy: sortBy || '',
  //         sortOrder: sortOrder || 'asc',
  //         shortlistId: currentListId || '',
  //         locationSearch,
  //         labelFilter,
  //       })
  //     );
  //   }
  // }, [locationSearch, labelFilter]);
  // Memoize the row selection handlers
  const handleRowSelection = useCallback(
    (selectedRowKeys, selectedRows) => {
      // Only update if there's an actual change
      if (
        JSON.stringify(selectedRowKeys) !== JSON.stringify(selectedCandidateIds)
      ) {
        dispatch(setSelectedCandidateIds(selectedRowKeys));
        setSelectedRows(selectedRows);
      }
    },
    [selectedCandidateIds, dispatch]
  );

  // Clear selections when changing pages or performing actions

  // Add this effect to clear selections on page change
  useEffect(() => {
    clearSelections();
  }, [page, currentListId]);

  // Add selection info display
  const SelectionBar = useMemo(() => {
    if (selectedCandidateIds.length === 0) return null;

    const currentPageIds = filteredCandidates.map((c) => c.candidate._id);
    const currentPageSelectedCount = selectedCandidateIds.filter((id) =>
      currentPageIds.includes(id)
    ).length;
    const isAllCurrentSelected =
      currentPageSelectedCount === currentPageIds.length;

    return (
      <Alert
        message={
          <Row justify="space-between" align="middle" style={{ width: "100%" }}>
            <Col>
              <Space>
                <Typography.Text>
                  {selectedCandidateIds.length} candidate
                  {selectedCandidateIds.length > 1 ? "s" : ""} selected
                </Typography.Text>
                {currentPageIds.length > 0 && (
                  <Checkbox
                    checked={isAllCurrentSelected}
                    onChange={() => {
                      if (isAllCurrentSelected) {
                        // Unselect current page candidates
                        dispatch(
                          setSelectedCandidateIds(
                            selectedCandidateIds.filter(
                              (id) => !currentPageIds.includes(id)
                            )
                          )
                        );
                      } else {
                        // Select all current page candidates
                        dispatch(
                          setSelectedCandidateIds([
                            ...new Set([
                              ...selectedCandidateIds,
                              ...currentPageIds,
                            ]),
                          ])
                        );
                      }
                    }}
                  >
                    {isAllCurrentSelected
                      ? "Unselect current page"
                      : "Select current page"}
                  </Checkbox>
                )}
              </Space>
            </Col>
            <Col>
              <Button
                type="link"
                onClick={clearSelections}
                icon={<CloseCircleOutlined />}
              >
                Clear selection
              </Button>
            </Col>
          </Row>
        }
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />
    );
  }, [selectedCandidateIds, filteredCandidates, dispatch, clearSelections]);

  return (
    <>
      <Modal
        title={editingListId ? "Edit List" : "Create New List"}
        open={isListModalVisible}
        onOk={handleCreateOrUpdateList}
        onCancel={handleListsCancel}
      >
        <Input
          placeholder="Enter list name"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        />
      </Modal>
      <Modal
        title="Select Columns"
        open={isModalVisible}
        onOk={handleListPreference}
        onCancel={handleCancel}
        style={{
          top: 0,
          right: 0,
          height: "100vh",
          position: "fixed",
          width: "300px",
          margin: 0,
          padding: 0,
          overflowY: "scroll",
        }}
        bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
      >
        {allColumns?.map((column) => (
          <div key={column.key} style={{ marginBottom: "10px" }}>
            <Switch
              checked={columnVisibility[column.key] || column.mandatory}
              onChange={(show) => handleSwitchChange(column.key, show)}
              style={{ marginLeft: "10px" }}
              disabled={column.mandatory}
            />{" "}
            <span>{column.title}</span>
            {column.mandatory && (
              <span style={{ color: "red" }}> (Mandatory)</span>
            )}
          </div>
        ))}
      </Modal>
      <Modal
        title="Send Invitaion"
        open={showLinkedInInvitaion}
        onOk={(e) => {
          setShowLinkedInInvitaion(false);
        }}
        onCancel={(e) => {
          setShowLinkedInInvitaion(false);
        }}
      >
        {showError(
          globalSuccess.status,
          globalSuccess.message,
          globalError.status,
          globalError.message
        )}
        <CandidateLinkedinInvitation
          form={form}
          selectedRows={selectedRows}
          sendInvitation={sendInvitation}
          maxChars={200}
        />
        {/* <Form
          form={form}
          name="linkedin_message"
          onFinish={sendInvitation}
          layout="vertical"
          style={{ maxWidth: "700px", margin: "auto" }}
        >
          <Form.Item
            name="displayName"
            label="LinkedIn Username"
            rules={[
              { required: true, message: "Please enter the LinkedIn username" },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="LinkedIn Users"
            >
              {selectedRows?.map((row) => (
                <Option key={row._id} value={row.candidate.first_name}>
                  <Tag color="blue">{row.candidate.first_name}</Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                insertVariable("name");
              }}
              style={{ marginLeft: "10px" }}
            >
              Name
            </Button>{" "}
            <Button
              type="primary"
              onClick={() => {
                insertVariable("email");
              }}
              style={{ marginLeft: "10px" }}
            >
              Email
            </Button>{" "}
            <Button
              type="primary"
              onClick={() => {
                insertVariable("phone");
              }}
              style={{ marginLeft: "10px" }}
            >
              Phone
            </Button>{" "}
          </Form.Item>

          <Form.Item
            name="message"
            label="Message"
            // rules={[{ required: true, message: 'Please enter your message' }]}
            rules={[
              { required: true, message: "Please enter your message" },
              {
                validator: (_, value) =>
                  value && value.length <= maxChars
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          `Message must be at most ${maxChars} characters`
                        )
                      ),
              },
            ]}
          >
            <TextArea
              rows={8}
              placeholder="Type your message here"
              ref={textAreaRef}
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
            />
          </Form.Item>

          <br />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send Message
            </Button>
          </Form.Item>
        </Form> */}
      </Modal>
      <Modal
        title="Send Email"
        open={showEmailForm}
        onCancel={handleCloseEmailModal}
        destroyOnClose={true}
        onOk={handleCloseEmailModal}
        width={800}
      >
        <CandidateEmail
          templates={emailTemplates}
          isLoading={isLoadingTemplates}
          createTemplate={createTemplate}
          updateTemplate={updateTemplate}
          onTemplateChange={handleSaveTemplateSuccess}
          form={form}
          emailData={emailData}
          insertVariable={insertVariable}
          onFinishFailed={onFinishFailed}
          sendEmail={sendEmail}
        />
      </Modal>
      <Modal
        title="Document Viewer"
        open={showCV}
        onOk={() => {
          setShowCv(false);
        }}
        onCancel={() => {
          setShowCv(false);
        }}
        style={{
          top: 0,
          right: 0,
          height: "100vh",
          position: "fixed",
          margin: 0,
          padding: 0,
        }}
        bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
        width={1000}
      >
        <PdfViewer fileUrl={cvPath} />
      </Modal>
      <Modal
        title={`Delete Candidate${idsToDelete.length > 1 ? "s" : ""}`}
        open={openDltModal}
        onOk={handleDeleteCandidate}
        okText="Delete"
        cancelText="Cancel"
        confirmLoading={isDeleting}
        onCancel={handleCancelDeleteModal}
      >
        <h5>
          Are you sure you want to delete{" "}
          {idsToDelete.length > 1 ? "these candidates" : "this candidate"}?
        </h5>
        <p style={{ color: "red" }}>This cannot be undone!</p>
      </Modal>
      {selectedEmailHistoryRecord && selectedEmailHistoryRecord.length > 0 && (
        <Modal
          title="Email Conversation"
          visible={isEmailHistoryModalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          {selectedEmailHistoryRecord?.map((record, index) => (
            <div key={index}>
              {/* Message Box */}
              <Card
                className="message-box"
                style={{ marginBottom: "20px", backgroundColor: "#f0f2f5" }}
              >
                <Typography.Text strong>Sent Message:</Typography.Text>
                <br />
                <Typography.Text>From: {record.from_email}</Typography.Text>
                <br />
                <Typography.Text>To: {record.to_email}</Typography.Text>
                <br />
                <Typography.Text>Subject: {record.subject}</Typography.Text>
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: record.message }}
                  style={{
                    padding: "10px",
                    whiteSpace: "pre-wrap",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                  }}
                />
                <Typography.Text type="secondary">
                  {new Date(record.created_at).toLocaleString()}
                </Typography.Text>
              </Card>

              {/* Reply Box */}
              {record.is_replied && (
                <Card
                  className="reply-box"
                  style={{ backgroundColor: "#e6fffb" }}
                >
                  <Typography.Text strong>Reply Message:</Typography.Text>
                  <br />
                  <Typography.Text>From: {record.to_email}</Typography.Text>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{ __html: record.reply_message }}
                    style={{
                      padding: "10px",
                      whiteSpace: "pre-wrap",
                      borderRadius: "5px",
                      backgroundColor: "#fff",
                    }}
                  />
                  <br />
                  <Typography.Text type="secondary">
                    {new Date(
                      record.meta.reply_receivedDateTime
                    ).toLocaleString()}
                  </Typography.Text>
                </Card>
              )}
            </div>
          ))}
        </Modal>
      )}
      <Notes
        handleCancel={() => setShowNotes(false)}
        isVisible={showNotes}
        handleNotes={handleNotes}
        notes={NotesData}
        jobOpenings={selectedRow}
        user={users}
        updateNoteState={setShowNotes}
        handleUpdateNote={handleUpdateNotes}
        handleDeleteNote={handleDeleteNotes}
        notesField={"multipleNotes"}
      />
      <CustomSnackbar
        success={SendBulkEmail.success || SearchContact.success}
        error={SendBulkEmail.error || SearchContact.error}
      />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          width: "100%",
        }}
      >
        <Card style={{ zIndex: 1 }}>
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={12} md={8} lg={6}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                style={{ width: "100%" }}
                onChange={handleSearch}
              />
            </Col>
            <Col xs={24} sm={12} md={16} lg={18}>
              <Row gutter={[8, 8]} justify="end">
                <Col>
                  <Button
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="ghost"
                    size="middle"
                    loading={loading}
                    title="Refresh Candidates Data"
                  >
                    {/* Refresh Candidates */}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => setIsModalVisible(true)}
                    icon={<ColumnHeightOutlined />}
                  >
                    Columns
                  </Button>
                </Col>
                <Col>
                  <GlobalSettings
                    // onClickSendEmail={onClickSendEmail}
                    onClickLinkedIn={handleLinkedInInviationModal}
                    searchContact={searchContact}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={[16, 16]}
            justify="space-between"
            align="middle"
            style={{ marginTop: "10px" }}
          >
            <Col xs={24} sm={12} md={8} lg={6}>
              <Typography.Text>
                Job ID:{" "}
                <Link
                  to={{
                    pathname: `/${rolePath}/opening-detail/${record?._id}`,
                    state: { record: record },
                  }}
                >
                  {record.opening_id}
                </Link>
              </Typography.Text>
            </Col>
            <Col xs={24} sm={12} md={16} lg={18}>
              <Row gutter={[8, 8]} justify="end" align="middle">
                <Col>
                  <Tooltip title="set page size">
                    <InputNumber
                      min={1}
                      value={localPageSize}
                      onChange={(value) => {
                        if (value) {
                          handlePaginationChange(1, value);
                        }
                      }}
                      style={{ width: 80, textAlign: "center" }}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Select
                    placeholder="Filter Label"
                    onChange={handleChangeLabel}
                    optionLabelProp="label"
                    value={selectedFilters}
                    mode="multiple"
                    style={{ width: "100%", minWidth: 150, maxWidth: 250 }}
                  >
                    <Select.Option value="top" label="Best">
                      <div className="demo-option-label-item">
                        Best Candidates
                      </div>
                    </Select.Option>
                    <Select.Option value="good" label="Good">
                      <div className="demo-option-label-item">
                        Good Candidates
                      </div>
                    </Select.Option>
                    <Select.Option value="qualified" label="Qualified">
                      <div className="demo-option-label-item">
                        Qualified Candidates
                      </div>
                    </Select.Option>
                  </Select>
                </Col>
                <Col>
                  <CandidateSort jobId={record.opening_id} />
                </Col>
                <Col>
                  <CandidateListManagement jobId={record.opening_id} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>

        <br />

        <Card
          title={
            <Typography.Text strong>
              Candidates List For {record.opening_title}
            </Typography.Text>
          }
          bordered={false}
          style={{
            flexGrow: 1,
            // overflowY: listView ? "hidden" : "scroll",
            // overflowY: "auto",
          }}
          extra={
            <Row align="middle" gutter={8}>
              <Col>
                <JobListPagination jobId={record.opening_id} />
              </Col>
              {selectedCandidateIds.length > 0 && (
                <>
                  <Col>
                    <Button
                      type="ghost"
                      onClick={handleSubmitCandidates}
                      loading={submitting}
                    >
                      Submit ({selectedCandidateIds.length})
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteMultipleCandidates()}
                    >
                      Delete ({selectedCandidateIds.length})
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      icon={<EmailOutlined />}
                      type="primary"
                      onClick={() => onClickSendEmail()}
                    >
                      Send Email ({selectedCandidateIds.length})
                    </Button>
                  </Col>
                </>
              )}
              <Col>
                <Switch
                  onChange={handleView}
                  title="Change View Style"
                  checkedChildren={<List />}
                  unCheckedChildren={<TableOutlined />}
                />
              </Col>
            </Row>
          }
        >
          {SelectionBar}
          {/* Rest of the card content */}
          {listView ? (
            <Table
              loading={loading}
              dataSource={filteredCandidates}
              key={JSON.stringify(filteredCandidates)}
              size="large"
              rowKey={(record) => record.candidate._id}
              onChange={handleTableChange}
              pagination={{
                position: ["bottomCenter"],
                total: totalData || 0,
                pageSize: localPageSize,
                current: currentPage || 1,
                showSizeChanger: true,
                onChange: (page, pageSize) =>
                  handlePaginationChange(page, pageSize),
              }}
              rowSelection={{
                type: selectionType,
                selectedRowKeys: selectedCandidateIds,
                onChange: handleRowSelection,
                preserveSelectedRowKeys: true,
              }}
              columns={visibleColumns}
              style={{
                overflowX: "scroll",
                maxHeight: "600px",
              }}
            />
          ) : (
            <Spin spinning={loading} tip="Loading Candidates...">
              <div
                style={{
                  overflowY: "scroll",
                  maxHeight: "600px",
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {filteredCandidates.length === 0 ? (
                  <Empty description="No candidates found for the selected filter" />
                ) : (
                  filteredCandidates.map((cndidate, index) => {
                    const {
                      candidate,
                      employment,
                      qualifications,
                      emailHistory,
                    } = cndidate;

                    const candidateLabel = bestCandidates.includes(
                      candidate._id
                    )
                      ? "top"
                      : goodCandidates.includes(candidate._id)
                      ? "good"
                      : qualifiedCandidates.includes(candidate._id)
                      ? "qualified"
                      : "";
                    return (
                      <CandidateCard
                        key={candidate._id + index}
                        candidate={candidate}
                        candidateLabel={candidateLabel}
                        getNotes={getNotes}
                        record={record}
                        currentListId={currentListId}
                        type={type}
                        rolePath={rolePath}
                        selectedCandidateIds={selectedCandidateIds}
                        employment={employment}
                        qualifications={qualifications}
                        emailHistory={emailHistory}
                        EmailStatus={EmailStatus}
                        lists={lists}
                        LinkedInInvitationStatus={LinkedInInvitationStatus}
                        isValidEmails={isValidEmails}
                        handleCheckboxChange={handleCheckboxChange}
                        handleLabelChange={handleLabelChange}
                        handleAddToList={handleAddToList}
                        onClickSendEmail={() => onClickSendEmail(cndidate)}
                        openDeleteModal={openDeleteModal}
                        IMAGE_CANDIDATE_URL={IMAGE_CANDIDATE_URL}
                        cndidate={cndidate}
                        attachments={candidate.attachments}
                        getLinkedInUrl={getLinkedInUrl}
                      />
                    );
                  })
                )}
              </div>
            </Spin>
          )}
        </Card>
      </section>
      <Modal
        title="Submit Candidates"
        open={submitModalVisible}
        onOk={handleConfirmSubmit}
        onCancel={() => setSubmitModalVisible(false)}
        confirmLoading={submitting}
      >
        <p>
          Are you sure you want to submit {selectedCandidateIds.length}{" "}
          candidate(s)?
        </p>
      </Modal>
    </>
  );
}
