import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dropdown, Menu, Input, Select, Space, Tag } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DownOutlined, ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setSortBy, setSortOrder, setPage, setLocationSearch, setLabelFilter, fetchCandidates } from '../../../../redux/reducers/candidatesSlice';
import { debounce } from 'lodash';

const { Option } = Select;

export default function CandidateSort({ jobId }) {
    const dispatch = useDispatch();
    const { currentListId, sortBy, sortOrder, page, pageSize, search, locationSearch, labelFilter } = useSelector((state) => state.candidates);
    const [locationSearchLocal, setLocationSearchLocal] = useState(locationSearch);
    const [labelFilterLocal, setLabelFilterLocal] = useState(labelFilter);
    
    // Add this useEffect at the top of your component
    useEffect(() => {
        // Reset filters when component mounts
        dispatch(setLocationSearch(''));
        dispatch(setLabelFilter(''));
        setLocationSearchLocal('');
        setLabelFilterLocal('');
        dispatch(setSortBy(null));
        dispatch(setSortOrder('asc'));
        
        // Fetch candidates with reset filters
        dispatch(fetchCandidates({
            jobId,
            page: 1,
            pageSize,
            search: '',
            sortBy: '',
            sortOrder: '',
            shortlistId: currentListId || '',
            locationSearch: '',
            labelFilter: ''
        }));
    }, []); // Empty dependency array means this runs once on mount

    const handleSort = (value) => {
        if (sortBy === value) {
            const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
            dispatch(setSortOrder(newSortOrder));
        } else {
            dispatch(setSortBy(value));
            dispatch(setSortOrder('asc'));
        }
        dispatch(setPage(1));
        dispatch(fetchCandidates({
            jobId,
            page: 1,
            pageSize,
            sortBy: value,
            sortOrder: sortBy === value ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc',
            shortlistId: currentListId,
            locationSearch: locationSearchLocal,
            labelFilter: labelFilterLocal,
          }));
        };

    // Modify the clearSort function to also clear filters
    const clearSort = () => {
        dispatch(setSortBy(null));
        dispatch(setSortOrder('asc'));
        dispatch(setLocationSearch(''));
        dispatch(setLabelFilter(''));
        setLocationSearchLocal('');
        setLabelFilterLocal('');
        dispatch(setPage(1));
        dispatch(fetchCandidates({
          jobId,
          page: 1,
          pageSize,
          search,
          sortBy: '',
          sortOrder: '',
          shortlistId: currentListId,
          locationSearch: '',
          labelFilter: ''
        }));
      };

    // Replace the debouncedLocationSearch
    const debouncedLocationSearch = useCallback(
        debounce((value) => {
          dispatch(setLocationSearch(value));
          dispatch(setPage(1));
          dispatch(fetchCandidates({
            jobId,
            page: 1,
            pageSize,
            search,
            sortBy: sortBy || '',
            sortOrder: sortOrder || 'asc',
            shortlistId: currentListId || '',
            locationSearch: value,
            labelFilter: labelFilterLocal,
          }));
        }, 300),
        [dispatch, jobId, pageSize, search, sortBy, sortOrder, currentListId, labelFilterLocal]
      );
      const handleLocationSearch = (e) => {
        const value = e.target.value;
        setLocationSearchLocal(value);
        debouncedLocationSearch(value);
      };

    useEffect(() => {
        setLocationSearchLocal(locationSearch);
    }, [locationSearch]);

   // Update handleLabelFilter
  const handleLabelFilter = useCallback((value) => {
    setLabelFilterLocal(value);
    dispatch(setLabelFilter(value));
    dispatch(setPage(1));
    dispatch(
      fetchCandidates({
        jobId,
        page: 1,
        pageSize,
        search,
        sortBy,
        sortOrder,
        shortlistId: currentListId,
        locationSearch,
        labelFilter: value,
      })
    );
  }, [dispatch, jobId, pageSize, search, sortBy, sortOrder, currentListId, locationSearch]);


    useEffect(() => {
        setLabelFilterLocal(labelFilter);
    }, [labelFilter]);

    // Add cleanup for debounced function
    useEffect(() => {
        return () => {
            debouncedLocationSearch.cancel();
        };
    }, [debouncedLocationSearch]);

    const menuItems = [
        {
            key: 'sort',
            type: 'group',
            label: 'Sort Options',
            children: [
                {
                    key: 'verifiedNumber',
                    label: (
                        <span className="flex items-center justify-between w-full">
                            Verified Number
                            {sortBy === 'verifiedNumber' && (sortOrder === 'asc' ? <ArrowUpOutlined className="w-4 h-4" /> : <ArrowDownOutlined className="w-4 h-4" />)}
                        </span>
                    ),
                    onClick: () => handleSort('verifiedNumber'),
                },
                {
                    key: 'verifiedEmail',
                    label: (
                        <span className="flex items-center justify-between w-full">
                            Verified Email
                            {sortBy === 'verifiedEmail' && (sortOrder === 'asc' ? <ArrowUpOutlined className="w-4 h-4" /> : <ArrowDownOutlined className="w-4 h-4" />)}
                        </span>
                    ),
                    onClick: () => handleSort('verifiedEmail'),
                },
            ],
        },
        {
            type: 'divider',
        },
        {
            key: 'location',
            type: 'group',
            label: 'Location',
            children: [
                {
                    key: 'locationSearch',
                    label: (
                        <Input
                            placeholder="Search location"
                            value={locationSearchLocal}
                            onChange={handleLocationSearch}
                            onPressEnter={handleLocationSearch}
                            onClick={(e) => e.stopPropagation()}
                        />
                    ),
                },
            ],
        },
        {
            type: 'divider',
        },
        {
            key: 'label',
            type: 'group',
            label: 'Label',
            children: [
                {
                    key: 'labelFilter',
                    label: (
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Label"
                            value={labelFilterLocal}
                            onChange={handleLabelFilter}
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                            dropdownStyle={{ zIndex: 2000 }}
                        >
                            <Option value="">All</Option>
                            <Option value="Linkedin">LinkedIn</Option>
                            <Option value="Indeed">Indeed</Option>
                        </Select>
                    ),
                },
            ],
        },
    ];

    return (
        <Space>
            <Dropdown
                menu={{ items: menuItems }}
                trigger={['click']}
            >
                <Button>
                    Sort & Filter <DownOutlined />
                </Button>
            </Dropdown>
            {sortBy && (
                <Button 
                    icon={<ClearOutlined />} 
                    title='Clear Sort & Filter'
                    onClick={clearSort}
                    size="small"
                />
            )}
        </Space>
    );
}