import {
  FacebookOutlined,
  LinkedinOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { RoomOutlined } from "@material-ui/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  COPILOT_CREATE_CAMPAIGN_REQUEST,
  COPILOT_EDIT_CAMPAIGN_REQUEST,
  COPILOT_GET_CAMPAIGN_REQUESTS,
  EMAIL_TEMPLATES_CREATE,
  EMAIL_TEMPLATES_USER_BY_TYPE,
  JOB_ASSIGNMENT_DETAILS,
  JOB_CANDIDATE_WITHDRAW_RECRUITER,
  JOB_DETAIL,
  OUTLOOK_EMAILS,
  SUBMISSION_BY_RECRUITER,
} from "../../../ApiUrl";
import { logoutHandler } from "../../../others/logout";
import ActivityLogUI from "../../modules/ActivityLog";
import BooleanString from "../../modules/Openings/BooleanString";
import OpeningDetailUi from "../../modules/Openings/OpeningDetailUI";
import { showError } from "../utils/helpers";
import CandidateList from "./CandidateList";
import CandidatesbyRecruiter from "./candidatesbyRecruiter";

const { Option } = Select;
const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const { Text, Title } = Typography;

export default function OpeningsDetail(props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [bdmId, setBdmId] = useState("");
  const [openingDetails, setOpeningDetails] = useState("");
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const users = useSelector(({ users }) => users);
  let location = useLocation();
  const history = useHistory();
  const { candidateId } = location.state || {};
  const [predefinedTemplates, setPredefinedTemplates] = useState([]);
  const [isCampaignModalOpen, setIsisCampaignModalOpen] = useState(false);

  const [campaign, setCampaign] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isEmailTemplateModalOpen, setIsEmailTemplateModalOpen] = useState(
    false
  );
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false);
  const [editedCampaignId, setEditedCampaignId] = useState(null);
  const [templateContent, setTemplateContent] = useState("");
  const [iframeCode, setIframeCode] = useState("");
  const [editor, setEditor] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [connectedEmails, setConnectedEmails] = useState([]);
  const daysOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const predefinedPlatforms = ["monster", "local", "cb"];

  const fetchCampaign = async () => {
    try {
      const openingId = location.state.record._id;
      const response = await axios.get(
        `${COPILOT_GET_CAMPAIGN_REQUESTS}/${openingId}`,
        {
          headers: { Authorization: users.token },
        }
      );
      setCampaign(response.data.data);
    } catch (error) {
      console.error("Error fetching campaign:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchConnectedEmails = async () => {
    try {
      const userId = users.user._id;
      const response = await axios.get(OUTLOOK_EMAILS, {
        headers: {
          Authorization: users.token,
        },
        params: { userId },
      });

      if (response.data) {
        const emails = response.data;

        setConnectedEmails(emails);
      } else {
        console.error("Failed to fetch connected emails");
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  useEffect(() => {
    if (users.token && users.user._id) {
      fetchConnectedEmails();
    }
  }, [users.token, users.user._id]);
  const appendVariable = (tag) => {
    if (!editor) return; // Ensure the CKEditor instance exists

    editor.model.change((writer) => {
      const selection = editor.model.document.selection;

      // Check if there's a range in the current selection
      if (selection.isCollapsed) {
        // Insert the tag at the cursor position
        const position = selection.getFirstPosition();
        writer.insertText(tag, position);
      } else {
        // Replace the selected text with the tag
        const range = selection.getFirstRange();
        writer.insertText(tag, range);
        writer.setSelection(writer.createPositionAfter(range.end.nodeAfter));
      }
    });

    // Focus the editor
    editor.editing.view.focus();
  };
  const handleSaveEmailTemplate = (values) => {
    message.loading("creating template...", 0);
    const params = {
      user_id: users.user._id,
      email_type: "ai-copilot-recruitment",
      content: values.content,
      template_name: values.template_name,
      subject: values.subject,
    };
    setLoadingTemplate(true);
    axios
      .post(EMAIL_TEMPLATES_CREATE, params, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        message.destroy();
        message.success("Sucessfully created Email Template", 0);
      })
      .catch((error) => {});
    emailTemplateForm.resetFields();
    setIsEmailTemplateModalOpen(false);
    message.destroy();
  };
  const [emailTemplateForm] = Form.useForm();
  const [form] = Form.useForm();
  useEffect(() => {
    getOpeningDetail();
    fetchCampaign();
    return () => {};
  }, []);

  const getOpeningDetail = () => {
    if (location?.state?.record) {
      axios
        .get(JOB_DETAIL + "/" + location.state.record._id, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          setOpeningDetails(res.data.data[0]);
          getJobAssignmentDetails(res.data.data[0].opening_id);
        })
        .catch((error) => {
          if (error?.response?.status == 401) {
            logoutHandler(history);
          }
        });
    } else {
      history.push("/recruiter/assign-jobs");
    }
  };
  const fetchTemplates = () => {
    setLoadingTemplate(true);

    axios
      .get(`${EMAIL_TEMPLATES_USER_BY_TYPE}?type=${"ai-copilot-recruitment"}`, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        const result = res.data.data || []; // Ensure it's an array
        const templates = result.map((item) => ({
          name: item.template_name,
          ...item,
        }));
        setPredefinedTemplates(templates); // Update the predefined templates with the response
        setLoadingTemplate(false);
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
        setPredefinedTemplates([]); // Reset templates on error
        setLoadingTemplate(false);
      });
  };
  const showCampainModal = () => {
    fetchTemplates();
    setIsisCampaignModalOpen(true);
  };

  const handleCancelCampaignModal = () => {
    setIsisCampaignModalOpen(false);
    form.resetFields();
    setEditedCampaignId(null);
  };
  const onSubmit = (selecterIds) => {
    let candidate_submission_by_recruiter = [];
    // console.log("bdmId", bdmId);
    if (bdmId) {
      selecterIds.map((item) => {
        const object = {
          opening_id: openingDetails.opening_id,
          candidate_id: item,
          recruiter_id: users.user._id,
          company_id:
            openingDetails.account_name.length > 0
              ? openingDetails.account_name[0]._id
              : null,
          bdm_id: bdmId,
          submission_status: "submission",
        };
        candidate_submission_by_recruiter.push(object);
      });

      let param = {
        candidate_submission_by_recruiter: candidate_submission_by_recruiter,
      };

      if (selecterIds.length > 0 && openingDetails.account_name.length) {
        setLoading(true);
        axios
          .post(SUBMISSION_BY_RECRUITER, param, {
            headers: { Authorization: users.token },
          })
          .then((res) => {
            if (!res.data.error) {
              setSuccess(true);
              setMsgSuccess(res.data.message);
              setLoading(false);
              setError(false);
              setDefaultState();
              getOpeningDetail();
            }
          })
          .catch((error) => {
            setSuccess(false);
            setLoading(false);
            setError(true);
            if (error.response) {
              let errorMessage = "";
              {
                error.response.data &&
                  Object.entries(error.response.data.errors).map(
                    ([key, value]) => {
                      return (errorMessage += value + ", ");
                    }
                  );
              }
              setmsgError(errorMessage);
            }
            if (error?.response?.status == 401) {
              logoutHandler(history);
            }
            setDefaultState();
          });
      } else {
        setSuccess(false);
        setLoading(false);
        setError(true);
        const errorMessage = "please select candidate";
        setmsgError(errorMessage);
        setDefaultState();
      }
    }
  };
  const handleSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        number_of_source_per_platforms: values.platformNumbers,
        opening_id: openingDetails._id,
        mode: values.campaign_mode,
        emailTemplates: [values.template],
        campaign_active_days_and_time: {
          ...values.campaign_active_days_and_time,
          time_range: {
            start: values.campaign_active_days_and_time.time_range.start.format(
              "HH:mm"
            ),
            end: values.campaign_active_days_and_time.time_range.end.format(
              "HH:mm"
            ),
          },
        },
      };

      if (isEditing) {
        // Update existing campaign
        await axios.post(
          `${COPILOT_EDIT_CAMPAIGN_REQUEST}/${editedCampaignId}`,
          payload,
          {
            headers: { Authorization: users.token },
          }
        );
        setEditedCampaignId(null);
        message.success({
          content: "Campaign updated successfully.",
          duration: 3, // Message will disappear after 3 seconds
        });
      } else {
        await axios.post(COPILOT_CREATE_CAMPAIGN_REQUEST, payload, {
          headers: { Authorization: users.token },
        });
        message.success({
          content: "Campaign created successfully.",
          duration: 3, // Message will disappear after 3 seconds
        });
      }

      handleCancelCampaignModal();
      // fetchCampaign(); // Refresh campaign list
    } catch (error) {
      console.error(error);
      message.error({
        content: "An error occurred. Please try again.",
        duration: 3, // Message will disappear after 3 seconds
      });
    }
  };
  const getJobAssignmentDetails = (id) => {
    axios
      .post(
        JOB_ASSIGNMENT_DETAILS,
        {
          recruiter_id: users.user._id,
          opening_id: id,
        },
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => {
        setBdmId(res.data.data[0].created_by);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          logoutHandler(history);
        }
      });
  };

  const withdrawCandidate = (selecterIds, status) => {
    let candidate_withdraw_by_recruiter = [];
    selecterIds.map((item) => {
      const object = {
        opening_id: openingDetails.opening_id,
        _id: item,
        recruiter_id: users.user._id,
      };
      candidate_withdraw_by_recruiter.push(object);
    });

    let param = {
      candidate_withdraw_by_recruiter: candidate_withdraw_by_recruiter,
    };

    if (selecterIds.length > 0) {
      setLoading(true);
      axios
        .post(JOB_CANDIDATE_WITHDRAW_RECRUITER, param, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          if (!res.data.error) {
            setSuccess(true);
            setMsgSuccess(res.data.message);
            setLoading(false);
            setError(false);
            setDefaultState();
          }
        })
        .catch((error) => {
          setSuccess(false);
          setLoading(false);
          setError(true);
          let errorMessage = "";
          {
            error.response.data &&
              Object.entries(error.response.data.errors).map(([key, value]) => {
                return (errorMessage += value + ", ");
              });
          }
          setmsgError(errorMessage);
          setDefaultState();
          if (error?.response?.status == 401) {
            logoutHandler(history);
          }
        });
    } else {
      setSuccess(false);
      setLoading(false);
      setError(true);
      const errorMessage = "please select candidate";
      setmsgError(errorMessage);
      setDefaultState();
    }
  };

  const onDelete = (selecterIds) => {
    if (selecterIds.length > 0) {
      setLoading(true);
      axios
        .delete("url", {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          if (!res.data.error) {
            setSuccess(true);
            setMsgSuccess(res.data.message);
            setLoading(false);
            setError(false);
          }
        })
        .catch((error) => {
          setSuccess(false);
          setLoading(false);
          setError(true);
          let errorMessage = "";
          {
            error.response.data &&
              Object.entries(error.response.data.errors).map(([key, value]) => {
                return (errorMessage += value + ", ");
              });
          }
          setmsgError(errorMessage);
          setDefaultState();
          if (error?.response?.status == 401) {
            logoutHandler(history);
          }
        });
    }
  };

  const setDefaultState = () => {
    setTimeout(() => {
      setSuccess(false);
      setLoading(false);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
    }, 3000);
  };
  const handleTemplateSelection = (id) => {
    // Find the selected template from the fetched templates
    const selectedTemplate = predefinedTemplates?.find(
      (template) => template._id === id
    );
    console.log(selectedTemplate);

    if (selectedTemplate) {
      setTemplateContent(selectedTemplate.content); // Set the content
      setSelectedTemplate(selectedTemplate); // Update selected template
      setSelectedTemplateId(selectedTemplate._id); // Update selected template ID
    } else {
      // Handle case where the template is not found
      console.error("Selected template not found!");
      setTemplateContent("");
      setSelectedTemplate(null);
      setSelectedTemplateId(null);
    }
  };

  const handleOpenCampaignModal = (campaign = null) => {
    fetchTemplates();
    setIsisCampaignModalOpen(true);

    // :
    // {time_range: {…}}
    // campaign_active_days_and_time.days
    // :
    // undefined
    // campaign_mode
    // :
    // undefined
    // campaign_name
    // :
    // "Hiring a Java Architect"
    // is_boolean_string_verified
    // :
    // true
    // platformNumbers
    // :
    // {monster: undefined, local: undefined}
    // template
    // :
    // undefined
    console.log(campaign);
    if (campaign) {
      setEditedCampaignId(campaign._id);
      form.setFieldsValue({
        ...campaign,
        platformNumbers: campaign.number_of_source_per_platforms,
        campaign_mode: campaign.mode,
        template: campaign.emailTemplates[0],
        campaign_active_days_and_time: {
          ...campaign?.campaign_active_days_and_time,
          time_range: {
            start: campaign?.campaign_active_days_and_time?.time_range?.start
              ? moment(
                  campaign?.campaign_active_days_and_time?.time_range?.start,
                  "HH:mm"
                )
              : null,
            end: campaign?.campaign_active_days_and_time?.time_range?.end
              ? moment(
                  campaign?.campaign_active_days_and_time?.time_range?.end,
                  "HH:mm"
                )
              : null,
          },
        },
        start_date: campaign?.start_date ? moment(campaign.start_date) : null,
        end_date: campaign?.end_date ? moment(campaign.end_date) : null,
      });
      console.log(form.getFieldsValue());
      setIsEditing(true);
    } else {
      form.resetFields();
      setIsEditing(false);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // Function to show the Embed Modal
  const showEmbedModal = () => {
    const code = generateIframe();
    setIframeCode(code); // Store the iframe code
    setIsEmbedModalOpen(true); // Open the modal
  };

  // Function to hide the Embed Modal
  const handleEmbedModalCancel = () => {
    setIsEmbedModalOpen(false);
  };
  const copyToClipboard = () => {
    const iframeCode = generateIframe();
    navigator.clipboard
      .writeText(iframeCode)
      .then(() => {
        message.success("Embed code copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy embed code.");
      });
  };
  // Function to copy the iframe code to clipboard
  const copyEmbedCode = () => {
    navigator.clipboard
      .writeText(iframeCode)
      .then(() => {
        message.success("Embed code copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy embed code.");
      });
  };
  const handleShare = (platform) => {
    const jobTitle = "Job Title Here"; // Replace with job title from data
    const jobDescription =
      "Job Description Here Hello Description of Job is here."; // Replace with job description from data
    const jobUrl = `${DOMAIN_URL}/openings/${openingId}`; // Construct job URL dynamically

    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      jobUrl
    )}&title=${encodeURIComponent(jobTitle)}&summary=${encodeURIComponent(
      jobDescription
    )}&source=pk.indeed.com`;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      jobUrl
    )}`;
    if (platform === "facebook") {
      window.open(facebookShareUrl, "_blank");
    } else if (platform === "linkedin") {
      window.open(linkedInShareUrl, "_blank");
    }
  };
  const object = openingDetails
    ? {
        opening_id: openingDetails ? openingDetails.opening_id : "",
        recruiter_id: users.user._id,
      }
    : undefined;
  // console.log('category in opening details->', openingDetails?.category[0]?.name)
  return (
    <div>
      <Row gutter={24}>
        <Col span={14}>
          <OpeningDetailUi
            openingDetails={openingDetails}
            goBack={props.history.goBack}
          />
          <br />
          <ActivityLogUI
            props={props}
            param={openingDetails}
            isActivityLogs={false}
          />
        </Col>
        <Col span={10}>
          <ActivityLogUI
            props={props}
            param={openingDetails}
            isActivityLogs={true}
          />

          <br />

          <BooleanString
            jobId={openingDetails._id}
            description={openingDetails?.job_description}
            category={openingDetails?.category?.[0]?.name ?? ""}
            title={openingDetails?.opening_title}
            city={openingDetails?.city?.[0]?.city ?? ""}
            state={openingDetails?.state?.[0]?.state ?? ""}
            country={openingDetails?.country}
            required_skills={openingDetails?.required_skills}
          />
          <>
            <Card>
              <Space>
                <Button
                  type="primary"
                  icon={<ShareAltOutlined />}
                  onClick={showModal}
                >
                  Share
                </Button>
                <Button
                  type="default"
                  icon={<RoomOutlined />}
                  onClick={showEmbedModal}
                >
                  Embed Code
                </Button>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => handleOpenCampaignModal()}
                >
                  Start Copilot
                </Button>
              </Space>
            </Card>
            <br />
            {campaign.length > 0 &&
              campaign.map((item, idx) => (
                <Card
                  bordered={true}
                  key={idx}
                  title={item.campaign_name}
                  style={{ marginBottom: "16px" }}
                >
                  <Button onClick={() => handleOpenCampaignModal(item)}>
                    Edit
                  </Button>
                  <div className="mt-3"></div>

                  <Text>
                    <strong>Active Days:</strong>{" "}
                    {item.campaign_active_days_and_time?.days?.join(", ") ||
                      "-"}
                  </Text>
                  <br />
                  <Text>
                    <strong>Time Range:</strong>{" "}
                    {`${item.campaign_active_days_and_time?.time_range?.start ||
                      "-"} - ${item.campaign_active_days_and_time?.time_range
                      ?.end || "-"}`}
                  </Text>
                  <br />
                  {item.number_of_source_per_platforms && (
                    <Text>
                      <strong>Number of Sources:</strong>{" "}
                      {Object.entries(item.number_of_source_per_platforms)
                        .map(([platform, count]) => `${platform}: ${count}`)
                        .join(", ") || "-"}
                    </Text>
                  )}
                  <br />
                  <Text>
                    <strong>Created At:</strong>{" "}
                    {new Date(item.created_at).toLocaleDateString() || "-"}
                  </Text>
                </Card>
              ))}
            {/* Embed Code Modal */}

            <Modal
              title="Embed Job Posting on Your Website"
              open={isEmbedModalOpen}
              onCancel={handleEmbedModalCancel}
              footer={null}
            >
              <Typography.Title level={5}>How to Embed</Typography.Title>
              <Typography.Paragraph>
                Copy the code below and paste it into your website's HTML where
                you want the job posting to appear.
              </Typography.Paragraph>
              <Card bordered style={{ background: "#f5f5f5" }}>
                <pre
                  style={{
                    background: "#333",
                    color: "#fff",
                    padding: "15px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    overflowX: "auto",
                  }}
                >
                  {iframeCode}
                </pre>
              </Card>
              <div style={{ textAlign: "right", marginTop: "15px" }}>
                <Button type="primary" onClick={copyEmbedCode}>
                  Copy to Clipboard
                </Button>
              </div>
            </Modal>
            <Modal
              title="Share this post"
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
            >
              <Space size="large">
                <Button
                  icon={<FacebookOutlined />}
                  type="primary"
                  onClick={() => handleShare("facebook")}
                  style={{ backgroundColor: "#3b5998", borderColor: "#3b5998" }}
                >
                  Facebook
                </Button>
                <Button
                  icon={<LinkedinOutlined />}
                  type="primary"
                  onClick={() => handleShare("linkedin")}
                  style={{ backgroundColor: "#0e76a8", borderColor: "#0e76a8" }}
                >
                  LinkedIn
                </Button>
              </Space>
            </Modal>
            <Modal
              title={isEditing ? "Edit Copilot" : "Start Copilot"}
              visible={isCampaignModalOpen}
              onCancel={handleCancelCampaignModal}
              footer={null}
              width={1000}
              bodyStyle={{ padding: "24px 32px" }}
            >
              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="campaign_name"
                      label="Campaign Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the campaign name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter campaign name" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name={["campaign_active_days_and_time", "days"]}
                      label="Active Days"
                      rules={[
                        {
                          required: true,
                          message: "Please select active days",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select active days"
                        allowClear
                      >
                        {daysOptions.map((day) => (
                          <Option key={day} value={day}>
                            {day}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="campaign_mode"
                      label="Mode"
                      rules={[
                        { required: true, message: "Please select the mode" },
                      ]}
                    >
                      <Select placeholder="Select Mode">
                        <Option value="simple">Simple</Option>
                        <Option value="complex">Complex</Option>
                        <Option value="medium">Medium</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Typography.Title level={5} style={{ marginBottom: "16px" }}>
                  Platforms Sourcing
                </Typography.Title>

                <Row gutter={[24, 24]}>
                  {predefinedPlatforms.map((platform) => (
                    <Col xs={24} sm={12} md={8} key={platform}>
                      <Form.Item
                        name={["platformNumbers", platform]}
                        label={platform}
                        rules={[
                          {
                            type: "number",
                            min: 0,
                            message: "Number must be positive",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          placeholder="Enter number"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>

                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="template"
                      label="Select Email Template"
                      rules={[
                        {
                          required: true,
                          message: "Please select an email template",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select an email template"
                        onChange={handleTemplateSelection}
                      >
                        {predefinedTemplates.map((template) => (
                          <Option key={template.type} value={template._id}>
                            {template.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Button
                        type="link"
                        onClick={() => {
                          setIsEmailTemplateModalOpen(true);
                        }}
                        style={{ padding: 0 }}
                      >
                        Create New Template
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>

                {templateContent && (
                  <Card
                    style={{
                      border: "1px solid #e8e8e8",
                      padding: "16px",
                      background: "#f9f9f9",
                      marginTop: "16px",
                    }}
                  >
                    <Typography.Text strong>Template Preview:</Typography.Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: templateContent }}
                    />
                  </Card>
                )}

                <Row gutter={[24, 24]} style={{ marginTop: "16px" }}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="email_to_reach_candidate"
                      label="Email to Reach"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the email address",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Enter email address" /> */}
                      <Select
                        placeholder="Select Email"
                        style={{ width: "100%" }}
                      >
                        {connectedEmails.map((email) => (
                          <Option key={email.email} value={email.email}>
                            {email.email}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Active Time Range"
                      style={{ marginBottom: 0 }}
                    >
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item
                            name={[
                              "campaign_active_days_and_time",
                              "time_range",
                              "start",
                            ]}
                            rules={[
                              {
                                required: true,
                                message: "Please select start time",
                              },
                            ]}
                          >
                            <TimePicker
                              format="HH:mm"
                              placeholder="Start Time"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[
                              "campaign_active_days_and_time",
                              "time_range",
                              "end",
                            ]}
                            rules={[
                              {
                                required: true,
                                message: "Please select end time",
                              },
                            ]}
                          >
                            <TimePicker
                              format="HH:mm"
                              placeholder="End Time"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="start_date"
                      label="Start Date"
                      rules={[
                        {
                          required: true,
                          message: "Please select the start date",
                        },
                      ]}
                    >
                      <DatePicker
                        format="YYYY-MM-DD"
                        placeholder="Select start date"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="end_date"
                      label="End Date"
                      rules={[
                        {
                          required: true,
                          message: "Please select the end date",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("start_date").isSameOrBefore(value)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("End date must be after start date")
                            );
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        format="YYYY-MM-DD"
                        placeholder="Select end date"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block>
                        {isEditing ? "Update Campaign" : "Submit Campaign"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>

            <Modal
              title="Create Email Template"
              visible={isEmailTemplateModalOpen}
              onCancel={() => {
                setIsEmailTemplateModalOpen(false);
              }}
              footer={null}
              width={800}
              bodyStyle={{ padding: "24px 32px" }}
            >
              <Form
                form={emailTemplateForm}
                layout="vertical"
                onFinish={handleSaveEmailTemplate}
              >
                {/* Template Subject */}
                <Form.Item
                  name="template_name"
                  label={<strong>Template Name</strong>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the template name",
                    },
                  ]}
                >
                  <Input placeholder="Enter email subject" />
                </Form.Item>
                {/* Template Subject */}
                <Form.Item
                  name="subject"
                  label={<strong>Subject</strong>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the email subject",
                    },
                  ]}
                >
                  <Input placeholder="Enter email subject" />
                </Form.Item>

                {/* Variable Options */}
                <Typography.Text strong>Add Variables:</Typography.Text>
                <div style={{ marginBottom: "10px" }}>
                  {[
                    { label: "Opening Title", tag: "{{OpeningTitle}}" },
                    { label: "Candidate Name", tag: "{{CandidateName}}" },
                    { label: "Short Description", tag: "{{ShortDescription}}" },
                  ].map((variable) => (
                    <Button
                      key={variable.tag}
                      type="default"
                      size="small"
                      style={{ marginRight: "8px", marginBottom: "8px" }}
                      onClick={() => appendVariable(variable.tag)}
                    >
                      {variable.label}
                    </Button>
                  ))}
                </div>

                <Form.Item
                  name="content"
                  label={<strong>Content</strong>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the email content.",
                    },
                  ]}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    data={form.getFieldValue("content")} // Initialize with current form value
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      emailTemplateForm.setFieldsValue({ content: data });
                      setEditor(editor); // Save the editor instance
                    }}
                  />
                </Form.Item>

                {/* Save Button */}
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Save Template
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </>
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={12}>
          {
            <CandidatesbyRecruiter
              onSubmit={withdrawCandidate}
              onDelete={onDelete}
              object={object}
              loading={loading}
              opening_details={openingDetails}
            />
          }
        </Col>
        <Col span={12}>
          <CandidateList
            candidateId={candidateId}
            onSubmit={onSubmit}
            object={{ opening_id: openingDetails.opening_id }}
            loading={loading}
            opening_details={openingDetails}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>{showError(success, msgSuccess, error, msgError)}</Col>
      </Row>
    </div>
  );
}
