import React, { useState, useEffect } from "react";
import { Calendar, Typography, Button, Card, Spin, message } from "antd";
import moment from "moment";
import axios from "axios";
import {
  COPILOT_CAMPAIGN_TIME_SLOTS_GET,
  COPILOT_CAMPAIGN_AVAILABLE_TIME_SLOTS_GET,
  COPILOT_POST_TIME_SLOTS,
  COPILOT_GET_DATE_SPECIFIC_SLOTS_WITH_YEAR_MONTH,
} from "../../../ApiUrl";
import { useParams } from "react-router";

const { Title } = Typography;

const CalendarUI = () => {
  const [defaultSchedule, setDefaultSchedule] = useState({});
  const [loading, setLoading] = useState(true);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const { campaignId, token, sourceId: candidateId } = useParams();
  const [timezone, setTimezone] = useState(null);

  const [activeDates, setActiveDates] = useState([]); // State to store dates with available slots

  const fetchDateSpecificSlots = async (month, year) => {
    try {
      const response = await axios.post(
        COPILOT_GET_DATE_SPECIFIC_SLOTS_WITH_YEAR_MONTH,
        { campaign_id: campaignId, month, year },
        { headers: { Authorization: token } }
      );
      const datesWithSlots = response.data.data.map((slot) =>
        moment(slot.date).format("YYYY-MM-DD")
      );
      setActiveDates(datesWithSlots); // Update state with active dates
    } catch (error) {
      console.error("Failed to fetch date-specific slots:", error);
      message.error("Failed to fetch date-specific slots. Please try again.");
    }
  };

  const isValidDate = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const dayName = date.format("dddd");
    if (activeDates.includes(formattedDate)) {
      return true; // Date has specific slots available
    }
    // Default validation logic
    return (
      getValidDays().includes(dayName) && date.isSameOrAfter(moment(), "day")
    );
  };

  useEffect(() => {
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    fetchDateSpecificSlots(currentMonth, currentYear);
  }, []);

  // Fetch the default schedule
  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const response = await axios.get(
          `${COPILOT_CAMPAIGN_TIME_SLOTS_GET}/${campaignId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const transformedSchedule = response.data.data.slots.reduce(
          (acc, slot) => {
            acc[slot.day] = slot.times.map((time) => ({
              start: time.start,
              end: time.end,
            }));
            return acc;
          },
          {}
        );

        setDefaultSchedule(transformedSchedule);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch schedule:", error);
        message.error("Failed to fetch schedule. Please try again.");
        setLoading(false);
      }
    };

    fetchSchedule();
  }, [campaignId, token]);

  const getValidDays = () => {
    return Object.keys(defaultSchedule).filter(
      (day) => defaultSchedule[day]?.length > 0
    );
  };

  // const isValidDate = (date) => {
  //   const dayName = date.format("dddd");
  //   return (
  //     getValidDays().includes(dayName) && date.isSameOrAfter(moment(), "day")
  //   );
  // };

  const bookSlot = async (slot) => {
    try {
      const response = await axios.post(
        COPILOT_POST_TIME_SLOTS,
        {
          campaign_id: campaignId,
          candidate_id: candidateId,
          start: slot.start,
          end: slot.end,
          date: selectedDate,
        },
        {
          headers: { Authorization: token },
        }
      );
      message.success("Slot booked successfully!"); // Show success message
    } catch (error) {
      console.error("Failed to book slot:", error.response.data);
      message.error(
        error.response.data || "Failed to book slot. Please try again."
      );
    }
  };

  const handleMonthChange = (value) => {
    const newMonth = value.month() + 1;
    const newYear = value.year();
    fetchDateSpecificSlots(newMonth, newYear); // Fetch slots when the calendar month changes
  };

  const onDateSelect = async (date) => {
    if (isValidDate(date)) {
      setSelectedDate(date);
      setSelectedSlot(null); // Reset the selected slot
      setSlotsLoading(true); // Set loading for slots
      try {
        const response = await axios.post(
          COPILOT_CAMPAIGN_AVAILABLE_TIME_SLOTS_GET,
          {
            campaign_id: campaignId,
            date: date.format("YYYY-MM-DD"),
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        setAvailableSlots(response.data.data.availableSlots);
      } catch (error) {
        console.error("Failed to fetch available slots:", error);
        message.error("Failed to fetch available slots. Please try again.");
      } finally {
        setSlotsLoading(false); // Stop loading
      }
    }
  };

  // Render each date cell
  const dateCellRender = (date) => {
    const isActive = isValidDate(date);
    const isSelected = selectedDate && date.isSame(selectedDate, "day");

    return (
      <div
        style={{
          textAlign: "center",
          color: isActive ? (isSelected ? "#fff" : "#1890ff") : "#ccc",
          background: isSelected ? "#1890ff" : isActive ? "#f0f8ff" : "#f5f5f5",
          borderRadius: "50%",
          lineHeight: "32px",
          width: "32px",
          height: "32px",
          margin: "auto",
          cursor: isActive ? "pointer" : "not-allowed",
        }}
        onClick={() => onDateSelect(date)}
      >
        {date.date()}
      </div>
    );
  };

  // Render time slots for the selected date
  const renderTimeSlots = () => {
    if (slotsLoading) {
      return (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Spin size="small" />
        </div>
      );
    }

    if (!selectedDate) return null;

    if (availableSlots.length === 0) {
      return <p>No slots available for this date.</p>;
    }

    return (
      <div style={{ textAlign: "left" }}>
        <Title level={5}>Available Slots:</Title>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {availableSlots.map((slot, index) => (
            <div
              key={index}
              onClick={() => setSelectedSlot(slot)}
              style={{
                padding: "8px 12px",
                borderRadius: "4px",
                border: "1px solid #1890ff",
                backgroundColor:
                  selectedSlot === slot ? "#1890ff" : "transparent",
                color: selectedSlot === slot ? "#fff" : "#1890ff",
                cursor: "pointer",
              }}
            >
              {slot.start} - {slot.end}
            </div>
          ))}
        </div>
        {selectedSlot && (
          <Button
            type="primary"
            style={{ marginTop: "20px" }}
            onClick={() =>
              // alert(`Booking slot: ${selectedSlot.start} - ${selectedSlot.end}`)
              bookSlot(selectedSlot)
            }
          >
            Book Slot
          </Button>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Card
      style={{
        maxWidth: "800px",
        margin: "auto",
        padding: "20px",
        borderRadius: "12px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
      bodyStyle={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <p>Time Zone : {timezone}</p>
      <Title level={4} style={{ textAlign: "center" }}>
        Select a Date and Time Slot
      </Title>
      <div style={{ display: "flex", gap: "30px" }}>
        {/* Calendar */}
        <div style={{ width: "300px" }}>
          <Calendar
            fullscreen={false}
            dateFullCellRender={dateCellRender}
            headerRender={({ value, onChange }) => {
              const current = value.clone();
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 16px",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const prev = current.clone().subtract(1, "month");
                      onChange(prev); // Move calendar to the previous month
                      handleMonthChange(prev); // Fetch slots for the updated month
                    }}
                  >
                    {"<"}
                  </button>
                  <Title level={5} style={{ margin: 0 }}>
                    {current.format("MMMM YYYY")}
                  </Title>
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const next = current.clone().add(1, "month");
                      onChange(next); // Move calendar to the next month
                      handleMonthChange(next); // Fetch slots for the updated month
                    }}
                  >
                    {">"}
                  </button>
                </div>
              );
            }}
          />
        </div>

        {/* Slot Details */}
        <div
          style={{
            flex: 1,
            padding: "10px",
            border: "1px solid #f0f0f0",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
          }}
        >
          <Title level={4}>
            {selectedDate
              ? selectedDate.format("dddd, MMMM Do YYYY")
              : "Select a date"}
          </Title>
          {renderTimeSlots()}
        </div>
      </div>
    </Card>
  );
};

export default CalendarUI;
