import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { requestHeader } from "../../others/header";
import {
  ADD_CANDIDATES_TO_LIST,
  AUTO_SOURCE_CANDIDATE_DELETE,
  CANDIDATE_DELETE_CANDIDATES,
  CANDIDATE_LIST_AUTO_SOURCED,
  CREATE_LIST,
  DELETE_LIST,
  GET_LISTS,
  REMOVE_CANDIDATES_FROM_LIST,
  SUBMISSION_BY_BDM,
  SUBMISSION_BY_RECRUITER,
  UPDATE_LIST,
} from "../../ApiUrl";

export const fetchCandidates = createAsyncThunk(
  "candidates/fetchCandidates",
  async (
    {
      jobId,
      page,
      pageSize,
      search,
      sortBy,
      sortOrder,
      shortlistId,
      locationSearch,
      labelFilter,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `${CANDIDATE_LIST_AUTO_SOURCED}/${jobId}?isAutoSourced=true&page=${page}&size=${pageSize}&s=${search}&sortBy=${sortBy ||
          ""}&sortOrder=${sortOrder || ""}&shortlistId=${shortlistId ||
          ""}&locationSearch=${locationSearch ||
          ""}&labelFilter=${labelFilter || ""}`,
        requestHeader()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteCandidates = createAsyncThunk(
  "candidates/deleteCandidates",
  async ({ candidateIds, jobId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        AUTO_SOURCE_CANDIDATE_DELETE + "/delete",
        { candidateIds, jobId },
        requestHeader()
      );
      return { ...response.data, candidateIds };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteAllCandidates = createAsyncThunk(
  "candidates/deleteAllCandidates",
  async ({ candidateIds }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CANDIDATE_DELETE_CANDIDATES,
        { candidateIds },
        requestHeader()
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);
export const submitCandidates = createAsyncThunk(
  "candidates/submitCandidates",
  async (
    { candidateIds, jobId, companyId, userRole, bdmId, isAutoSourced },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState();
      const userId = state.users.user._id;
      let endpoint;
      let payload;

      if (userRole === "recruiter") {
        endpoint = SUBMISSION_BY_RECRUITER;
        payload = {
          candidate_submission_by_recruiter: candidateIds.map(
            (candidateId) => ({
              opening_id: jobId,
              candidate_id: candidateId,
              recruiter_id: userId,
              bdm_id: bdmId ? bdmId : null,
              company_id: companyId ? companyId : null,
              submission_status: "submission",
            })
          ),
        };
      } else if (userRole === "bdm") {
        endpoint = SUBMISSION_BY_RECRUITER;
        payload = {
          candidate_submission_by_recruiter: candidateIds.map(
            (candidateId) => ({
              opening_id: jobId,
              recruiter_id: userId,
              candidate_id: candidateId,
              bdm_id: userId,
              company_id: companyId ? companyId : "",
              submission_status: "submit",
              candidate_select_by_bdm: 1,
            })
          ),
        };
      } else {
        return rejectWithValue({ 
          message: "You must be a recruiter or BDM to submit candidates. Current role: " + (userRole || "unknown")
        });
      }

      const response = await axios.post(
        `${endpoint}?isAutoSourced=${isAutoSourced}`,
        payload,
        requestHeader()
      );

      // Check if response exists and has data
      if (!response || !response.data) {
        return rejectWithValue({ message: "Invalid response from server" });
      }

      return {
        ...response.data,
        submittedCandidateIds: candidateIds,
        success: true
      };
    } catch (error) {
      // Handle axios error
      if (error.response?.data) {
        return rejectWithValue(error.response.data);
      }
      // Handle other errors
      return rejectWithValue({ 
        message: error.message || "Failed to submit candidates" 
      });
    }
  }
);
export const fetchLists = createAsyncThunk(
  "candidates/fetchLists",
  async ({ createdBy, jobId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_LISTS}/${createdBy}?jobId=${jobId}`,
        requestHeader()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createList = createAsyncThunk(
  "candidates/createList",
  async ({ listName, user, jobId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CREATE_LIST,
        { name: listName, user: user, jobId: jobId },
        requestHeader()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateList = createAsyncThunk(
  "candidates/updateList",
  async ({ listId, newName }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_LIST}/${listId}`,
        { name: newName },
        requestHeader()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteList = createAsyncThunk(
  "candidates/deleteList",
  async (listId, { rejectWithValue }) => {
    try {
      await axios.delete(`${DELETE_LIST}/${listId}`, requestHeader());
      return listId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCandidatesToList = createAsyncThunk(
  "candidates/addCandidatesToList",
  async ({ listId, candidateIds }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        ADD_CANDIDATES_TO_LIST,
        { listId, candidateIds },
        requestHeader()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeCandidatesFromList = createAsyncThunk(
  "candidates/removeCandidatesFromList",
  async ({ listId, candidateIds }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        REMOVE_CANDIDATES_FROM_LIST,
        { listId, candidateIds },
        requestHeader()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const selectCandidateLabels = (state) => ({
  bestCandidates: state.candidates.bestCandidates,
  goodCandidates: state.candidates.goodCandidates,
  qualifiedCandidates: state.candidates.qualifiedCandidates,
});

export const selectCandidateLabel = createSelector(
  [selectCandidateLabels, (_, candidateId) => candidateId],
  (labels, candidateId) => {
    if (labels.bestCandidates.includes(candidateId)) return "top";
    if (labels.goodCandidates.includes(candidateId)) return "good";
    if (labels.qualifiedCandidates.includes(candidateId)) return "qualified";
    return "";
  }
);
const candidateSlice = createSlice({
  name: "candidates",
  initialState: {
    list: [],
    filteredList: [],
    loading: false,
    error: null,
    page: 1,
    pageSize: 10,
    totalData: 0,
    search: "",
    currentPage: 1,
    selectedCandidateIds: [],
    listView: false,
    bestCandidates: [],
    goodCandidates: [],
    qualifiedCandidates: [],
    selectedFilters: [],
    deleteStatus: "idle",
    deleteError: null,
    shouldRefresh: false,
    sortBy: null,
    sortOrder: "asc",
    lists: [],
    currentListId: null,
    listsWithCandidates: {},
    isAddingToList: false,
    isRemovingFromList: false,
    isTogglingCandidates: false,
    isFetchingCandidates: false,
    submitError: null,
    submitting: false,
    isAutoSourced: false,
    locationSearch: "",
    labelFilter: "",
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = Number(action.payload) || 10;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSelectedCandidateIds: (state, action) => {
      state.selectedCandidateIds = action.payload;
    },
    setListView: (state, action) => {
      state.listView = action.payload;
    },
    setCandidateLabel: (state, action) => {
      const { candidateId, labelType } = action.payload;
      state[labelType].push(candidateId);
      // Update filteredList
      state.filteredList = state.filteredList.map((candidate) =>
        candidate.candidate._id === candidateId
          ? { ...candidate, label: labelType.replace("Candidates", "") }
          : candidate
      );
    },
    clearCandidateLabel: (state, action) => {
      const { candidateId, labelType } = action.payload;
      state[labelType] = state[labelType].filter((id) => id !== candidateId);
      // Update filteredList
      state.filteredList = state.filteredList.map((candidate) =>
        candidate.candidate._id === candidateId
          ? { ...candidate, label: "" }
          : candidate
      );
    },
    setShouldRefresh: (state, action) => {
      state.shouldRefresh = action.payload;
    },
    setIsAutoSourced: (state, action) => {
      state.isAutoSourced = action.payload;
    },
    setLocationSearch: (state, action) => {
      state.locationSearch = action.payload;
    },
    setLabelFilter: (state, action) => {
      state.labelFilter = action.payload;
      state.filteredList = applyFiltersToList(
        state.list,
        state.selectedFilters,
        state.currentListId,
        state,
        state.locationSearch,
        action.payload
      );
    },
    applyFilters: (state) => {
      state.filteredList = applyFiltersToList(
        state.list,
        state.selectedFilters,
        state.currentListId,
        state,
        state.locationSearch,
        state.labelFilter
      );
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },

    setCurrentListId: (state, action) => {
      state.currentListId = action.payload;
    },
    setCurrentListAndFilter: (state, action) => {
      state.currentListId = action.payload;
      if (action.payload) {
        const currentList = state.lists.find(
          (list) => list._id === action.payload
        );
        if (currentList) {
          state.filteredList = state.list.filter((candidateItem) =>
            currentList.candidateIds.includes(candidateItem.candidate._id)
          );
        } else {
          state.filteredList = [];
        }
      } else {
        state.filteredList = state.list;
      }
    },
    setFilteredList: (state, action) => {
      if (state.currentListId) {
        const currentList = state.lists.find(
          (list) => list._id === state.currentListId
        );
        if (
          currentList &&
          currentList.candidateIds &&
          currentList.candidateIds.length > 0
        ) {
          state.filteredList = state.list.filter((candidateItem) =>
            currentList.candidateIds.includes(candidateItem.candidate._id)
          );
        } else {
          // If the current list is empty or doesn't have candidateIds, set filteredList to an empty array
          state.filteredList = [];
        }
      } else {
        state.filteredList = action.payload || state.list;
      }
    },
    setCurrentListId: (state, action) => {
      state.currentListId = action.payload;
      state.filteredList = applyFiltersToList(
        state.list,
        state.selectedFilters,
        action.payload,
        state
      );
    },
    setTogglingCandidates: (state, action) => {
      state.isTogglingCandidates = action.payload;
    },
    clearCandidateLabel: (state, action) => {
      const { candidateId, labelType } = action.payload;
      state[labelType] = state[labelType].filter((id) => id !== candidateId);
      // Update filteredList
      state.filteredList = state.filteredList.map((candidate) =>
        candidate.candidate._id === candidateId
          ? { ...candidate, label: "" }
          : candidate
      );
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
      state.filteredList = applyFiltersToList(
        state.list,
        state.selectedFilters,
        state.currentListId,
        state
      );
    },
    clearFilters: (state) => {
      state.selectedFilters = [];
      state.filteredCandidates = state.allCandidates;
    },
    clearSelections: (state) => {
      state.selectedCandidateIds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidates.pending, (state) => {
        state.loading = true;
        state.isFetchingCandidates = true;
      })
      .addCase(fetchCandidates.fulfilled, (state, action) => {
        state.loading = false;
        state.isFetchingCandidates = false;
        state.list = action.payload.data;
        state.filteredList = applyFiltersToList(
          action.payload.data,
          state.selectedFilters,
          state.currentListId,
          state,
          action.meta.arg.locationSearch,
          action.meta.arg.labelFilter
        );
        state.totalData = action.payload.paginate.totalData;
        state.currentPage = action.payload.paginate.pageNo;
        state.pageSize = Number(action.payload.paginate.size) || state.pageSize;
        if (action.meta.arg.locationSearch !== undefined) {
          state.locationSearch = action.meta.arg.locationSearch;
        }
        if (action.meta.arg.labelFilter !== undefined) {
          state.labelFilter = action.meta.arg.labelFilter;
        }
        state.selectedCandidateIds = [];
      })
      .addCase(fetchCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isFetchingCandidates = false;
      })
      .addCase(submitCandidates.pending, (state) => {
        state.submitting = true;
        state.submitError = null;
      })
      .addCase(submitCandidates.fulfilled, (state, action) => {
        state.submitting = false;
        state.submitError = null;
        const submittedIds = action.payload.submittedCandidateIds;

        state.list = state.list.filter(
          (candidate) => !submittedIds.includes(candidate.candidate._id)
        );
        state.filteredList = state.filteredList.filter(
          (candidate) => !submittedIds.includes(candidate.candidate._id)
        );

        state.lists = state.lists.map((list) => ({
          ...list,
          candidateIds: list.candidateIds.filter(
            (id) => !submittedIds.includes(id)
          ),
          candidateCount: list.candidateIds.filter(
            (id) => !submittedIds.includes(id)
          ).length,
        }));

        state.selectedCandidateIds = state.selectedCandidateIds.filter(
          (id) => !submittedIds.includes(id)
        );

        state.totalData -= submittedIds.length;

        state.shouldRefresh = true;
        state.selectedCandidateIds = [];
      })
      .addCase(submitCandidates.rejected, (state, action) => {
        state.submitting = false;
        state.submitError = action.payload;
      })
      .addCase(deleteCandidates.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(deleteCandidates.fulfilled, (state, action) => {
        state.deleteStatus = "succeeded";
        if (action.payload && Array.isArray(action.payload.candidateIds)) {
          state.list = state.list.filter(
            (candidate) =>
              !action.payload.candidateIds.includes(candidate.candidate._id)
          );

          state.filteredList = applyFiltersToList(
            state.list,
            state.selectedFilters,
            state.currentListId,
            state,
            state.locationSearch,
            state.labelFilter
          );
        } else {
          console.error(
            "Invalid payload structure in deleteCandidates.fulfilled"
          );
        }
        state.selectedCandidateIds = [];
      })
      .addCase(deleteCandidates.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.deleteError = action.payload;
      })
      .addCase(fetchLists.fulfilled, (state, action) => {
        state.lists = action.payload;
      })
      .addCase(createList.fulfilled, (state, action) => {
        // state.lists.push(action.payload);
        state.lists.push({
          ...action.payload,
          candidateCount: 0,
          candidateIds: [],
        });
      })
      .addCase(updateList.fulfilled, (state, action) => {
        const index = state.lists.findIndex(
          (list) => list.id === action.payload._id
        );
        if (index !== -1) {
          state.lists[index] = action.payload;
        }
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        state.lists = state.lists.filter((list) => list._id !== action.payload);
        if (state.currentListId === action.payload) {
          state.currentListId = null;
        }
      })
      .addCase(addCandidatesToList.fulfilled, (state, action) => {
        state.isAddingToList = false;
        const { listId, candidateIds } = action.payload;
        const listIndex = state.lists.findIndex((list) => list._id === listId);
        if (listIndex !== -1) {
          state.lists[listIndex] = {
            ...state.lists[listIndex],
            candidateIds: [
              ...new Set([
                ...state.lists[listIndex].candidateIds,
                ...candidateIds,
              ]),
            ],
            candidateCount:
              state.lists[listIndex].candidateIds.length + candidateIds.length,
          };
        }

        state.lists = [...state.lists];
        if (state.currentListId === listId) {
          const newCandidates = state.list.filter((candidate) =>
            candidateIds.includes(candidate.candidate._id)
          );
          state.filteredList = [...state.filteredList, ...newCandidates];
        } else if (!state.currentListId) {
          state.filteredList = state.list;
        }
        state.selectedCandidateIds = [];
      })
      .addCase(removeCandidatesFromList.fulfilled, (state, action) => {
        state.isRemovingFromList = false;
        const { listId, candidateIds } = action.payload;
        const listIndex = state.lists.findIndex((list) => list._id === listId);
        if (listIndex !== -1) {
          state.lists[listIndex].candidateIds = state.lists[
            listIndex
          ].candidateIds.filter((id) => !candidateIds.includes(id));
          state.lists[listIndex].candidateCount =
            state.lists[listIndex].candidateIds.length;

          if (state.currentListId === listId) {
            state.filteredList = state.filteredList.filter(
              (candidate) => !candidateIds.includes(candidate.candidate._id)
            );
          }
        }
      })
      .addCase(addCandidatesToList.pending, (state) => {
        state.isAddingToList = true;
      })

      .addCase(addCandidatesToList.rejected, (state) => {
        state.isAddingToList = false;
      })
      .addCase(removeCandidatesFromList.pending, (state) => {
        state.isRemovingFromList = true;
      })

      .addCase(removeCandidatesFromList.rejected, (state) => {
        state.isRemovingFromList = false;
      });
  },
});

const applyFiltersToList = (
  list,
  selectedFilters,
  currentListId,
  state,
  locationSearch,
  labelFilter
) => {
  let filteredList = list;

  if (currentListId) {
    const currentList = state.lists.find((list) => list._id === currentListId);
    if (currentList) {
      filteredList = filteredList.filter((candidate) =>
        currentList.candidateIds.includes(candidate.candidate._id)
      );
    }
  }

  if (selectedFilters.length > 0) {
    filteredList = filteredList.filter((candidate) => {
      const candidateId = candidate.candidate._id;
      return (
        (selectedFilters.includes("top") &&
          state.bestCandidates.includes(candidateId)) ||
        (selectedFilters.includes("good") &&
          state.goodCandidates.includes(candidateId)) ||
        (selectedFilters.includes("qualified") &&
          state.qualifiedCandidates.includes(candidateId))
      );
    });
  }

  if (locationSearch) {
    filteredList = filteredList.filter((candidate) =>
      candidate.candidate.current_location
        .toLowerCase()
        .includes(locationSearch.toLowerCase())
    );
  }

  if (labelFilter) {
    filteredList = filteredList.filter((candidate) =>
      candidate.candidate.tags.includes(labelFilter)
    );
  }

  return filteredList;
};
export const {
  setPage,
  setPageSize,
  setSearch,
  setSelectedCandidateIds,
  setListView,
  setCandidateLabel,
  clearCandidateLabel,
  setSelectedFilters,
  applyFilters,
  setShouldRefresh,
  setSortBy,
  setSortOrder,
  sortCandidates,
  setCurrentListId,
  setCurrentListAndFilter,
  setFilteredList,
  setTogglingCandidates,
  // addCandidateToList,
  // removeCandidateFromList,
  setIsAutoSourced,
  clearFilters,
  setLabelFilter,
  setLocationSearch,
  clearSelections,
  labelFilter,
} = candidateSlice.actions;

export default candidateSlice.reducer;
