import React from "react";
import { Tabs } from "antd";
import CampaignsListing from "./CampaignsList";
import SourcingSetting from "./SourcingSetting";
import RecruiterCalendar from "./RecruiterCalender";

const { TabPane } = Tabs;

const Copilot = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Running Co-Pilots" key="1">
          <CampaignsListing />
        </TabPane>

        <TabPane tab="Calendar" key="2">
          <RecruiterCalendar />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Copilot;
