import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Button,
  message,
} from "antd";
import dayjs from "dayjs";
import { SEND_MEETING_INVITE } from "../../../ApiUrl";
import { useSelector } from "react-redux";

const MeetingModal = ({ visible, onClose, candidateId }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const users = useSelector(({ users }) => users);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      const formattedData = {
        candidateId,
        subject: values.subject,
        date: values.date.format("YYYY-MM-DD"),
        start: values.time.format("HH:mm"),
        end: dayjs(values.time)
          .add(1, "hour")
          .format("HH:mm"), // Assuming 1-hour duration
        message: values.notes || "", // Notes as message
      };

      setLoading(true);
      const response = await axios.post(SEND_MEETING_INVITE, formattedData, {
        headers: { Authorization: users.token },
      });

      message.success(
        response.data.message || "Meeting Scheduled Successfully!"
      );
      form.resetFields();
      onClose();
    } catch (error) {
      message.error("Failed to schedule meeting. Please try again.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Schedule a Meeting"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={loading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Send Invite Link
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Subject"
          name="subject"
          rules={[{ required: true, message: "Please enter a subject" }]}
        >
          <Input placeholder="Enter meeting subject" />
        </Form.Item>

        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Time"
          name="time"
          rules={[{ required: true, message: "Please select a time" }]}
        >
          <TimePicker style={{ width: "100%" }} use12Hours format="h:mm A" />
        </Form.Item>

        <Form.Item label="Notes" name="notes">
          <Input.TextArea placeholder="custom message..." rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MeetingModal;
