import React from "react";
import CbSearch from "../../../../CBSearch/CbSearch";

export default function CareerBuilderCandidates() {
  return (
    <>
      <CbSearch />
    </>
  );
}
