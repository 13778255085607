import React, { useEffect } from 'react';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidates, setPage, setPageSize } from '../../../../redux/reducers/candidatesSlice';

export default function JobListPagination({ jobId }) {
    const dispatch = useDispatch();
    const { 
        totalData, 
        currentPage, 
        pageSize, 
        search, 
        locationSearch, 
        labelFilter,
        sortBy,
        sortOrder,
        currentListId
    } = useSelector((state) => state.candidates);

    useEffect(() => {
        dispatch(setPage(1));
    }, [jobId, dispatch]);

    const handlePaginationChange = (page, size) => {
        dispatch(setPage(page));
        dispatch(setPageSize(size));
        dispatch(fetchCandidates({
            jobId,
            page,
            pageSize: size,
            search,
            locationSearch,
            labelFilter,
            sortBy: sortBy || '',
            sortOrder: sortOrder || '',
            shortlistId: currentListId || ''
        }));
    };

    return (
        <Pagination
            total={totalData || 0}
            pageSize={pageSize || 10}
            current={currentPage || 1}
            showSizeChanger={true}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            hideOnSinglePage={true}
            onChange={handlePaginationChange}
        />
    );
}