/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Select,
  message,
  Spin,
  Switch,
  Checkbox,
  Input,
  Skeleton,
  Tooltip,
  Typography,
  Layout,
} from "antd";
import {
  SearchOutlined,
  DownOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  FilterOutlined,
  TagOutlined,
  CompassOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import axios from "axios";
import SearchList from "./searchList";
import AdvanceSearchModal from "./advanceSearchModal";
import { CB_SEARCH } from "../../../ApiUrl";
import {
  Countries,
  searchRadiusInMilesOptions,
  stateAbbreviationToName,
} from "./Option";
import { useSelector } from "react-redux";

const { Option } = Select;
const { Content, Sider } = Layout;

const extractKeywordsFromData = (results) => {
  const isPossibleName = (str) => /^[A-Z][a-z]+ [A-Z][a-z]+$/.test(str);

  const jobTitles = results
    .flatMap((result) => result.JobTitle || [])
    .filter(Boolean);

  const filteredTitles = jobTitles.filter((title) => !isPossibleName(title));

  return Array.from(new Set(filteredTitles));
};

const formatLocationData = (data, type) => {
  if (!Array.isArray(data)) return [];

  return data.map((location) => {
    const parts = location.split(", ");
    if (type === "cities") {
      const [city, state] = parts;
      return `US-${state.split(" ")[0]}-${city}`;
    }
    if (type === "states") {
      const state = parts[0];
      return state.split(" ")[0];
    }
    return location;
  });
};

const formatLocationParameters = (cities, states, countries) => {
  const formatCity = (cityStr) => {
    if (!cityStr) return "";
    // Example format: "Atlanta, Georgia, United States" -> "Atlanta"
    return cityStr.split(",")[0].trim();
  };

  const formatState = (stateStr) => {
    if (!stateStr) return "";
    // Example format: "Georgia, United States" -> "GA"
    const stateName = stateStr.split(",")[0].trim();
    // Find the abbreviation by state name
    return (
      Object.entries(stateAbbreviationToName).find(
        ([abbr, name]) => name === stateName
      )?.[0] || ""
    );
  };

  const formatCountry = (countryStr) => {
    if (!countryStr) return "";
    // Find the country code by country name
    return (
      Object.entries(Countries).find(
        ([code, name]) => name === countryStr
      )?.[0] || ""
    );
  };

  return {
    cities: cities.map(formatCity).filter(Boolean),
    states: states.map(formatState).filter(Boolean),
    countries: countries.map(formatCountry).filter(Boolean),
  };
};

const SEARCH_PATTERNS = {
  EXACT: "Exact",
  BOO: "Boo",
  ALL: "All",
  ANY: "Any",
};

const PATTERN_DESCRIPTIONS = {
  [SEARCH_PATTERNS.ALL]: "Match all comma-separated keywords",
  [SEARCH_PATTERNS.ANY]: "Match any comma-separated keywords",
  [SEARCH_PATTERNS.EXACT]: "Match exact phrase in quotes",
  [SEARCH_PATTERNS.BOO]: "Use boolean operators (AND, OR, NOT)",
};

const BooleanSearchHelp = () => (
  <div style={{ maxWidth: "400px" }}>
    <p>
      <strong>Boolean Search Examples:</strong>
    </p>
    <ul style={{ paddingLeft: "20px", margin: "8px 0" }}>
      <li>
        <code>java AND python</code> - Must have both skills
      </li>
      <li>
        <code>developer OR engineer</code> - Either role
      </li>
      <li>
        <code>software AND NOT junior</code> - Exclude junior positions
      </li>
      <li>
        <code>program*</code> - Matches program, programmer, programming
      </li>
      <li>
        <code>"full stack"</code> - Exact phrase match
      </li>
      <li>
        <code>(java OR python) AND "machine learning"</code> - Complex
        combinations
      </li>
    </ul>
    <p>
      <small>
        Use AND, OR, NOT, *, quotes, and parentheses to build powerful searches
      </small>
    </p>
  </div>
);

const CbSearch = () => {
  const [jobSkill, setJobSkill] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const users = useSelector(({ users }) => users);

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [radius, setRadius] = useState(null);
  const [range, setRange] = useState([0, 40]);
  const [highestDegree, setHighestDegree] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [enableKeywords, setEnableKeywords] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactValue, setContactValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [advancedParams, setAdvancedParams] = useState({});
  const [sortOption, setSortOption] = useState("Relevancy");
  const [manualKeyword, setManualKeyword] = useState("");
  const [hasInitialSearch, setHasInitialSearch] = useState(false);
  const searchButtonRef = useRef(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [location, setLocation] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [excludeSkills, setExcludeSkills] = useState("");
  const [employer, setEmployer] = useState("");
  const [activeFilters, setActiveFilters] = useState({
    freshnessInDays: 365,
    experience: [0, 40],
    // salaryRange: [0, 500000],
    cities: [],
    states: [],
    countries: [],
    minimumSalary: 0,
    maximumExperience: 40,
    languagesSpoken: [],
    workStatus: [],
    employmentType: "All",
    securityClearance: "No",
    availability: "0",
    militaryExperience: "",
    minimumTravelReq: 0,
    managementExp: false,
    employeesManaged: 0,
    currentlyEmployed: false,
  });
  const [enableFilters, setEnableFilters] = useState(false);

  const { Option } = Select;

  const [resultsLoading, setResultsLoading] = useState(false);
  const [locationDataLoading, setLocationDataLoading] = useState(false);

  const currentRequestId = useRef(0);
  const abortController = useRef(null);

  const [searchPattern, setSearchPattern] = useState(SEARCH_PATTERNS.BOO);
  const [keywordInput, setKeywordInput] = useState("");

  const formatLocationForBackend = () => {
    let formattedState =
      Object.entries(stateAbbreviationToName).find(
        ([abbr, name]) => name?.toLowerCase() === state?.toLowerCase()
      )?.[0] || state;
    const formattedCountry = "US";

    return {
      city: city || "",
      state: formattedState,
      country: formattedCountry,
      zipCode,
    };
  };

  const highlightBooleanOperators = (inputValue) => {
    const input = document.createElement("div");
    let html = inputValue;
    const operators = ["AND", "OR", "NOT"];
    operators.forEach((op) => {
      html = html.replace(
        new RegExp(`\\b${op}\\b`, "g"),
        `<span style="font-style: italic; opacity: 0.7">${op}</span>`
      );
    });
    return html;
  };

  const handleSearchWithPageSize = async (page = 1, sortingOption = sortOption, immediatePageSize) => {
    // Input validation
    if (!keywordInput.trim() && !jobSkill.trim()) {
      message.warning("Please enter keywords or job title to search");
      return;
    }

    // Handle request cancellation
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    const requestId = ++currentRequestId.current;

    // Set loading states
    if (!hasInitialSearch) {
      setResultsLoading(true);
      setLocationDataLoading(true);
    }

    try {
      setResultsLoading(true);

      // Process keywords
      let finalPattern = SEARCH_PATTERNS.BOO;
      let finalKeywords = jobSkill;

      if (enableKeywords || keywordInput || excludeSkills) {
        const selectedKeywordsString =
          selectedKeywords.length > 0
            ? `(${selectedKeywords.join(" AND ")})`
            : "";

        const customKeywords = keywordInput.trim() ? `(${keywordInput})` : "";

        const excludedSkillsArray = excludeSkills
          .split(",")
          .map((skill) => skill.trim())
          .filter(Boolean);

        const excludedSkillsString =
          excludedSkillsArray.length > 0
            ? excludedSkillsArray.map((skill) => `NOT ${skill}`).join(" AND ")
            : "";

        const keywordParts = [
          selectedKeywordsString,
          customKeywords,
          excludedSkillsString,
        ].filter(Boolean);

        finalKeywords = keywordParts.join(" AND ");
        finalPattern = searchPattern || SEARCH_PATTERNS.BOO;
      }

      // Get current filters and ensure they're serializable
      const currentFilters = { ...activeFilters };
      const salaryRange = currentFilters.salaryRange?.range ||
        currentFilters.salaryRange || [0, 300000];
      const [minimumSalary, maximumSalary] = Array.isArray(salaryRange)
        ? salaryRange
        : [0, 300000];

      const { cities, states, countries } = formatLocationParameters(
        currentFilters.cities || [],
        currentFilters.states || [],
        currentFilters.countries || []
      );

      const experienceRange = currentFilters.experience || [0, 0];

      // Use the immediate page size if provided, otherwise use state
      const effectivePageSize = immediatePageSize || rowsPerPage;
      console.log('Using page size:', effectivePageSize);

      const searchParams = {
        jobTitle: String(jobSkill || ""),
        city: String(city || ""),
        state: String(state || ""),
        zipCode: String(zipCode || ""),
        company: String(employer || ""),
        searchByName: Boolean(searchByName),
        radius: radius?.value ? String(radius.value) : "",
        minimumExperience: experienceRange[0] ? String(experienceRange[0]) : "",
        keywords: String(finalKeywords || ""),
        pageNumber: Number(page),
        rowsPerPage: Number(effectivePageSize),
        sortOption: String(sortingOption || ""),
        freshnessInDays: Number(currentFilters.freshnessInDays) || 0,
        cities: cities.map(String),
        states: states.map(String),
        countries: countries.map(String),
        searchPattern: String(finalPattern || ""),
        ...Object.fromEntries(
          Object.entries(advancedParams || {}).map(([key, value]) => [
            key,
            typeof value === "object" ? JSON.stringify(value) : String(value),
          ])
        ),
      };

      // Make the API request with sanitized data
      const response = await axios.post(
        `${CB_SEARCH}/cbSearch-candidate/list`,
        { searchParams },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: users.token,
          },
          signal: abortController.current.signal,
        }
      );

      // Check if request is still current
      if (requestId !== currentRequestId.current) {
        return;
      }

      // Handle response
      if (response.data?.data) {
        const { results: searchResults, pagination } = response.data.data;

        setResults(searchResults || []);
        setCurrentPage(Number(pagination?.currentPage) || 1);
        setTotalPages(Number(pagination?.totalPages) || 0);
        setTotalResults(Number(pagination?.totalResults) || 0);
        setRowsPerPage(Number(pagination?.rowsPerPage) || effectivePageSize);

        if (searchResults?.length > 0) {
          setHasInitialSearch(true);
          setEnableFilters(true);
          const keywords = extractKeywordsFromData(searchResults);
          setKeywordOptions(keywords);
          message.success("Search completed successfully");
        } else {
          message.warning("No results found");
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Search error:", error);
        message.error("An error occurred during the search");
        setResultsLoading(false);
        setLocationDataLoading(false);
      }
    } finally {
      if (requestId === currentRequestId.current) {
        setResultsLoading(false);
        setLoading(false);
        setLocationDataLoading(false);
      }
    }
  };

  const handleSearch = (page = 1, sortingOption = sortOption) => {
    return handleSearchWithPageSize(page, sortingOption);
  };

  const handleFilterChange = useCallback(
    (filterType, value) => {
      if (!hasInitialSearch) return;

      // console.log('Filter change in CbSearch:', filterType, value, typeof value); // Debug log

      // Update active filters
      setActiveFilters((prev) => {
        const newFilters = {
          ...prev,
          [filterType]:
            filterType === "freshnessInDays" ? parseInt(value, 10) : value,
        };
        // console.log('Updated filters:', newFilters); // Debug log
        return newFilters;
      });

      // Trigger search with updated filters immediately
      handleSearch(1, sortOption);
    },
    [hasInitialSearch, sortOption]
  );

  const handleResetFilters = useCallback(() => {
    // Reset active filters to default values
    const defaultFilters = {
      freshnessInDays: 365,
      // experience: [0, 20],
      // salaryRange: [0, 300000],
      cities: [],
      states: [],
      countries: [],
      // Add all other filter fields with default values
      minimumSalary: 0,
      // maximumSalary: 0,
      minimumExperience: 0,
      maximumExperience: 40,
      languagesSpoken: [],
      workStatus: [],
      employmentType: "All",
      securityClearance: "No",
      availability: "0",
      militaryExperience: "",
      minimumTravelReq: 0,
      managementExp: false,
      employeesManaged: 0,
      currentlyEmployed: false,
    };

    setActiveFilters(defaultFilters);

    // Only show the success message if we're not in the initial render
    if (hasInitialSearch) {
      message.success("Filters have been reset");
    }

    // Force a new search with default filters
    const searchParams = {
      jobTitle: jobSkill,
      location,
      radius,
      pageNumber: 1,
      rowsPerPage,
      sortOption,
      ...defaultFilters,
    };

    // Clear any pending requests
    if (abortController.current) {
      abortController.current.abort();
    }

    // Make immediate search call with reset filters
    axios
      .post(
        `${CB_SEARCH}/cbSearch-candidate/list`,
        { searchParams },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: users.token,
          },
        }
      )
      .then((response) => {
        if (response.data?.data) {
          const { results: searchResults, pagination } = response.data.data;
          setResults(searchResults || []);
          setCurrentPage(1);
          setTotalPages(parseInt(pagination?.totalPages || 0));
          setTotalResults(parseInt(pagination?.totalResults || 0));
          message.success("Filters have been reset");
        }
      })
      .catch((error) => {
        console.error("Error resetting filters:", error);
        message.error("Failed to reset filters");
      });
  }, [
    jobSkill,
    location,
    radius,
    keywordInput,
    rowsPerPage,
    sortOption,
    users.token,
  ]);

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  const onSortChange = (value) => {
    setSortOption(value);
    handleSearch(1, value);
  };

  useEffect(() => {
    setIsButtonDisabled(!(jobSkill.length > 0 || keywordInput.length > 0));
  }, [jobSkill, keywordInput]);

  useEffect(() => {
    if (enableKeywords && selectedKeywords.length === 0) {
      setEnableKeywords(false);
    }
  }, [selectedKeywords]);
  const handleMenuClick = (e) => {
    const keyword = e.key;
    setSelectedKeywords((prevSelected) =>
      prevSelected.includes(keyword)
        ? prevSelected.filter((item) => item !== keyword)
        : [...prevSelected, keyword]
    );
  };

  const items = keywordOptions.map((keyword) => ({
    key: keyword,
    label: (
      <Checkbox
        checked={selectedKeywords.includes(keyword)}
        onChange={() => handleMenuClick({ key: keyword })}
      >
        {keyword}
      </Checkbox>
    ),
  }));

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchButtonRef.current && !isButtonDisabled) {
      searchButtonRef.current.click();
    }
  };
  const handlePageChange = (page, pageSize) => {
    if (pageSize !== rowsPerPage) {
      handleSearchWithPageSize(1, sortOption, pageSize);
      setRowsPerPage(pageSize);
      setCurrentPage(1);
    } else {
      setCurrentPage(page);
      handleSearch(page, sortOption);
    }
  };

  const handleAdvancedSearch = (params) => {
    setAdvancedParams(params);
    closeModal();
  };
  useEffect(() => {
    if (Object.keys(advancedParams).length > 0) {
      // console.log('Performing advanced search...');
      handleSearch();
    }
  }, [advancedParams]);

  const handleKeywordSelect = (values) => {
    const lastValue = values[values.length - 1];
    if (lastValue && values.filter((v) => v === lastValue).length > 1) {
      message.warning("This keyword has already been selected");
      values = values.filter(
        (v, i) => v !== lastValue || values.indexOf(v) === i
      );
    }
    setSelectedKeywords(values);
  };

  const handleManualKeywordChange = (e) => {
    const value = e.target.value;
    if (selectedKeywords.includes(value)) {
      message.warning("This keyword has already been selected");
      return;
    }
    setManualKeyword(value);
  };

  const KeywordSearchHelp = ({ pattern }) => (
    <div>
      <p>
        <strong>{PATTERN_DESCRIPTIONS[pattern]}</strong>
      </p>
      {pattern === SEARCH_PATTERNS.BOO && (
        <ul style={{ marginBottom: 0 }}>
          <li>Use AND for multiple requirements</li>
          <li>Use OR for alternatives</li>
          <li>Use NOT to exclude terms</li>
          <li>Use * for partial matches (e.g., program*)</li>
          <li>Use quotes for exact phrases</li>
          <li>Use parentheses for grouping</li>
        </ul>
      )}
    </div>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={400}
        theme="light"
        style={{
          overflowY: "auto",
          borderRight: "1px solid #f0f0f0",
        }}
      >
        <Card
          bordered={false}
          style={{
            padding: 0,
            boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
          }}
        >
          {/* Keyword Search Section */}
          <div style={{ marginBottom: "16px" }}>
            <Typography.Title level={5}>
              <FilterOutlined /> Keyword Search
            </Typography.Title>
            <div style={{ position: "relative" }}>
              <Input
                prefix={<TagOutlined />}
                value={keywordInput}
                onChange={(e) => setKeywordInput(e.target.value)}
                placeholder="Java AND Python OR C++"
                onKeyDown={handleKeyDown}
                className="keyword-input"
                // status={!keywordInput.trim() && !jobSkill.trim() ? "error" : ""}
              />
              <Tooltip
                title={<BooleanSearchHelp />}
                placement="right"
                style={{
                  maxWidth: "auto",
                }}
              >
                <InfoCircleOutlined
                  style={{
                    position: "absolute",
                    right: "8px",
                    top: "8px",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                />
              </Tooltip>
            </div>
          </div>

          {/* Location Search Section */}
          <div style={{ marginBottom: "16px" }}>
            <Typography.Title level={5}>
              <CompassOutlined /> Location
            </Typography.Title>
            <Select
              prefix={<CompassOutlined />}
              value={state}
              onChange={(value) => setState(value)}
              placeholder="Select a state"
              style={{ width: "100%", marginBottom: "12px" }}
              allowClear
            >
              {Object.entries(stateAbbreviationToName).map(
                ([abbreviation, stateName]) => (
                  <Option key={abbreviation} value={abbreviation}>
                    {stateName}
                  </Option>
                )
              )}
            </Select>
            <Input
              prefix={<PushpinOutlined />}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="City"
              style={{ marginBottom: "12px" }}
            />

            <Input
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              placeholder="Zip Code"
            />
          </div>

          {/* Search Button */}
          <Button
            onClick={handleSearch}
            type="primary"
            icon={<SearchOutlined />}
            block
            style={{ marginBottom: "16px" }}
          >
            Search Jobs
          </Button>

          {/* Advanced Options Toggle */}
          <Typography.Link
            onClick={() => setShowAdvanced(!showAdvanced)}
            style={{ display: "block", textAlign: "center" }}
          >
            {showAdvanced ? "Hide" : "Show"} Advanced Options <DownOutlined />
          </Typography.Link>

          {/* Advanced Options */}
          {showAdvanced && (
            <div style={{ marginTop: "16px" }}>
              <Typography.Title level={5}>Advanced Filters</Typography.Title>
              <Input
                value={excludeSkills}
                onChange={(e) => setExcludeSkills(e.target.value)}
                placeholder="Exclude Skills"
                style={{ marginBottom: "12px" }}
              />
              <Input
                value={jobSkill}
                onChange={(e) => setJobSkill(e.target.value)}
                placeholder="Job Title"
                style={{ marginBottom: "12px" }}
              />
              <Input
                value={employer}
                onChange={(e) => setEmployer(e.target.value)}
                placeholder="Search by Employer"
                style={{ marginBottom: "12px" }}
              />
              <Select
                value={radius}
                placeholder="Location Radius"
                onChange={setRadius}
                style={{ width: "100%" }}
                allowClear
                options={searchRadiusInMilesOptions}
              />
            </div>
          )}
        </Card>
      </Sider>

      <Content style={{ paddingLeft: "20px" }}>
        <Card>
          <Row gutter={4}>
            <Col span={24}>
              {resultsLoading ? (
                <Spin tip="Loading...">
                  <Skeleton active />
                </Spin>
              ) : !hasInitialSearch ? (
                <h6 style={{ marginLeft: "20px" }}>
                  Please enter a job title and click search to see results.
                </h6>
              ) : results.length > 0 ? (
                <SearchList
                  results={results}
                  loading={loading}
                  jobTitle={jobSkill}
                  range={range}
                  setRange={setRange}
                  highestDegree={highestDegree}
                  setHighestDegree={setHighestDegree}
                  radius={radius}
                  location={location}
                  contactValue={contactValue}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalResults={totalResults}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  sortOption={sortOption}
                  onSortChange={onSortChange}
                  onFilterChange={handleFilterChange}
                  enableFilters={enableFilters}
                  hasInitialSearch={hasInitialSearch}
                  activeFilters={activeFilters}
                  resultsLoading={resultsLoading}
                  locationDataLoading={locationDataLoading}
                  onResetFilters={handleResetFilters}
                />
              ) : (
                <h6 style={{ marginLeft: "20px" }}>No results found.</h6>
              )}
            </Col>
          </Row>
        </Card>
      </Content>
    </Layout>
  );
};

export default CbSearch;
