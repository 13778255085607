import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, Input, Row, Spin, message, Typography, Tooltip, Modal, Drawer, Space } from 'antd';
import { EditOutlined, HistoryOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { 
  GENERATE_BOOLEAN_STRING, 
  GET_BOOLEAN_STRING, 
  BOOLEAN_STRING_VERSIONS,
  BOOLEAN_STRING_DELETE,
  BOOLEAN_STRING_UPDATE,
  BOOLEAN_STRING_SAVE,
  BOOLEAN_STRING_UPDATE_STATUS
} from '../../../ApiUrl';
import { requestHeader } from '../../../others/header';

const { TextArea } = Input;
const { Text, Paragraph } = Typography;

export default function BooleanString({
  jobId,
  title,
  description,
  required_skills,
  city, 
  state,
  country,
  category,
  onBooleanStringChange, // New prop for callback
}) {
  const [loading, setLoading] = useState(false);
  const [booleanData, setBooleanData] = useState(null);
  const [isPromptModalVisible, setIsPromptModalVisible] = useState(false);
  const [isEditStringModalVisible, setIsEditStringModalVisible] = useState(false);
  const [isHistoryDrawerVisible, setIsHistoryDrawerVisible] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [currentEditingString, setCurrentEditingString] = useState({ key: '', value: '' });
  const [versions, setVersions] = useState([]);
  const [regenerateCount, setRegenerateCount] = useState(0);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [deletingVersionId, setDeletingVersionId] = useState(null);
// console.log('location=>', city, state, country)
  const fetchExistingBooleanString = useCallback(async () => {
    if (!jobId) {
      console.error('JobId is undefined');
      message.error('Failed to fetch boolean string: JobId is undefined');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(`${GET_BOOLEAN_STRING}/${jobId}`, requestHeader());
      // console.log('Fetched boolean string data:', response.data);
      if (response.data?.data?.found) {
        setBooleanData(response.data.data.data);
        setCurrentPrompt(response.data.data.data.prompt || '');
      } else {
        setBooleanData(null);
      }
    } catch (error) {
      console.error('Error fetching boolean string:', error);
      message.error('Failed to fetch boolean string');
      setBooleanData(null);
    } finally {
      setLoading(false);
    }
  }, [jobId]);

  useEffect(() => {
    if (jobId) {
      fetchExistingBooleanString();
    }
  }, [jobId, fetchExistingBooleanString]);

  // Modified to work without jobId for generation
  const generateBooleanString = async (customPrompt = null, isRegenerate = false) => {
    if (!description) {
      message.warning('Please enter a job description before generating boolean strings');
      return;
    }

    setLoading(true);
    try {
      const payload = {
        job_title: title,
        job_description: description,
        city,
        state,
        country,
        required_skills,
        category,
        prompt: customPrompt || currentPrompt,
        isRegenerate,
        previousStrings: booleanData ? [
          booleanData.current_boolean_string.boolean_string_1,
          booleanData.current_boolean_string.boolean_string_2,
          booleanData.current_boolean_string.boolean_string_3
        ] : []
      };

      // If jobId exists, include it in payload
      if (jobId) {
        payload.jobId = jobId;
      }
      console.log('payload data->', payload)
      const response = await axios.post(GENERATE_BOOLEAN_STRING, payload, requestHeader());
      setBooleanData(response.data.data);
      setCurrentPrompt(response.data.data.prompt || '');
      setUnsavedChanges(true);

      // Notify parent component of changes
      if (onBooleanStringChange) {
        onBooleanStringChange(response.data.data);
      }

      message.success('Boolean string generated successfully');
    } catch (error) {
      console.error('Error generating boolean string:', error);
      message.error('Failed to generate boolean string');
    } finally {
      setLoading(false);
    }
  };

  // Modified to handle both scenarios
  const saveBooleanString = async (singleString = null) => {
    if (!jobId) {
      // For new openings, just update local state and notify parent
      const updatedData = singleString 
        ? { ...booleanData, current_boolean_string: { ...booleanData.current_boolean_string, ...singleString } }
        : booleanData;
      setBooleanData(updatedData);
      if (onBooleanStringChange) {
        onBooleanStringChange(updatedData);
      }
      setUnsavedChanges(false);
      return;
    }

    // Existing save logic for openings with jobId
    setLoading(true);
    try {
      const dataToSave = singleString 
        ? { ...booleanData, current_boolean_string: { ...booleanData.current_boolean_string, ...singleString } }
        : booleanData;
      
      const response = await axios.post(`${BOOLEAN_STRING_SAVE}/${jobId}`, dataToSave, requestHeader());
      console.log('Saved boolean string data:', response.data);
      setBooleanData(response.data.data);
      setUnsavedChanges(false);
      message.success('Boolean string saved successfully');
      fetchVersions();
    } catch (error) {
      console.error('Error saving boolean string:', error);
      message.error('Failed to save boolean string');
    } finally {
      setLoading(false);
    }
  };

  const updateBooleanString = async (updatedData) => {
    if (!jobId) {
      console.error('JobId is undefined');
      message.error('Failed to update boolean string: JobId is undefined');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.patch(`${BOOLEAN_STRING_UPDATE}/${jobId}`, updatedData, requestHeader());
      console.log('Updated boolean string data:', response.data);
      setBooleanData(response.data.data);
      setUnsavedChanges(false);
      message.success('Boolean string updated successfully');
      fetchVersions();
    } catch (error) {
      console.error('Error updating boolean string:', error);
      message.error('Failed to update boolean string');
    } finally {
      setLoading(false);
    }
  };

  const fetchVersions = async () => {
    if (!jobId) {
      console.error('JobId is undefined');
      message.error('Failed to fetch versions: JobId is undefined');
      return;
    }
    try {
      const response = await axios.get(`${BOOLEAN_STRING_VERSIONS}/${jobId}`, requestHeader());
     // console.log('Fetched versions:', response.data);
      setVersions(response.data.data || []);
    } catch (error) {
      console.error('Error fetching versions:', error);
      message.error('Failed to fetch versions');
    }
  };

  const handleRegenerate = () => {
    if (regenerateCount >= 10) {
      message.warning('You can only regenerate 10 times');
      return;
    }
    setRegenerateCount(prev => prev + 1);
    generateBooleanString(null, true); // Ensure isRegenerate is set to true
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => message.success('Copied to clipboard'))
      .catch(() => message.error('Failed to copy'));
  };

  const handlePromptEdit = async () => {
    setIsPromptModalVisible(false);
    setLoading(true);
    try {
      await generateBooleanString(currentPrompt, true); // Ensure isRegenerate is set to true
    } catch (error) {
      console.error('Error regenerating boolean string:', error);
      message.error('Failed to regenerate boolean string');
    } finally {
      setLoading(false);
    }
  };

  const handleStringEdit = () => {
    const updatedBooleanData = {
      ...booleanData,
      current_boolean_string: {
        ...booleanData.current_boolean_string,
        [currentEditingString.key]: currentEditingString.value
      }
    };
    setBooleanData(updatedBooleanData);
    setUnsavedChanges(true);
    setIsEditStringModalVisible(false);
  };

  const updateVersionStatus = async (versionId, newStatus) => {
    try {
      await axios.patch(`${BOOLEAN_STRING_UPDATE_STATUS}/${versionId}`, { status: newStatus }, requestHeader());
      fetchVersions();
      message.success('Version status updated successfully');
    } catch (error) {
      console.error('Error updating version status:', error);
      message.error('Failed to update version status');
    }
  };

  const deleteVersion = async (versionId) => {
    try {
      setDeletingVersionId(versionId); // Set loading state for specific version
      const response = await axios.delete(`${BOOLEAN_STRING_DELETE}/${versionId}`, requestHeader());
      if (response.data.deletedId) {
        // Immediately update the local state
        setVersions(prevVersions => prevVersions.filter(v => v._id !== versionId));
        message.success('Version deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting version:', error);
      message.error('Failed to delete version');
    } finally {
      setDeletingVersionId(null); // Clear loading state
    }
  };

  const renderBooleanStrings = () => {
    if (!booleanData || !booleanData.current_boolean_string) {
      console.log('No boolean data to render');
      return null;
    }
    const currentVersion = booleanData.current_boolean_string;
    
    return ["boolean_string_1", "boolean_string_2", "boolean_string_3"].map((key) => (
      <div key={key} style={{ marginBottom: 16, padding: 16, border: '1px solid #f0f0f0', borderRadius: 4 }}>
        <Row justify="space-between" align="top" gutter={4}>
          <Col span={jobId ? 20 : 24}>
            <Paragraph copyable>{currentVersion[key]}</Paragraph>
          </Col>
          {jobId && ( // Only show edit/save buttons if jobId exists
            <Col span={4}>
              <Tooltip title="Edit">
                <Button 
                  icon={<EditOutlined />} 
                  onClick={() => {
                    setCurrentEditingString({ key, value: currentVersion[key] });
                    setIsEditStringModalVisible(true);
                  }} 
                />
              </Tooltip>
              <Tooltip title="Save">
                <Button 
                  icon={<SaveOutlined />} 
                  onClick={() => saveBooleanString({ [key]: currentVersion[key] })} 
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      </div>
    ));
  };

  return (
    <Card 
      title="Boolean String" 
      extra={jobId && ( // Only show history button if jobId exists
        <Button 
          icon={<HistoryOutlined />} 
          onClick={() => {
            setIsHistoryDrawerVisible(true);
            fetchVersions();
          }}
        >
        </Button>
      )}
    >
      {loading && <Spin />}
      {!booleanData?.current_boolean_string ? (
        <Row justify="center">
          <Button 
            type="primary" 
            onClick={() => generateBooleanString()}
            disabled={!description} // Disable if no description
          >
            Generate Boolean String
          </Button>
          {!description && (
            <div style={{ marginTop: 8, color: '#ff4d4f' }}>
              Please enter a job description first
            </div>
          )}
        </Row>
      ) : (
        <>
          {renderBooleanStrings()}
          <Row justify="space-between" style={{ marginTop: 16 }}>
            <Button onClick={() => setIsPromptModalVisible(true)}>
              View/Edit Prompt
            </Button>
            <Button 
              onClick={handleRegenerate}
              loading={loading}
              disabled={loading || regenerateCount >= 10}
            >
              Regenerate
            </Button>
            {jobId && ( // Only show save all button if jobId exists
              <Button 
                type="primary" 
                icon={<SaveOutlined />} 
                onClick={() => saveBooleanString()}
                disabled={!unsavedChanges}
              >
                Save All Changes
              </Button>
            )}
          </Row>
        </>
      )}

      <Modal
        title="Edit Boolean String Prompt"
        open={isPromptModalVisible}
        onOk={handlePromptEdit}
        onCancel={() => setIsPromptModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsPromptModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="regenerate" loading={loading}  type="primary" onClick={handlePromptEdit}>
            Regenerate
          </Button>,
        ]}
      >
        <TextArea
          value={currentPrompt}
          onChange={(e) => setCurrentPrompt(e.target.value)}
          rows={6}
          placeholder="Enter your custom prompt..."
        />
      </Modal>

      <Modal
        title="Edit Boolean String"
        open={isEditStringModalVisible}
        onOk={handleStringEdit}
        onCancel={() => setIsEditStringModalVisible(false)}
      >
        <TextArea
          value={currentEditingString.value}
          onChange={(e) => setCurrentEditingString({ ...currentEditingString, value: e.target.value })}
          rows={6}
          placeholder="Edit the boolean string..."
        />
      </Modal>

      <Drawer
        title="Boolean String History"
        placement="right"
        width={600}
        onClose={() => setIsHistoryDrawerVisible(false)}
        open={isHistoryDrawerVisible}
      >
        {versions.map((version, index) => (
          <Card 
            key={version._id} 
            style={{ marginBottom: 16 }}
            loading={deletingVersionId === version._id}
          >
            <Text strong>Version {versions.length - index}</Text>
            <Paragraph type="secondary">
              {new Date(version.created_at).toLocaleString()}
            </Paragraph>
            <Paragraph strong>Status: {version.status}</Paragraph>
            <Paragraph strong>Prompt:</Paragraph>
            <Paragraph>{version.prompt}</Paragraph>
            {["boolean_string_1", "boolean_string_2", "boolean_string_3"].map((key) => (
              <div key={key}>
                <Text strong>String {key.split('_')[2]}:</Text>
                <Paragraph copyable>{version[key]}</Paragraph>
              </div>
            ))}
            <Space>
              <Button 
                type="primary" 
                size="small"
                onClick={() => {
                  updateBooleanString({
                    boolean_string_1: version.boolean_string_1,
                    boolean_string_2: version.boolean_string_2,
                    boolean_string_3: version.boolean_string_3,
                    prompt: version.prompt,
                  });
                  updateVersionStatus(version._id, 'used');
                  setIsHistoryDrawerVisible(false);
                }}
              >
                Use This Version
              </Button>
              <Button 
                size="small"
                onClick={() => updateVersionStatus(version._id, version.status === 'archived' ? 'saved' : 'archived')}
              >
                {version.status === 'archived' ? 'Unarchive' : 'Archive'}
              </Button>
              <Button 
                danger
                size="small"
                icon={<DeleteOutlined />}
                loading={deletingVersionId === version._id}
                disabled={deletingVersionId === version._id}
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete this version?',
                    content: 'This action cannot be undone.',
                    onOk: () => deleteVersion(version._id),
                    okButtonProps: { loading: deletingVersionId === version._id }
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          </Card>
        ))}
      </Drawer>
    </Card>
  );
}

