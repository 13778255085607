/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Badge } from "antd";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { MESSAGE_COUNT } from "../../../../../ApiUrl";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutHandler } from "../../../../../others/logout";
const getMenuItemActive = (url, hasSubmenu = false) => {
  return checkIsActive(location, url)
    ? ` ${!hasSubmenu &&
        "menu-item-active"} menu-item-open menu-item-not-hightlighted`
    : "";
};
const MenuItem = ({ url, icon, text, count, onClick }) => (
  <li
    className={`menu-item ${getMenuItemActive(url, false)}`}
    aria-haspopup="true"
  >
    <NavLink onClick={onClick} className="menu-link" to={url}>
      <span className="svg-icon menu-icon">
        <img src={toAbsoluteUrl(icon)} width="20px" />
      </span>
      <span className="menu-text">{text}</span>
      {count !== undefined && <Badge count={count} offset={[5, 5]} />}
    </NavLink>
  </li>
);

export function AsideMenuList({ layoutProps }) {
  const users = useSelector(({ users }) => users);
  const role = users.role;
  const history = useHistory();
  const [count, setCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    getMessageCount();
  }, []);

  const getMessageCount = () => {
    const userID = ["admin", "bdm", "recruiter", "freelancerecruiter"].includes(
      role
    )
      ? users.user._id
      : "";

    const param = {
      company_id: role === "company" ? users.user._id : "",
      candidate_id: role === "candidate" ? users.user._id : "",
      user_id: userID,
      contact_id: "",
    };

    axios
      .post(MESSAGE_COUNT, param, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        if (!res.data.error) {
          setCount(res.data.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          logoutHandler(history);
        }
      });
  };

  const renderMenuItems = (items) => {
    return items.map((item) => (
      <MenuItem
        key={item.url}
        url={item.url}
        icon={item.icon}
        text={item.text}
        count={item.count}
        onClick={getMessageCount}
      />
    ));
  };

  const menuItems = {
    company: [
      {
        url: "/dashboard",
        icon: "/media/svg/icons/Extra/dashboard-v2.png",
        text: "Dashboard",
      },
      {
        url: "/company/job-openings",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Job Openings",
      },
      {
        url: "/company/candidates",
        icon: "/media/svg/icons/Extra/candidates.png",
        text: "Candidates",
      },
      {
        url: "/company/messages",
        icon: "/media/svg/icons/Extra/email-v2.png",
        text: "Messages",
        count,
      },
      {
        url: "/company/reports",
        icon: "/media/svg/icons/Extra/report-v2.png",
        text: "Reports",
      },
      {
        url: "/company/contactActivityLogList",
        icon: "/media/svg/icons/Extra/edit.png",
        text: "Contact Activity",
      },
    ],
    bdm: [
      {
        url: "/dashboard",
        icon: "/media/svg/icons/Extra/dashboard-v2.png",
        text: "Dashboard",
      },
      {
        url: "/bdm/job-openings",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Job Openings",
      },
      {
        url: "/bdm/assign-jobs",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Assign Jobs",
      },
      {
        url: "/bdm/workJobsList",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Freelance work Jobs",
      },
      {
        url: "/bdm/CandidateList",
        icon: "/media/svg/icons/Extra/candidates.png",
        text: "Candidates",
      },
      {
        url: "/bdm/ClientsList",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Clients",
      },
      {
        url: "/bdm/messages",
        icon: "/media/svg/icons/Extra/email-v2.png",
        text: "Messages",
        count,
      },
      {
        url: "/bdm/reports",
        icon: "/media/svg/icons/Extra/report-v2.png",
        text: "Reports",
      },
      // {
      //   url: "/bdm/monsterSearch",
      //   icon: "/media/svg/icons/Extra/monster-search.png",
      //   text: "Monster Search",
      // },
      // {
      //   url: "/bdm/cbSearch",
      //   icon: "/media/svg/icons/Extra/report.png",
      //   text: "CB Search",
      // },
      // {
      //   url: "/bdm/autosource",
      //   icon: "/media/svg/icons/Extra/auto-source.png",
      //   text: "Auto Source",
      // },
    ],
    recruiter: [
      {
        url: "/dashboard",
        icon: "/media/svg/icons/Extra/dashboard-v2.png",
        text: "Dashboard",
      },
      {
        url: "/recruiter/assign-jobs",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Assign Jobs",
      },
      {
        url: "/recruiter/candidateApplyJobs",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Candidate Apply Jobs",
      },
      {
        url: "/recruiter/CandidateList",
        icon: "/media/svg/icons/Extra/candidates.png",
        text: "Candidates",
      },
      {
        url: "/recruiter/copilot",
        icon: "/media/svg/icons/Extra/ai.png",
        text: "Copilot",
      },
      {
        url: "/recruiter/messages",
        icon: "/media/svg/icons/Extra/email-v2.png",
        text: "Messages",
        count,
      },
      {
        url: "/recruiter/reports",
        icon: "/media/svg/icons/Extra/report-v2.png",
        text: "Reports",
      },
      // {
      //   url: "/recruiter/monsterSearch",
      //   icon: "/media/svg/icons/Extra/monster-search.png",
      //   text: "Monster Search",
      // },
      // {
      //   url: "/recruiter/cbSearch",
      //   icon: "/media/svg/icons/Extra/report.png",
      //   text: "CB Search",
      // },
      // {
      //   url: "/recruiter/autosource",
      //   icon: "/media/svg/icons/Extra/auto-source.png",
      //   text: "Auto Source",
      // },
    ],
    freelancerecruiter: [
      {
        url: "/dashboard",
        icon: "/media/svg/icons/Extra/dashboard-v2.png",
        text: "Dashboard",
      },
      {
        url: "/freelancerecruiter/all-jobs",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "All Jobs",
      },
      {
        url: "/freelancerecruiter/assign-jobs",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Assign Jobs",
      },
      {
        url: "/freelancerecruiter/CandidateList",
        icon: "/media/svg/icons/Extra/candidates.png",
        text: "Candidates",
      },
      {
        url: "/freelancerecruiter/addCandidate",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Add Candidate",
      },
      {
        url: "/freelancerecruiter/messages",
        icon: "/media/svg/icons/Extra/email-v2.png",
        text: "Messages",
        count,
      },
      {
        url: "/freelancerecruiter/reports",
        icon: "/media/svg/icons/Extra/report-v2.png",
        text: "Reports",
      },
      // {
      //   url: "/freelancerecruiter/monsterSearch",
      //   icon: "/media/svg/icons/Extra/monster-search.png",
      //   text: "Monster Search",
      // },
      // {
      //   url: "/freelancerecruiter/cbSearch",
      //   icon: "/media/svg/icons/Extra/report.png",
      //   text: "CB Search",
      // },
      // {
      //   url: "/freelancerecruiter/autosource",
      //   icon: "/media/svg/icons/Extra/auto-source.png",
      //   text: "Auto Source",
      // },
    ],
    admin: [
      {
        url: "/dashboard",
        icon: "/media/svg/icons/Extra/dashboard-v2.png",
        text: "Dashboard",
      },
      {
        url: "/current-openings",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Current Openings",
      },
      {
        url: "/user/list/all",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Users",
      },
      {
        url: "/admin/candidateList",
        icon: "/media/svg/icons/Extra/candidates.png",
        text: "Candidates",
      },
      {
        url: "/admin/ClientsList",
        icon: "/media/svg/icons/Extra/clients.png",
        text: "Clients",
      },
      {
        url: "/admin/copilot",
        icon: "/media/svg/icons/Extra/ai.png",
        text: "Copilot",
      },
      // {
      //   url: "/admin/AiRecruiter",
      //   icon: "/media/svg/icons/Extra/ai-recruiter.png",
      //   text: "AI Recruiter",
      // },
      {
        url: "/admin/subscription",
        icon: "/media/svg/icons/Extra/subscription.png",
        text: "Subscriptions",
      },
      {
        url: "/admin/messages",
        icon: "/media/svg/icons/Extra/email-v2.png",
        text: "Messages",
        count,
      },
      {
        url: "/admin/reports",
        icon: "/media/svg/icons/Extra/report-v2.png",
        text: "Reports",
      },
      // {
      //   url: "/admin/monsterSearch",
      //   icon: "/media/svg/icons/Extra/monster-search.png",
      //   text: "Monster Search",
      // },
      // {
      //   url: "/admin/cbSearch",
      //   icon: "/media/svg/icons/Extra/report.png",
      //   text: "CB Search",
      // },
      // {
      //   url: "/admin/autosource",
      //   icon: "/media/svg/icons/Extra/auto-source.png",
      //   text: "Auto Source",
      // },
      {
        icon: "/media/svg/icons/Extra/configuration.png",
        text: "Configuration",
        url: "/admin/configurations",
      },
    ],
    operations: [
      {
        url: "/dashboard",
        icon: "/media/svg/icons/Extra/dashboard-v2.png",
        text: "Dashboard",
      },
      {
        url: "/current-openings",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Current Openings",
      },
      {
        url: "/user/list/all",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Users",
      },
      {
        url: "/operations/candidateList",
        icon: "/media/svg/icons/Extra/candidates.png",
        text: "Candidates",
      },
      {
        url: "/operations/ClientsList",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Clients",
      },
      // {
      //   url: "/operations/AiRecruiter",
      //   icon: "/media/svg/icons/Extra/user-employee.png",
      //   text: "AI Recruiter",
      // },
      {
        url: "/operations/subscription",
        icon: "/media/svg/icons/Extra/subscription.png",
        text: "Subscriptions",
      },
      {
        url: "/operations/messages",
        icon: "/media/svg/icons/Extra/email-v2.png",
        text: "Messages",
        count,
      },
      {
        url: "/operations/reports",
        icon: "/media/svg/icons/Extra/report-v2.png",
        text: "Reports",
      },
      // {
      //   url: "/admin/monsterSearch",
      //   icon: "/media/svg/icons/Extra/monster-search.png",
      //   text: "Monster Search",
      // },
      // {
      //   url: "/admin/cbSearch",
      //   icon: "/media/svg/icons/Extra/report.png",
      //   text: "CB Search",
      // },
      // {
      //   url: "/admin/autosource",
      //   icon: "/media/svg/icons/Extra/auto-source.png",
      //   text: "Auto Source",
      // },
    ],
    bdmmanager: [
      {
        url: "/dashboard",
        icon: "/media/svg/icons/Extra/dashboard-v2.png",
        text: "Dashboard",
      },
      {
        url: "/current-openings",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Current Openings",
      },
      {
        url: "/user/list/all",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "BDMs",
      },
      {
        url: "/bdmmanager/candidateList",
        icon: "/media/svg/icons/Extra/candidates.png",
        text: "Candidates",
      },
      {
        url: "/bdm-manager/ClientsList",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Clients",
      },
      // {
      //   url: "/bdm-manager/AiRecruiter",
      //   icon: "/media/svg/icons/Extra/user-employee.png",
      //   text: "AI Recruiter",
      // },
      {
        url: "/bdm-manager/subscription",
        icon: "/media/svg/icons/Extra/subscription.png",
        text: "Subscriptions",
      },
      {
        url: "/bdm-manager/messages",
        icon: "/media/svg/icons/Extra/email-v2.png",
        text: "Messages",
        count,
      },
      {
        url: "/bdm-manager/reports",
        icon: "/media/svg/icons/Extra/report-v2.png",
        text: "Reports",
      },
      // {
      //   url: "/admin/monsterSearch",
      //   icon: "/media/svg/icons/Extra/monster-search.png",
      //   text: "Monster Search",
      // },
      // {
      //   url: "/admin/cbSearch",
      //   icon: "/media/svg/icons/Extra/report.png",
      //   text: "CB Search",
      // },
      // {
      //   url: "/admin/autosource",
      //   icon: "/media/svg/icons/Extra/auto-source.png",
      //   text: "Auto Source",
      // },
    ],
    recruitmentmanager: [
      {
        url: "/dashboard",
        icon: "/media/svg/icons/Extra/dashboard-v2.png",
        text: "Dashboard",
      },
      {
        url: "/current-openings",
        icon: "/media/svg/icons/Extra/job-seeker-v2.png",
        text: "Current Openings",
      },
      {
        url: "/user/list/all",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Recruiters",
      },
      {
        url: "/recruitmentmanager/candidateList",
        icon: "/media/svg/icons/Extra/candidates.png",
        text: "Candidates",
      },
      {
        url: "/recruitment-manager/ClientsList",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Clients",
      },
      // {
      //   url: "/recruitment-manager/AiRecruiter",
      //   icon: "/media/svg/icons/Extra/user-employee.png",
      //   text: "AI Recruiter",
      // },
      {
        url: "/recruitment-manager/subscription",
        icon: "/media/svg/icons/Extra/subscription.png",
        text: "Subscriptions",
      },
      {
        url: "/recruitment-manager/messages",
        icon: "/media/svg/icons/Extra/email-v2.png",
        text: "Messages",
        count,
      },
      {
        url: "/recruitment-manager/reports",
        icon: "/media/svg/icons/Extra/report-v2.png",
        text: "Reports",
      },
      // {
      //   url: "/admin/monsterSearch",
      //   icon: "/media/svg/icons/Extra/monster-search.png",
      //   text: "Monster Search",
      // },
      // {
      //   url: "/admin/cbSearch",
      //   icon: "/media/svg/icons/Extra/report.png",
      //   text: "CB Search",
      // },
      // {
      //   url: "/admin/autosource",
      //   icon: "/media/svg/icons/Extra/auto-source.png",
      //   text: "Auto Source",
      // },
    ],
    candidate: [
      {
        url: "/candidate/profile",
        icon: "/media/svg/icons/Extra/user-employee.png",
        text: "Profile",
      },
    ],
  };

  return (
    <>
      {role && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {renderMenuItems(menuItems[role])}
        </ul>
      )}
    </>
  );
}
